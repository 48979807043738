import React,{useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Link, TextField, MenuItem } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus, sortByProperty, warpText, getLabel} from '../utils/MantawiTime'; 
import { navigate } from '@reach/router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
     
      width: "auto",
      flex: 1,
    },   
    loadingUi:{
        width: "100%",        
    },
    typoUi:{
        maxHeight: 100,
        display:"block",
        overflow: 'hidden',
       
    },
    notificationUi:{
       flex: 1,
    },
    
    gridUi:{
      flex: 1,
      width: "100%"
    },
    inline: {
      display: 'inline',
    },
    avatarSmall: {
      backgroundColor: red[500],
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: 5
    },
    avatarDiv:{
      width: theme.spacing(3) + 5,
    },
    textLocationField: {
      marginTop: '10px',
    },
    
  }),
);
   
TimeAgo.addLocale(en);
  

const YourOrders = ({userId,closedAccountDrawer}) => {

  let formatter = new Intl.NumberFormat('en-IN', {
   
    minimumFractionDigits: 2
  })
  

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
  const processing =['ordered','open','cart','processing','delivery','intransit','delivered']
  const completed =['completed','canceled'];
  const [statusIn, setStatusIn] = useState("processing");
 
  const [yourOrders, setYourOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRecords, setHasRecords] = useState(false);

  // const [yourOrders, isLoading] = useFirestoreQuery(
  //     firestore.collection("orders").where("userId", "==", userId)
  //       .where("status","in",filter)
  //       .where("totalCount",">",0)
  //       .orderBy("totalCount")
  // );

  const useFirestoreQueryYourOrders=  async <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryYourOrders: " );
    const unsubscribe =  await query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        setYourOrders(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        setIsLoading(false);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryYourOrders: " + err);
        console.error(err);
         
      } 
      
    );
    
    return () => unsubscribe();
  }
  
 
 const handleOrder = (order)=>{
 // var to=`/?store=${order.storeId}&mid=o&oid=${order._id}`;
 console.log("handleOrder: " + globalThis.STORE_ID);
 // the reason that is using globalThis.STORE_ID, becuase we don't want to load the store of the order
 // we want that it will remain on the current store. because use will be dis oriented or get lose in between 
 // loading of store.
 // why we are opening the store when item still on cart, because user could order more or continue shopping
 var to=`/?store=${globalThis.STORE_ID}&mid=o&oid=${order._id}`;
 if(order.status === 'cart'){
   to=`/?store=${order.storeId}`;
 }
 if(order.hasUpdates){ 
    firestore.collection("orders").doc(order._id).update( {hasUpdates:false} )
        .then(()=>{
          console.log("post updated........");
        }).catch((error)=>{
          console.log("post updated error: " + error);
          console.error(error);
    });
  }
  navigate(to);
  closedAccountDrawer();
}
// const statuses =[
//   {value: 'open',label: 'Open'},
//   {value: 'processing',label: 'Processing'},
//   {value: 'delivery',label: 'For Pickup'},
//   {value: 'intransit',label: 'In Transit'},
//   {value: 'delivered',label: 'Delivered'},
//   {value: 'completed',label: 'Completed'},
//   {value: 'canceled',label: 'Canceled'},
// ];
const statuses =[
  {value: "processing",label: 'Processing'},
  {value: "completed",label: 'Completed'},
];

const handleStatusChange = (event) => {
  if(event.target.value === 'completed'){
    
    refreshYourOrders(completed);
  }else{
   
    refreshYourOrders(processing);
  }
  setStatusIn(event.target.value);
  
};

  const [sortData,setSortData] = useState([]);

  useEffect( ()=>{
       setSortData( yourOrders.sort(sortByProperty("updated")));
       if(yourOrders.length>0 && !hasRecords){
        setHasRecords(true);
      }
  },[yourOrders] );

  const refreshYourOrders = (filter) =>{
    setIsLoading(true);
    useFirestoreQueryYourOrders(firestore.collection("orders").where("userId", "==", userId)
          .where("status","in",filter)
          .where("totalCount",">",0)
          .orderBy("totalCount")
    )
  } 

  useEffect( ()=>{ 
  
    refreshYourOrders(processing);
   
  },[])

  return (
    <div className={classes.root}>
       { hasRecords && 
<TextField
                        id="filter-selector"
                        select
                        label= "Filter"
                        placeholder="Set Sales Order Status"
                        variant="outlined"
                        fullWidth
                        value = {statusIn}                  
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                        className ={classes.textLocationField}
                      >
                        {statuses.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>}
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
     
    <List className={classes.listUi} >
        { sortData.length === 0 &&  <Grid  container direction="row" justify="center"
          alignItems="center" >
            <Typography>
            Start Ordering
            </Typography>
            <Typography>
            From your favorite Online Store
            </Typography>
        </Grid>}
        { sortData.map((order) =>(
          <Link  key={order._id}  style={{ textDecoration: 'none' }}  onClick={ ()=>{handleOrder(order)}}>     
          <ListItem className={classes.listItemUi} key={order._id} button  >
             
                
            <Avatar  alt={`Avatar ${order.storeId}` }
              className={classes.avatarSmall}
              src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${order.storeUserId}?alt=media`}>
                {order.storeName}
                
            </Avatar>
             
             <ListItemText className={classes.typoUi}
                primary={ 
                  <Typography
                      component="span"
                        variant="subtitle2"
                        display="block"
                        color="textPrimary"
                        noWrap >
                    {order.storeName + ' #' + order.transNumber  ? order.transNumber : "no schedule."}
                  </Typography>
                }
                secondary={ 
                  <React.Fragment>   
                    <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                   
                    >
                      { `${timeAgo.format(utcTimePlus(order.created), 'twitter')} :`}
              
                    </Typography>

                    { order.status === "cart" ?
                        <Typography component="span" variant="body2" color= "secondary">
                          { getLabel(order.status)} 
                        </Typography>
                    :
                        <Typography component="span" variant="body2" color= "primary" >
                            {getLabel(order.status)}
                        </Typography>
                    }
                    

                    <Typography variant="body2"  component="span"  color="textPrimary"  display="block" >
                            <span>
                              Total : &nbsp; 
                              &#8369;
                              { formatter.format(order.negTotalAmount ? order.negTotalAmount : 0)}
                              &nbsp;
                              Qty: {order.totalCount} 
                            </span>
                    </Typography>
                  </React.Fragment>   
 
                }
            />
            <ListItemSecondaryAction>
            { order.status === "cart" ?
             (order.hasUpdates?<ShoppingCartIcon color="primary" fontSize="small"  />:
             <ShoppingCartIcon className={classes.notificationUi} color="primary" fontSize="small" /> )
             :
             ( order.hasUpdates?<NotificationsActiveIcon color="primary" fontSize="small"  />:
              <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" /> 
             )
            }
           
            </ListItemSecondaryAction>
                    
          </ListItem>
           </Link> 
        ))}
      </List>
     
    }
   </div>
  );
} 
export default YourOrders;
