import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import  Accordion from '@material-ui/core/Accordion';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import YourSales from '../templates/YourSales';
import YourDraftOrders from '../templates/YourDraftOrders';
import YourInventory from '../templates/YourInventory';
import AddIcon from '@material-ui/icons/Add';
import { Button, Grid } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';

import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    buttonMenu: {        
      textTransform: "none",     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .05)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,     
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const StoreDrawer = ({profileId,store, closedStoreDrawer}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleScheduleList = (event)=>{

  };

  return (
    <div className={classes.root}>
      <Accordion key="1" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Product Catalog</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
        <YourInventory  userId={profileId} store={store} closedStoreDrawer={closedStoreDrawer} />
        </AccordionDetails>
      </Accordion>

      <Accordion  key="2" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Sales/Services Orders</Typography>
        </AccordionSummary>
        <AccordionDetails  className={classes.root}>
          
            <YourSales store={store}  closedStoreDrawer={closedStoreDrawer}  />
 
        </AccordionDetails>
      </Accordion>
       
      <Accordion  key="3" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Cart/Draft Orders</Typography>
        </AccordionSummary>
        <AccordionDetails  className={classes.root}>
        <YourDraftOrders userId={profileId}  closedStoreDrawer={closedStoreDrawer}  store={store} />
      
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default StoreDrawer;