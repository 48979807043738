import React , {useEffect,useState} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime, utcTimePlus, sortByProperty} from '../utils/MantawiTime'; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "100%",
      overflow: 'auto',
    },
    listItemUi:{
      marginTop: "10px",      

      width: "98%"
    },
    commentUi:{
        width: "100%", 
    },
    loadingUi:{
        width: "100%",
        
    },
    avatarSmall: {
      backgroundColor: red[500],
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
);
   
 

TimeAgo.addLocale(en);
var count = 0;
const Comments = ({productId}) => {

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
   
  const [comments, isLoading] = useFirestoreQuery(
 
       firestore.collection("comments").where("productId", "==", productId)
       .orderBy("updated","desc")
    
  );

  const [ sortComments, setSortComments]= useState([]);
 
  useEffect(()=>{
 
     if(comments && comments.length>0){
        comments.sort(sortByProperty("created"))
        setSortComments(comments);       
     }
     
  },[comments]);

  return (
      <>
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
    <List className={classes.listUi} dense >
            {  sortComments.map((comment) =>(
            
              <ListItem className={classes.listItemUi} key={comment._id} alignItems="flex-start" divider>
      
                              
                <Grid
                  container
                  direction={globalThis.PROFILE_UID === comment.userId ? "row-reverse" : "row"}
                  justify= "flex-start"
                  alignItems="center"
                >
                  <Grid container item xs={12}  alignItems="center"
                   direction={globalThis.PROFILE_UID === comment.userId ? "row-reverse" : "row"}                   
                   spacing={2}
                  > 

                  
                    {globalThis.PROFILE_UID === comment.userId &&<Grid item xs={1}>
                        <IconButton aria-label="settings" >
                           <MoreVertIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                    }


                     <Grid item xs={1}>
                        <Avatar  className={classes.avatarSmall} aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${comment.userId}?alt=media`} >
                          {comment.name}
                        </Avatar>                    
                      </Grid>
                                      
                      <Grid item>
                            <Typography variant="body2" color="textPrimary" component="p">
                                {comment.name}
                            </Typography>
                            <Typography variant="caption" color="textPrimary" component="p">
                                {timeAgo.format(utcTimePlus(comment.created),'twitter')}
                            </Typography>
                      </Grid>
                  </Grid>
                  <Grid container item xs={12}  alignItems="center"  direction={globalThis.PROFILE_UID === comment.userId ? "row-reverse" : "row"}>
                    <Grid item>
                       <Typography variant="body1" color="textPrimary" component="p">
                            {comment.message}
                        </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                  
              </ListItem>
                
            ))}
          </List>
        }
   </>
  );
} 
export default Comments;


