import React,{useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Link } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus, sortByProperty, warpText} from '../utils/MantawiTime'; 
import { navigate } from '@reach/router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
     
      width: "auto",
      flex: 1,
    },   
    loadingUi:{
        width: "100%",        
    },
    typoUi:{
        maxHeight: 100,
        display:"block",
        overflow: 'hidden',
    },
    notificationUi:{
       flex: 1,
    },
    
    gridUi:{
      flex: 1,
      width: "100%"
    },
    inline: {
      display: 'inline',
    },
    addProductUi: {
      textTransform: "none"      
    },
 
    
  }),
);
   
TimeAgo.addLocale(en);
 
 

const YourInventory = ({userId, store, closedStoreDrawer}) => {


  let formatter = new Intl.NumberFormat('en-IN', {
   
    minimumFractionDigits: 2
  })
  

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');
  const totalItemCount = store.maxItemCount;
  const [itemCount, setItemCount] = useState(0);
   

  const [products, isLoading] = useFirestoreQuery(
      firestore.collection("products")
        .where("userId","==",userId)
        .where("storeId", "==", store._id)
        .where("status","in",["new","cancelled","available","preorder","outofstock"]) 
  );
  
 const handleProduct = (productId)=>{
  
  var to=`/?store=${store._id}&uid=${userId}&mid=product&prid=${productId}`;
   
  navigate(to);
  closedStoreDrawer();
}


const handleNewProduct = (productId) =>{
  
  navigate(`?store=${store._id}&uid=${userId}&mid=product&prid=${null}`)
}
useEffect(()=>{
  setItemCount(products.length);

},[products])

 

  return (
    <div className={classes.root}>
       <Grid  container direction="row" justify="center" alignItems="center" >
         {products.length > 15 ?

        <Button className={classes.addProductUi} onClick={()=>{handleNewProduct(null)}}> Add Products or Services{`(${itemCount}/${totalItemCount})`} <AddCircleIcon fontSize="large" color="secondary"/> </Button>
          :
         <Button className={classes.addProductUi} onClick={()=>{handleNewProduct(null)}}> Add Products or Services {`(${itemCount}/${totalItemCount})`} <AddCircleIcon fontSize="large" color="primary"/> </Button>
         }
        </Grid>
   
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
     
    <List className={classes.listUi} >
      
        {  products.map((product) =>(
          <Link  key={product._id}  style={{ textDecoration: 'none' }}  onClick={ ()=>{handleProduct(product._id)}}>     
          <ListItem className={classes.listItemUi} key={product._id} button alignItems="flex-start">
               
            
              <ListItemText className={classes.typoUi}   primary={ 
              
                                     <Typography
                                          variant="subtitle2"
                                          display="block"
                                          color="textPrimary"
                                          noWrap >
                                      {product.productName}  
                                   </Typography>
      
                             }
                       secondary={                   
                                  <>
                                      <Typography component="span" variant="body2" color="textPrimary" >
                                      { store.currency ==='PHP' ? <>&#8369;</>  : '$' } 
                                      { formatter.format(product.price) } { `Qty: ${product.quantity} `}
                                      </Typography>
                                       
                                          {product.description}
                                     
                                    </>
                                   }
                                   />
              <ListItemSecondaryAction>
                 {product.status?<NotificationsActiveIcon color="primary" fontSize="small"  />:
                  <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" /> }
            </ListItemSecondaryAction>
                    
          </ListItem>
           </Link> 
        ))}
      </List>
     
    }
   </div>
  );
} 
export default YourInventory;
