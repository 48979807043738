import React, {useEffect,useState,useRef} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StoreIcon from '@material-ui/icons/Store';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SettingsIcon from'@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Switch from '@material-ui/core/Switch';
import {Link, Button } from 'gatsby-theme-material-ui';
import AppsIcon from '@material-ui/icons/Apps';
import SEO from "../components/SEO"; 
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Fab from '@material-ui/core/Fab';
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
 
import LoginModal from '../components/LoginModal';
import StoreDrawer from '../components/StoreDrawer';
import AccountDrawer from '../components/AccountDrawer';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ProductEntry from '../components/ProductEntry';
import SalesOrder from '../components/SalesOrder';
import Order from '../components/Order';
import DraftOrder from '../components/DraftOrder';
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import {UserProfile} from '../utils/mantawiObject';
import { MenuItem, Menu, Avatar, Container, CardMedia, CircularProgress, Box } from '@material-ui/core';
import { DotsVertical } from 'mdi-material-ui';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorBoundary from '../utils/ErrorBoundary';
import Adds from '../components/Adds';
import Account from "../components/account";
import ProductPosts from "../components/product_posts";
import WorkSchedule from "../components/WorkSchedule";
import StorePosts from "../components/store_posts";
import Welcome from "../components/welcome";
import Helps from "../components/helps2";

import queryString from 'query-string';
import ContactUsForm from "../components/ContactUS";
import StoreSetting from "../components/StoreSetting";
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import RegisteredDialog from '../templates/RegisteredDialog'
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';
import LinkIcon from '@material-ui/icons/Link';
import {ScreenView, utcTime, sortByProperty, Copyright,getMDateToday, getMTransNumber} from '../utils/MantawiTime'; 
//const myExtScript = require('../utils/paypal');
const drawerWidth = 300;
const drawerWidthRight = 240;

globalThis.LOADING_IMAGE =  require('../images/loading.gif');
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  titleUi:{
    width: '100%'
  },
  toolbar: { 
      paddingBottom: '10px',
      color: 'white'
  },
  toolbar2:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarClipped: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: '15px' ,
    height: '50px', 
    background: blue[500]
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerShift:{
      width: '100%',
      flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperFull:{
    width: '100%',
  },
  drawerRight: {
    width: drawerWidthRight,
    flexShrink: 0,
  },
  drawerPaperRight: {
    width: drawerWidthRight,
  },
  headerSpacer: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 50,
    ...theme.mixins.toolbar,
     
  },
  headerSpacer2: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 70,
  },
  headerSpacer3: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 75,
    paddingRight: 5,
    paddingLeft: 5,
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  content2: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "100%",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift2: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    zIndex: 0,
    overflow: 'auto',
  },
  typography: {
    useNextVariants: true,
  },  
  topWhiteBar:{
    height: '15px',
    width: '100%',
    backgroundColor: "white",
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex:  theme.zIndex.drawer + 1 
  },
  addIconFab: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
    backgroundColor: green[300],
    zIndex: theme.zIndex.drawer + 1 
  },
  
  fabMap2: {
    margin: theme.spacing(1),
    top: '110px',
    right: 'auto',
    bottom: 'auto',
    left: '90px',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1 
  },
 
  footer: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    background: blue[500]
  },
 
  footerDialog: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1 
  },
  loginButton: {
    color: 'white',
    textDecoration: 'none',
  },
  menuButton2: {
    color: 'white',
    textTransform: 'none',
  },
  dotsVerticalIcon: {
    color: "#efefef"
  },
  avatarSmall: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: red[500],
  },
  adds:{
    width: '160',
    height: '600',
    columnCount: 1
  },
  linkButton:{
    color: 'blue',
  },
  avatar: {
    backgroundColor: red[500],
  },
  storeDetailsUi:{
    paddingLeft: 15,
    paddingRight: 15,
  },
  fab: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
    backgroundColor: green[300],
    zIndex: theme.zIndex.drawer + 1 
  },
  menuImage:{
    minWidth: 250,
    maxWidth: 250,
  },
  headerCenter:{
    alignItems: "center",
    display: "flex"
  },
  headerIcon:{
    marginLeft: 5,
  },
  linkUrlUi:{
    textTransform: 'none',
  },
  paypalUi:{
    marginTop: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  
}));

let openDrawer  = true;
let openMapDialog = false;

 
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())
 
 
globalThis.POSTS_QUERY =["postType","==","post"]
 
globalThis.SHOWPAGE = 3; // welcome

function linkify_o(text) {
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
  });
}

//making your text to html
function createInnerHtml(key, story){
  //var ht = linkify_o(story);
  document.getElementById(key).innerHTML =story;
}
const errorBannerUrl = require(`../images/Miniens1.png`);
const onErrorImg = (event:  any) =>{ 
  event.target.src= require(`../images/Miniens1.png`);
}

 

const Layout = (props) => {
   
    const {isLoggedIn, profile} = useAuth();
   
    const theme = useTheme();
    const classes = useStyles();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const [isFullScreen, setIsFullScreen] = useState(false);
   
    const [bannerUrl,setBannerUrl] = useState(errorBannerUrl);
    
   // const params = queryString.parse( props.location.search);
    const avatarRef = useRef(null);
    const [history, setHistory] = useState([]);
   
   // const [showPage, setShowPage] = useState(3);
    
    const [storeId, setStoreID] = useState(process.env.GATSBY_DEFUALT_STORE );
    const [hasStore, setHasStore] = useState(null);
    const [orderStore, setOrderStore] = useState(null);
     //junald check or test if set to 0 or 3
    const [showPage, setShowPage2] = useState(ScreenView.WELCOME);
    const [store, setStore] = useState(null);
     

    const [stores, storesIsLoading] = useFirestoreQuery(
      firestore.collection("stores")
        
    );
  
//.where("storeId", "not-in", [globalThis.STORE_ID])        

    const [urlLocation, setUrlLocation] = useState(props.location);
 
    const [open, setOpen] = React.useState(openDrawer);
    
    const [hasOpen, sethasOpen] = React.useState(false);
    const [openMain, setOpenMain] = React.useState(true);
    
    
    const [openProductEntry, setOpenProductEntry] = React.useState(false);
    const [openSalesOrder, setOpenSalesOrder] = React.useState(false);
    const [openOrder, setOpenOrder] = React.useState(false);
    const [openDraftOrder, setOpenDraftOrder] = React.useState(false);
    const [toggleLogin, setToggleLogin] = React.useState(false);
    
    const [userProfilePicture, setUserProfilePicture] = React.useState(globalThis.USER_PROFILE_PICTURE);
    const [productId, setProductId] = useState(null);
 
    const [userProfile, setUserProfile] = useState<UserProfile>(null);
  
    const [registeredOpen, setRegisteredOpen] = useState(false);
    const [salesOrderId, setSalesOrderId] = useState(null);
    const [orderId, setOrderId] = useState(null);

    const [storeInHistory, setStoreInHistory] = useState(false);

    const [prTitle, setPrTitle] = useState("");
    const [prDescription, setPrDescription] = useState("");
    const [prUrl, setPrUrl] = useState("");
    const [prImage, setPrImage] = useState("");

    const [link,setLink] = useState(null);

    function copyToClipboard(theLink) {
      // checkView();
       const el = document.createElement('textarea');
       el.value = theLink;
       el.setAttribute('readonly', '');
       el.style.position = 'absolute';
       el.style.left = '-9999px';
       document.body.appendChild(el);
       el.select();
       document.execCommand('copy');
       document.body.removeChild(el);
     }

    const addStoreHistory = (_currentStore) =>{
      if(profile && userProfile){

        if(profile.uid === _currentStore.userId){
          return ;
        }
        console.log("addStoreHistory profile: "+ profile);
        console.log("addStoreHistory userProfile"+ userProfile);

        let historyCounter = userProfile.historyCounter || 0;
        let historyStores = userProfile.historyStores  || new Array();

        let fStore = userProfile.historyStores && userProfile.historyStores.find( e => e.storeId === _currentStore._id);

        if(fStore === null || fStore === undefined){
          if(historyCounter+1 > 5){
            historyCounter = 0;
          } 
   
          historyStores.push({
            storeId: _currentStore._id,
            storeName: _currentStore.name
           });
  
          historyCounter = historyCounter +1;
  
          firestore.collection('users').doc(globalThis.PROFILE_UID ).update(
            { 
              historyCounter: historyCounter,
              historyStores: historyStores
            })
            .then( () =>{
              setStoreInHistory(true);
              setUserProfile(userProfile);
            }).catch( (err)=>{
              console.log(err);
            });
             
        }
 
      }
    }

    const checkStoreHisgtory = (_currentStoreId) =>{
   
      let fStore = null;
      let _history = false;
      if(profile && userProfile){ 
         // console.log("checkStoreHistory....");
          if(userProfile.historyStores){
           // console.log("checkStoreHistory checking.... " + userProfile.historyStores.length);
            fStore =  userProfile.historyStores.find( e => e.storeId === _currentStoreId);
          }
         

          if( (fStore === null || fStore === undefined)  ){
            _history = false;
          }else{
            _history = true;
          }

          if(store && profile && store.userId === profile.uid){
            _history = true;
          }
      }
      setStoreInHistory(_history);
      //return fStore;
    }
   
    const setShowPage = (page) =>{
      globalThis.SHOWPAGE = page;
      setShowPage2(page);
    } 

   
  const handleUserProfilePictureURL = (u)=>{

    setUserProfilePicture(u);
    globalThis.USER_PROFILE_PICTURE = u;
  
    // userProfilePictureUrl = u;
    
    setAnchorElAvatar(null);
  }
   
  const [userId, setUserId] = useState(null);
 
 
    const handleDrawer = () =>{
      openDrawer = !openDrawer;
      setOpen(openDrawer)
      if(!matchesMD && !matchesSM && matchesXS && openDrawer){
        console.log("setOpenMain matchesMD matchesSM matchesXS false ");
        setOpenMain(false);
      }else{
        console.log("setOpenMain matchesMD matchesSM matchesXS true ");
        setOpenMain(true);
      }
     
    }

    const closedStoreDrawer = ()=>{
      var closingStoreDrawer = false;
      if(!matchesMD&&!matchesSM&&matchesXS) {
        closingStoreDrawer = true;
      }else{
        closingStoreDrawer = false;
      }
  

      if(closingStoreDrawer){
        handleDrawer();
      }
 
    }

    const closedAccountDrawer = ()=>{
      var closingStoreDrawer = false;
      if(!matchesMD&&!matchesSM&&matchesXS) {
         
        closingStoreDrawer = true;
      }else{
    
        closingStoreDrawer = false;
      }

      if(closingStoreDrawer){
        handleDrawer();
      }
 
    }

    const [colCount, setColCount] = useState(12);
    const [switchSx, setSwitchSx] = useState(false);

    const  handleChangeSwitch = () =>{
 
      if(switchSx){
        
        setSwitchSx(false);
      }else{
         
        setSwitchSx(true);
      }
    }

    useEffect(() => {
       if(switchSx){
        setColCount(12);
        
      }else{
        setColCount(6);
      
      }

    },[switchSx]);

    
   

    const [anchorElMobile, setAnchorElMobile] = React.useState<null | HTMLElement>(null);
    const [anchorElAvatar, setAnchorElAvatar] = React.useState<null | HTMLElement>(null);
    const [anchorElHistory, setAnchorElHistory] = React.useState<null | HTMLElement>(null);


    const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElMobile(event.currentTarget);
    };

    const handleClickAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElAvatar(event.currentTarget);
    };

    const handleClickHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElHistory(event.currentTarget);
    };
    
  
    const handleCloseMobile = () => {
      setAnchorElMobile(null);
    };
    const handleCloseAvatar = () => {
      setAnchorElAvatar(null);
    };
    const handleCloseHistory = () => {
      setAnchorElHistory(null);
    };
     

    const handleOpenProductEntryDialog = () =>{
      if(profile === null || profile === undefined){
       // navigate("/?login=now");
      }else{ 
        setOpenProductEntry(true)
      }
    }

    const handleCloseProductEntryDialog = () =>{
      console.log("handleClosePostDialog: " );
      navigate(`?store=${storeId}`);
      setOpenProductEntry(false)
    }

    const handleOpenSalesOrderDialog = () =>{
      if(profile === null || profile === undefined){
       // navigate("/?login=now");
      }else{ 
        setOpenSalesOrder(true)
      }
    }

    const handleCloseSalesOrderDialog = () =>{
      console.log("handleCloseSalesOrderDialog: " );
      
     // navigate(`?store=${storeId}mid=so&slid=${salesOrderId}`);
      setOpenSalesOrder(false)
      setSalesOrderId(null);
    }

    const handleOpenOrderDialog = () =>{
      if(profile === null || profile === undefined){
        console.log("loginnow: 1");
       // navigate("/?login=now");
      }else{ 
   
        setOpenOrder(true)
      }
    }

    const handleCloseOrderDialog = () =>{
      console.log("handleCloseSalesOrderDialog: " );
      
     // navigate(`?store=${storeId}mid=so&slid=${salesOrderId}`);
      setOpenOrder(false)
      setOrderId(null);
    }

    const handleOpenDraftOrderDialog = () =>{
      if(profile === null || profile === undefined){
        console.log("loginnow: 1");
       // navigate("/?login=now");
      }else{ 
   
        setOpenDraftOrder(true)
      }
    }


    const handleCloseDraftOrderDialog = () =>{
      console.log("handleCloseSalesOrderDialog: " );
      
     // navigate(`?store=${storeId}mid=so&slid=${salesOrderId}`);
      setOpenDraftOrder(false)
      setOrderId(null);
    }
     

    const handleSignOut = async () => {
      console.log('sign out');
     // auth.signOut();
      await firebase.auth().signOut();
      globalThis.USER_PROFILE_PICTURE =null;
      globalThis.PROFILE_UID =  null; 
      setShowPage(ScreenView.STORE);
      handleUserProfilePictureURL(null);
      navigate(`/?store=${storeId}`);
      setAnchorElMobile(null);
    }

    const handleShowHome = (url) =>{
      if(isLoggedIn){
        setShowPage(ScreenView.STORE);
        navigate(url);
      }else{
        setShowPage(0);
        navigate(`?store=${storeId}`);
      }
      setAnchorElMobile(null);
      
    }
 
    const handleShowStore = () =>{
      var url = `?store=${hasStore._id}&kil=${profile?profile.uid:""}`
      if(isLoggedIn){
        setShowPage(ScreenView.STORE);     
        setStore(hasStore);
        
        navigate(url);
      }else{
        setShowPage(0);
        navigate(`?store=${storeId}`);
      }
    
      setAnchorElMobile(null);
    }

    const handleSearchStore = () =>{

       setShowPage(ScreenView.STORE_PRODUCT);
       setAnchorElHistory(null);
       setAnchorElMobile(null);
    }

  const handleHistoryShowStore = (_storeId) =>{
      setShowPage(ScreenView.STORE);
      navigate(`?store=${_storeId}`);
      setAnchorElHistory(null);
  }
 
  const handleRegisteredDialog = (status) =>{
    setRegisteredOpen(false);
    if(status ==='register'){
      console.log("loginnow: 2");
      navigate("/?login=now");
    
    }else{
      navigate("/?fid=public");
    }
   
  }
  
    // SHOWPAGE_STORE ==0
    // SHOWPAGE_ACCOUNT=1
    // SHOWPAGE_POST=2
    // SHOWPAGE_WELCOME=3
    // SHOWPAGE_HELPS=4
    // SHOWPAGE_CONTACTUS=5
    //[props.location]
  useEffect(() => {
      
      console.log("showPage location change: " +  globalThis.SHOWPAGE);
      const params = queryString.parse( props.location.search);
 

      if(params.login && !isLoggedIn){
        console.log("props.location: 1"  );
           setToggleLogin(true);
      } else if(params.pr && params.pr !== "pu" && !isLoggedIn ){ 
        console.log("props.location: 2"  );
         setShowPage(ScreenView.WELCOME);
         setRegisteredOpen(true);
      }else if(params.mid && params.mid === "so"){   
        console.log("props.location: 3"  );  
        if(params.slid === 'null'){
          setSalesOrderId(null);
        }else{
          setSalesOrderId(params.slid);
        }
        handleOpenSalesOrderDialog();
      } else if(params.mid && params.mid === "o"){  
        console.log("props.location: 4"  );   
        if(params.oid === 'null'){
          setOrderId(null);
        }else{
          setOrderId(params.oid);
        }
        handleOpenOrderDialog();
    
     }else if(params.mid && params.mid === "do"){  
      console.log("props.location: 41"  );   
      if(params.oid === 'null'){
        setOrderId(null);
      }else{
        setOrderId(params.oid);
      }
      handleOpenDraftOrderDialog();
  
      } 
      
      else if(params.mid && params.mid === "account"){
        //store or user account
        console.log("props.location: 5"  );
        console.log("matchesMD matchesSM matchesXS 3: " + matchesMD + " : "  +matchesSM + " : " + matchesXS);
        if(!matchesMD&&!matchesSM&&matchesXS){     
          //junald              
          openDrawer = !openDrawer;
          setOpen(openDrawer)
        }
        setShowPage(ScreenView.ACCOUNT);
      }
      else if(params.mid && params.mid === "welcome"){
        console.log("props.location: 6"  );
        if(matchesXS){     
          //junald              
          openDrawer = !openDrawer;
          setOpen(openDrawer)
        }
        setShowPage(ScreenView.WELCOME);
      }
      
      else if(params.mid && params.mid === "help"){
        console.log("props.location: 7"  );
        setShowPage(ScreenView.HELP);
      }
      else if(params.mid && params.mid === "contactus"){     
        console.log("props.location: 8"  );
        setShowPage(ScreenView.CONTACTUS)
      }
      else if(params.mid && params.mid === "order"){     
        //entry
        console.log("props.location: 9"  );
        setShowPage(ScreenView.ORDER_DIALOG);
      }
      else if(params.mid && params.mid === "store-setting"){
        console.log("props.location: 10"  );
        //junald
        if(hasStore) { 
          console.log("matchesMD matchesSM matchesXS 3: " + matchesMD + " : "  +matchesSM + " : " + matchesXS);
          if(!matchesMD&&!matchesSM&&matchesXS){     
            //junald              
            openDrawer = !openDrawer;
            setOpen(openDrawer)
          }
    
           setShowPage(ScreenView.STORE_SETTING);
        }else{

        }
      }
      else if(params.mid && params.mid === "create-store"){
        console.log("props.location: 11"  );
        if(process.env.GATSBY_LIMIT_STATUS=="-development"){
          navigate(`/?store=${storeId}&mid=welcome`);
        }else{
          if(profile){ 
            if(hasStore){
              // setStoreID(hasStore.key);
              // globalThis.STORE_ID = hasStore.key;
              
              // setShowPage(7);
              setShowPage(ScreenView.STORE);
            }else{
              setStoreID('create');
              globalThis.STORE_ID = 'create';
              setShowPage(ScreenView.STORE_SETTING)
    
            }
         }
        } 
        
      }
  
      else if(params.mid && params.mid === "product"){     
        console.log("props.location: 12"  );
        if(params.prid === 'null'){
          setProductId(null);
        }else{
          setProductId(params.prid);
        }
        handleOpenProductEntryDialog();
      
      }  else if(params.mid && params.mid === "store"){ 
        //show your store
        console.log("props.location: 13"  );
        setShowPage(ScreenView.STORE);
      } else{
          console.log("props.location: 14"  );
          console.log("params.store change: "+ storeId);
          setShowPage(ScreenView.STORE);

          if(params.link ){
             // let theStoreLink = process.env.GATSBY_DEFUALT_STORE;
              setStore(null);
              console.log("params.link change: ");
              firestore.collection("stores").where("link", "==" ,params.link).get()
              .then(function(querySnapshot) {
                  
                  let _stList = [];
                  let theStoreLink = process.env.GATSBY_DEFUALT_STORE;
                  if(!querySnapshot.empty){                      
                      querySnapshot.forEach(function(doc) {                      
                          _stList.push({ _id: doc.id, ...doc.data()});
                      });                                              
                      theStoreLink = _stList[0]._id;
                  } 
                  
                  return theStoreLink;
               }).then(( theStoreLink) => {
                  
                  setStore(null);
                  navigate(`/?store=${theStoreLink}`);                      
                }
               ) 

                            
          }else{
            if(params.store && params.store !== 'create'){
              setStore(null);
              let _storeId = params.store+"";
          
              var _wait = [  firestore.collection("stores").doc( _storeId).get().then(
                function(doc){
                  if (doc.exists) {
                    
                    setStore({ _id: doc.id, ...doc.data()});
                
                    //we can add this code as a tagging rather auto history.
                    //addStoreHistory(store);
                   
                    globalThis.STORE_NAME= doc.data.name;
                    console.log("Init store profile null");
                  }  
                }
              ).catch( (error)=> {
                console.log("Error getting other params store: ", error);
              })];
              var _response = Promise.all(_wait);
              setUrlLocation(props.location);
            }else if(params.store === null || params.store === undefined){
              //junald disable
              navigate(`/?store=${process.env.GATSBY_DEFUALT_STORE}`);
            }else{
              setUrlLocation(props.location);
            }
          }
      }
      if(process.env.GATSBY_LIMIT_STATUS!=="-development"){
        console.clear();
 
     }
 

  },[props.location]);

 var [productPr,setProductPr] = useState(null);
 
  const handleWelcomePage = () =>{
    //junald
   
    navigate(`/?store=${storeId}&mid=welcome`);
  }

  const handleAccountPage = () =>{

    navigate(`/?store=${storeId}&mid=account`);
    setAnchorElMobile(null);
  }

  const handleStoreSettingPage = () =>{
    navigate(`/?store=${hasStore._id}&mid=store-setting`);
  }

  const handleCreateStoreSettingPage = () =>{
    // store setting 
    // for test release disable create store
    // only enable in local 
    // we could provide create-store adds instead of a actual create store form.
    
    if(process.env.GATSBY_LIMIT_STATUS !== "-development"){

      //navigate(`/?store=${storeId}&mid=welcome`);
      //navigate(`/?store=create&mid=create-store`);
      var to=`?store=str-063T67UHIWW0iw9JXFyBbFkDaP92&fid=0v9cKZGwHoRx3XPbHSfW`;
      navigate(to);

    }else{
      //no create store  only invitation
      navigate(`/?store=create&mid=create-store`);
      //junald invitation
      
      // var to=`?store=str-063T67UHIWW0iw9JXFyBbFkDaP92&fid=p8S2pGKvpH5uMtnWhkdp`;
      // navigate(to);
    } 
    
    // this is disabled for now creating store only
    

  }

  const handleHelpPage = () =>{
  
    navigate(`/?store=${storeId}&mid=help`);
    setAnchorElMobile(null);
  }

  const handleContactPage = () =>{

    navigate(`/?store=${storeId}&mid=contactus`);
    setAnchorElMobile(null);
  }

  const handleLogin=(status)=>{
    if(status){
      console.log("loginnow: 3");
       navigate(`/?login=now`);
    }
    setAnchorElMobile(null);
  }

  
 
  useEffect( ()=>{
    console.log("matchesMD matchesSM matchesXS 2: " + matchesMD + " : "  +matchesSM + " : " + matchesXS)
   
    if(!hasOpen){
      if(matchesMD&&matchesSM&&matchesXS) {
         openDrawer = true;
        setOpen(openDrawer)
      }  else{
        openDrawer = false;
        setOpen(openDrawer)
      }
    }
    if(!matchesMD&&!matchesSM&&matchesXS) {
      setIsFullScreen(true);
    } else{
      setIsFullScreen(false);
    }
  },[matchesMD,matchesSM,matchesXS])
 
   /// blank effect
  useEffect(()=>{

   if( props && props.location && props.location.search  ){ 
      const params = queryString.parse( props.location.search);
      let _storeId = process.env.GATSBY_DEFUALT_STORE;

      if(params.store){
        _storeId = params.store+"";
      } 

      if(params.store && params.store !== 'create' && params.fid  && params.prt && params.prd){
        
        setPrTitle(params.prt+'');
        setPrDescription(params.prd+'');
        setProductId(params.fid);
        setStoreID(params.store+'');
        setPrUrl(`${process.env.GATSBY_HOST}?store=${params.store}&fid=${params.fid}`);
        setPrImage("https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fminiens.png?alt=media&token=5b17906e-f077-4a04-844f-32717058c98d");                
        console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj: " + params.prt + ' '+ params.prd);
      }
      
      if(params.store && params.store !== 'create'){
       
        if(store === null){
          console.log("setbannerurl1.1: " +_storeId);
          setStoreID(_storeId);
          checkStoreHisgtory(_storeId);
          setBannerUrl(`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${_storeId}%2F${_storeId}?alt=media`)
            var _wait = [  firestore.collection("stores").doc( _storeId).get().then(
                function(doc){
                  if (doc.exists) {
                    setStore({ _id: doc.id, ...doc.data()});
                   
                    globalThis.STORE_NAME= data.name;
                    console.log("Init store profile null");
                  } 
                }
              ).catch( (error)=> {
                console.log("Error getting other params store: ", error);
              })];
            var _response = Promise.all(_wait);
          } 
      }
  
    }

  });

  const [storeList, setStoreList] = useState([]);

  useEffect( ()=>{
    console.log("xxxxxxxxxxxxxxxx store1: "  );
    if(userProfile && userProfile.historyStores && userProfile.historyStores.length > 0) {
      
     let keys = userProfile.historyStores.map( (e) => e.storeId);
     
      setStoreList(null);
      if(keys !== undefined && keys !== null && keys.length> 0){
        try{ 
          firestore.collection("stores").where( "key","in",keys).get().then( function(querySnapshot) {
            
              if(!querySnapshot.empty){
                  let _stList = [];
                  querySnapshot.forEach(function(doc) {
                   //   console.log("xxxxxxxxxxxxxxxx store: + " + doc.id, " => ", doc.data());
                      _stList.push({ _id: doc.id, ...doc.data()});
                  }); 
                  setStoreList(_stList);
              } else{
                setStoreList(null);
              }
            }
          
          ).catch( (error)=> {
            console.log("Error getting other params store: ", error);
          })
        }catch(error){
           console.log("userProfile.historyStores: "+ error);
        }
      }
    }
    console.log("xxxxxxxxxxxxxxxx store3: ");
  },[userProfile])


  //[profile]
  useEffect(()=>{
    const params = queryString.parse( props.location.search);
   
    console.log("globalThis.PROFILE_UID:"+globalThis.PROFILE_UID);
    if(profile){
    
      globalThis.PROFILE_UID = profile.uid;
      globalThis.PROFILE_USERNAME = profile.displayName ;
      setRegisteredOpen(false);
      setToggleLogin(false);
      firestore.collection("users").doc(profile.uid).get().then(
        function(doc){
          console.log("it should happened.....");
          let _userProfile = null;
          if (doc.exists) {
            var data = doc.data();
            _userProfile = {
              _id: doc.id,
              ...doc.data()
            };
            console.log('name: ' + data.name );
            setUserProfile(data as UserProfile);
            globalThis.PROFILE_USERNAME = data.name;
            handleUserProfilePictureURL(data.profilePicture);
            setUserId(profile.uid);
          } else {
            /** look at LoginModal
            _userProfile = { 
              email: profile.email,
              name: profile.displayName,
              dob: "" ,
              profilePicture: "",    
              location: "",
              locationCenter: [0,0],
              created: data.created,
              updated: "",
              useLocation: false,
              locationCity: "",
              locationCountry: "",
              locationDistrict: "",
              locationOther: "",    
           }
           firestore.collection("users")
              .doc(profile.uid)
              .set(_userProfile).then(
                  (doc) =>{
                    
                  } 
              );
               */
          }

          return _userProfile
        }

      ).then((_userProfile)=>{
        
        // this should be move on registration
        // or a setting to add a store.
        // when user logins in should only go where the params.store value
        // or open only the store which is set in params.store.
        // if user has a store, it should only when store button is click.
        // if should only open store that is set in param.store
      

        firestore.collection("stores").where("userId", "==" ,profile.uid).get()
          .then(function(querySnapshot) {
            let _store = null;
            
            if(!querySnapshot.empty){
              querySnapshot.forEach(function(doc) {
                //  console.log("store: + " + doc.id, " => ", doc.data());
                  _store = { _id: doc.id, ...doc.data()};
              }); 
             }
             setHasStore(_store);
          return _store;
        }).then( (_store) =>{
       
          if( params.store ){          

            if(store === null || store === undefined)  {
              firestore.collection("stores").doc(params.store+"").get().then(
                function(doc){
                  if (doc.exists ) {
                    setStore({ _id: doc.id, ...doc.data()});               
                  
                    globalThis.STORE_NAME= data.name;
                    console.log("setbannerurl 20.12: " +  globalThis.STORE_ID);
                  }  
                }
              ).catch( (error)=> {
                console.log("Error getting other params store: ", error);
              })

            }
          }else if(_store !== null  ){     
            //junald disable    
              // setStore(_store);           
              // globalThis.STORE_NAME= _store.name;  
           
          }else{         
            //junald disable
            firestore.collection("stores").doc( process.env.GATSBY_DEFUALT_STORE).get().then(
              function(doc){
                if (doc.exists) {      
                  setStore({ _id: doc.id, ...doc.data()});
              
                  globalThis.STORE_NAME= data.name;
                 
                }  
              }
            ).catch( (error)=> {
              console.log("Error getting other params store: ", error);
            })
          }
 

          return _store;

        }).catch(function(error) {
            console.log("Error getting my store: ", error);
        });

     
      // var wait = useFirestoreQueryFriends(firestore.collection("friends").where("userId", "==", globalThis.PROFILE_UID))
     
      }).then(()=>{
        setShowPage(ScreenView.STORE);
      }).catch((error)=>{
        console.error(error);
      }); 

 
 
    }
   
    if(process.env.GATSBY_LIMIT_STATUS!=="-development"){
       console.clear();
    }
  } ,[profile]);

  useEffect(()=>{
    if(hasStore){
        /** this paypal is set using sandbox paypal account junaldcl@gmail.com disabled for not
          * since subscriptions required to be a member of paypal.
          * need further testing using plain product checkout not subsricption
          * using the plain checkout, the system will check for near expiration to suggest the user
          * to purchase new subscription.
          * 
          * */
/**
        paypal.Buttons({
          style: {
              shape: 'rect',
              color: 'blue',
              layout: 'vertical',
              label: 'subscribe',
          },
          createSubscription: function(data, actions) {
            console.log('paypay userid1: '+  globalThis.PROFILE_UID);
            alert( 'paypay userid: '+  globalThis.PROFILE_UID );
            return actions.subscription.create({
              'plan_id': 'P-8EL97827ER159972RL44OCXI'
            });
          },
          onApprove: function(data, actions) {
            console.log('paypay userid: '+  globalThis.PROFILE_UID + " : " + data.subscriptionID);
            console.log('paypal data: ' + JSON.stringify(data));
            alert(data.subscriptionID);
          }
        }).render('#paypal-button-container'); 
         */
    }

  } ,[hasStore]);
//store
  useEffect(()=>{
    if(store){
      setStoreID(store._id);
      globalThis.STORE_ID =  store._id;
      checkStoreHisgtory(store._id);
      console.log("setbannerurl 20: " + store._id);
      setBannerUrl(`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${store._id}%2F${store._id}?alt=media`)
      setSwitchSx(store.switchSx);
    }

  } ,[store]);

  const findStore = (_storeId , _name) => {
    var _theName = _name;
    if(storeList === null ){
      return _theName
    }
   // console.log("findStorexxx: " + _name + " : " + _storeId + " " + storeList.length);
    var _st = storeList.find( e => e.key === _storeId );
   
    if(_st !== null  && _st !== undefined){
      _theName = _st.name;
      //console.log("findStorexxxxxxxxxxxxx: " + _name + " : " + _storeId);
    } 
    return _theName;
  }

  const showMap = (center) =>{
    //https://teknosrc.com/open-google-maps-new-browser-tab-location-latitude-longitude-tagged-marked/
    const showMapLocationUrl   = `https://www.google.com.sa/maps?z=12&t=m&q=loc:${center[1]}+${center[0]}`;
    
    window.open(showMapLocationUrl, '_blank');
  }
 
    const data = useStaticQuery(graphql`
          query{
              site{
                  siteMetadata{
                      title
                      image
                      url
                      description
                      contact {
                        email
                        phone
                      }
                  }
              }
          }
      `)
    return (
      
      <div className={classes.root}>
       
     
        <CssBaseline />
        <div className={classes.topWhiteBar}></div>
       
        <AppBar
          position="fixed"
          className={classes.appBarClipped} 
        >
          <Toolbar className={classes.toolbar}>
        
        
            <Button onClick={handleDrawer}  >
                    <MenuIcon  className={classes.menuButton2}  />
            </Button>
            
              <Typography variant="h6" noWrap  className={classes.titleUi}>
                {`${data.site.siteMetadata.title} ${store === null?"" : store.name}`}
              </Typography>
            
            <Grid
                container
                justify="flex-end"
                alignItems="center"
          
                spacing={3}
            >
          
              <Grid item  >
                  <Hidden smDown>
                      <div className={classes.toolbar2}>

                      <Button className={classes.menuButton2} onClick={(e)=>{handleClickHistory(e)}}>Store's</Button>
                          <Menu
                            id="simple-menu-history"
                            anchorEl={anchorElHistory}
                            keepMounted
                            open={Boolean(anchorElHistory)}
                            onClose={handleCloseHistory}
      
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                         
                          >

                            <MenuItem key="searchMenu" onClick={()=>{handleSearchStore()}}> 
                                  <SearchIcon  color="primary"  /> 
                                  <Typography variant="body1">
                                                Search online shop
                                   </Typography>
                            </MenuItem>

                            { (userProfile && userProfile.historyStores) ?
                              <div>
                                  {    userProfile.historyStores.map((hist) =>(              
                                       <MenuItem key={hist.storeId} onClick={()=>{handleHistoryShowStore(hist.storeId)}}> 
                                        <div className={classes.menuImage}>
                                          <Grid container justify="center" alignItems="center">
                                              <Grid item xs={12}>
                                              <Box display="flex"  alignItems="center" justifyContent="center" >
                                                <img
                                                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${hist.storeId}%2F${hist.storeId}?alt=media`}
                                                    alt="Uploaded Images"
                                                    height="60"
                                                    width="100%"
                                                    onError={onErrorImg}
                                                />

                                                </Box>
                                              </Grid>
                                                  
                                              <Grid item xs={12}>
                                                  
                                                  <Typography variant="body1">
                                                    { findStore(hist.storeId, hist.storeName)  }
                                                 
                                                  </Typography>
                                                
                                              </Grid>
                                                
                                          </Grid>
                                          </div>
                                        </MenuItem>
                                      ))
                                    } 
                                                                   
                                </div>
                                :
                                <div>
                                  {    stores.map((hist) =>(              
                                       <MenuItem key={hist._id} onClick={()=>{handleHistoryShowStore(hist._id)}}> 
                                        <div className={classes.menuImage}>
                                          <Grid container justify="center" alignItems="center">
                                              <Grid item xs={12}>
                                              <Box display="flex"  alignItems="center" justifyContent="center" >
                                                <img
                                                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${hist._id}%2F${hist._id}?alt=media`}
                                                    alt="Uploaded Images"
                                                    height="60"
                                                    width="100%"
                                                    onError={onErrorImg}
                                                />

                                                </Box>
                                              </Grid>
                                                  
                                              <Grid item xs={12}>
                                                  
                                                  <Typography variant="body1">
                                                    { findStore(hist._id, hist.name)  }
                                                 
                                                  </Typography>
                                                
                                              </Grid>
                                                
                                          </Grid>
                                          </div>
                                        </MenuItem>
                                      ))
                                    } 
                                                                   
                                </div>
                            }
                           
                          
                          </Menu>
                       
                        {isLoggedIn && 

                          <>
  
                            {hasStore &&
                              <Button className={classes.menuButton2} onClick={handleShowStore}>Home</Button>
                            }
                        
                            <Button className={classes.menuButton2} onClick={handleHelpPage}>Help</Button>
                            {hasStore &&
                             <Button className={classes.menuButton2} onClick={handleContactPage}>Contact Us</Button>
                            }      
                            <IconButton size="medium" action={avatarRef} onClick={ (e) => {handleClickAvatar(e)} } >
                                  <Avatar   className={classes.avatarSmall}
                                    alt={`Avatar n°${globalThis.PROFILE_USERNAME}`}
                                    src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}
                                    >
                                      { globalThis.PROFILE_USERNAME}
                                  </Avatar>
                            </IconButton>
  
                            <Menu
                              id="simple-menu-avatar"
                              anchorEl={anchorElAvatar}
                              keepMounted
                              open={Boolean(anchorElAvatar)}
                              onClose={handleCloseAvatar}
        
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                          
                            >
                            
                              {isLoggedIn && 
                                <div>
                                
                                    <MenuItem onClick={()=>{setShowPage(ScreenView.ACCOUNT)}}> Profile</MenuItem>
                                    <MenuItem onClick={()=>{setShowPage(ScreenView.CONTACTUS)}}> Contact Us</MenuItem>
                                    <MenuItem onClick={handleSignOut} > Sign Out</MenuItem> 
                                  </div>
                              }
                            
                            
                            </Menu>
                            
                          </>
                        }
                      
                          
                        {!isLoggedIn && 
                        <Grid container  direction="row" justify="center" alignItems="center">
                          
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleHelpPage}>Help</Button>
                          </Grid>
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleContactPage}>Contact Us</Button>
                          </Grid>
                          <Grid item>
                            <Button className={classes.loginButton} onClick={() => {   handleLogin(true); }}  >
                              Sign In
                            </Button>
                          </Grid> 
                          </Grid>
                        }
                     </div>    
                  </Hidden>
                  <Hidden mdUp>
                  <IconButton onClick={handleClickMobile}>
                    <DotsVertical className={classes.dotsVerticalIcon} />
                  </IconButton>
                    
                    <Menu
                      id="simple-menu-mobile"
                      anchorEl={anchorElMobile}
                      keepMounted
                      open={Boolean(anchorElMobile)}
                      onClose={handleCloseMobile}
                    >
                     
                      <MenuItem onClick={handleSearchStore}>
                        <SearchIcon  color="primary"  /> 
                        Search online shop
                        
                      </MenuItem>
                      <MenuItem onClick={handleHelpPage}>Help</MenuItem>
                      {isLoggedIn && <>
                        <MenuItem onClick={handleShowStore}>Home</MenuItem>
                        <MenuItem onClick={handleAccountPage}> Profile</MenuItem>
                        <MenuItem onClick={handleContactPage}>Contact Us</MenuItem>
                        <MenuItem onClick={handleSignOut} > Sign Out</MenuItem> </>
                      }
                            
                      {!isLoggedIn && 
                        <MenuItem onClick={() => {handleLogin(true);}}  >Sign In/Register</MenuItem>  
                      }
                    </Menu>
                    
                  </Hidden>
                </Grid>
                 
                </Grid>
          </Toolbar>
        </AppBar>
  
        <Drawer
          
          className={clsx(classes.drawer, { [classes.drawerShift]: false, })}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper:  !matchesMD&&!matchesSM&&matchesXS? classes.drawerPaperFull : classes.drawerPaper ,
          }}
        >
          <div>
           
        
            <List className={classes.headerSpacer2}>
       
                <ListItem button color="inherit" key='Welcome'  onClick={handleWelcomePage}>   
                  <ListItemIcon><HomeRoundedIcon  color="secondary" /></ListItemIcon>
                    <ListItemText className={classes.linkButton} primary='Welcome' />
                     
                </ListItem>
               
              </List>  
       
         
          <Divider />
          
       
         
          <Divider />
          {/**
              <List>
              <ListItem>
                  <Grid  className={classes.fabMap2} container  >

                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                      <input type="hidden" name="cmd" value="_s-xclick" />
                      <input type="hidden" name="hosted_button_id" value="BFQJ36PHWULPS" />
                      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                      <img alt="" border="0" src="https://www.paypal.com/en_PH/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                  </Grid>
                </ListItem>             
              </List>
          */}
          <List>
          
            { (profile)  &&  
             <ListItem button key='Account' onClick={handleAccountPage}>
                  <ListItemIcon>
                    <AccountCircleRoundedIcon color="secondary"  /> 
                  </ListItemIcon>
                  <ListItemText  className={classes.linkButton} primary='Account Settings' /> 
            
              </ListItem>
            }
          </List>
          { (profile) && <AccountDrawer profileId={profile.uid} store={hasStore} closedAccountDrawer ={closedStoreDrawer} /> }
          <List>   
              { 
                ( profile && !hasStore) &&  
                <ListItem button key='Store123' onClick={handleCreateStoreSettingPage}>
                  <ListItemIcon> 
                    <StoreRoundedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText  className={classes.linkButton} primary='Create Your Own Store and Start Selling.' /> 
                </ListItem>
              }
           </List>
          
          <List>
            { 
              ( profile && hasStore) &&  
             <ListItem button key='Store124' onClick={handleStoreSettingPage}>
                  <ListItemIcon> 
                    <StoreRoundedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText  className={classes.linkButton} primary='Online Store Settings' /> 
              </ListItem>
              }
          </List>
          { ( profile && hasStore) && <StoreDrawer profileId={profile.uid} store={hasStore} closedStoreDrawer ={closedStoreDrawer} /> }
          
              <Container className={classes.paypalUi}>
                <div id="paypal-button-container"></div>
              </Container>
          </div>
        
        </Drawer>
               
               { (!matchesMD&&!matchesSM&&matchesXS && openDrawer) ? <main  className={clsx(classes.content, { [classes.contentShift]: open, })}> </main> :
            <main  className={clsx(classes.content, { [classes.contentShift]: open, })}>
              <div className={classes.headerSpacer} />
              { (showPage === ScreenView.STORE ) &&
              <Container>
              <Grid container >
                  <Grid item xs={12}>
                  <Box display="flex"  alignItems="center" justifyContent="center" >
                    <img
                        src={bannerUrl}
                        alt="Store Banner"
                        height="200"
                        width="100%"
                        onError={onErrorImg}
                    />
                    </Box>
                  </Grid>
                   { store &&
                     <Grid item   xs={11} >
                        <Typography variant="body1" noWrap display="inline">
                            <Button  className={classes.linkUrlUi} onClick={()=>navigate(`?store=${store._id}`)} >
                                <Typography color="primary" variant="body2">   {store.name} </Typography>
                            </Button>  
                        </Typography>
                        <Typography  className={classes.headerCenter} noWrap display="inline">
                        </Typography>
                        <Grid container > 
                          <Grid item>   
                          <Button  className={classes.linkUrlUi}> <DateRangeIcon  className={classes.headerIcon} /> <Typography  variant="body2">{` : ${new Date(store.created).toDateString()} `}</Typography></Button>
                          </Grid>
                          {store && 
                             <Grid item> 
                                <Tooltip title={"Open google maps"}> 
                                  <Button className={classes.linkUrlUi} onClick={()=>showMap(store.center)} >
                                    <RoomIcon className={classes.headerIcon} color="primary" /> 
                                      <Typography variant="body2">{` : ${store.country},${store.district}${store.city?(','+store.city):''}`} </Typography>
                                  </Button>
                                </Tooltip>
                             </Grid> 
                          }
                          { store.email.length>0 &&  
                             <Grid item> <Button  className={classes.linkUrlUi}> 
                                <EmailIcon  className={classes.headerIcon} /> <Typography   variant="body2"> :{store.email} </Typography> </Button>
                             </Grid>}
                          { store.contactNo.length>0 && 
                             <Grid item>  <Button  className={classes.linkUrlUi}> 
                                <ContactPhoneIcon  className={classes.headerIcon}/> <Typography  variant="body2"> :  {store.contactNo}</Typography> 
                                </Button>
                              </Grid> }
                          <Grid item>
                            <Button  className={classes.linkUrlUi} onClick={()=>copyToClipboard(`${process.env.GATSBY_HOST}?store=${store._id}`)} >
                              <LinkIcon  className={classes.headerIcon}  color="primary"/><Typography color="primary">Copy and Share this shop URL link. </Typography>
                            </Button>  

                          </Grid> 
                      
                        </Grid>
                        <Grid>
                        {/**you can add noWrap on typography */}
                       <Typography variant="caption"  display="inline">
                          Add an Item and start a conversation with the seller privately on the draft dialog. just click the "Cart Button" .
                        </Typography> 
                        </Grid> 
                        <Typography variant="body1" display="inline">
                          {store.description}
                        </Typography>      
                     </Grid>
                    }
                  { store  && 
                    <Grid item xs={1}  >
                      <Grid container direction="row">
                          <Grid item>
                            <Tooltip title={storeInHistory?"Shop is already on the store list.":"Add to your store list."}>
                                {storeInHistory? 
                                <IconButton size="medium"  >
                                    <BookmarkIcon  style={{ color: green[500] }} />
                                  </IconButton>
                                  :
                                  <IconButton size="medium" onClick={ () => {addStoreHistory(store)} }   >
                                      <BookmarkIcon color="primary"/>
                                  </IconButton> 
                                } 
                            </Tooltip>
                            <Tooltip title="Switch display to two column adds"> 
                              <Switch
                                  checked={switchSx}
                                  onChange={handleChangeSwitch}
                                  color="primary"
                                  name="checkedB"    
                                  size="small"                        
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </Tooltip>
                          </Grid>
                          
                        </Grid>
                    </Grid>
                     
                  }

                   
                </Grid>
                </Container>
              }
            {/**
                  {props.children} */}
                    {  
                      (isLoggedIn && showPage === ScreenView.ACCOUNT) ? <Account setShowPage={setShowPage}/> :
                      (showPage === ScreenView.STORE_PRODUCT) ? <StorePosts  userProfile={userProfile} store={store}/> :
                      (isLoggedIn && store && showPage === ScreenView.STORE) ? <ProductPosts location={urlLocation}  isLoggedIn={isLoggedIn} store={store} colCount={colCount}/> :
                      (isLoggedIn && hasStore && showPage === ScreenView.STORE_SETTING) ? <StoreSetting setShowPage={setShowPage}  storeId={hasStore._id} userProfile={userProfile}/> :
                      (isLoggedIn && !hasStore && showPage === ScreenView.STORE_SETTING) ? <StoreSetting setShowPage={setShowPage}  storeId={globalThis.STORE_ID} userProfile={userProfile}/> :
                      (!isLoggedIn && store && showPage === ScreenView.STORE) ? <ProductPosts location={urlLocation}  isLoggedIn={isLoggedIn} store={store}  colCount={colCount}/> :
                      (!isLoggedIn && store && showPage === ScreenView.SCHEDULING ) ? <WorkSchedule location={urlLocation}  isLoggedIn={isLoggedIn} store={store}  colCount={colCount}/> :
                      ( showPage === ScreenView.WELCOME ) ? <Welcome storeId={storeId} prt={prTitle} prd={prDescription} fid={productId} /> :
                      ( showPage === ScreenView.HELP ) ? <Helps /> :
                      ( store && showPage === ScreenView.CONTACTUS ) ? <ContactUsForm setShowPage={setShowPage} userProfile={userProfile} store={store}/> :
                     
                        <Typography variant="body2" color="textSecondary" align="center">
                           <img  src={globalThis.LOADING_IMAGE} alt={'loading'}/>
                        </Typography>   
                    } 

                    {/**
SHOWPAGE_PUBLICPOST ==0
SHOWPAGE_ACCOUNT=1
SHOWPAGE_POST=2
SHOWPAGE_WELCOME=3
SHOWPAGE_HELPS=4
SHOWPAGE_CONTACTUS=5
 
                     */}
            </main>
}
 
   
  
        {(openProductEntry && hasStore !== null) && <ProductEntry openProductEntryDialog={openProductEntry} handleCloseProductEntryDialog={handleCloseProductEntryDialog} userProfile={userProfile} store={hasStore} prid={productId} matchesXS={isFullScreen}/>}
        {(openSalesOrder && hasStore !== null) && <SalesOrder openSalesOrderDialog={openSalesOrder} handleCloseSalesOrderDialog={handleCloseSalesOrderDialog} userProfile={userProfile} store={hasStore}  slid={salesOrderId}  matchesXS={isFullScreen}/>}
        {(openOrder ) && <Order openOrderDialog={openOrder} handleCloseOrderDialog={handleCloseOrderDialog} userProfile={userProfile}  orderId={orderId}  matchesXS={isFullScreen}/>}
        {(openDraftOrder ) && <DraftOrder openOrderDialog={openDraftOrder} handleCloseOrderDialog={handleCloseDraftOrderDialog} userProfile={userProfile}  orderId={orderId}  matchesXS={isFullScreen} storeCurrency={hasStore.currency}/>}
       
        {toggleLogin && <LoginModal setToggleLogin={setToggleLogin}  />}

        {registeredOpen && <RegisteredDialog 
          open={registeredOpen} 
          setRegisteredOpen={setRegisteredOpen}
          handleRegisteredDialog={handleRegisteredDialog} /> }

     
        <Hidden smDown>
          <Drawer
              className={classes.drawerRight}
              variant="persistent"
              anchor="right"
              open={true}
              classes={{
                paper: classes.drawerPaperRight,
              }}
          > 
           <div className={classes.headerSpacer3}>
            
                 <Adds/>      
           </div>
          </Drawer>
        </Hidden>
      </div>
   
    );
}
 
 
 export { Layout as default}
 