import React , {useState, useEffect, Fragment, useRef} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';
import { navigate } from "gatsby"
import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Divider, Grid, Container, TextField, Menu, MenuItem, CircularProgress, Icon, ListItemIcon, Link } from '@material-ui/core';
import Comments2 from './Comments2';
import CommentEntry from './CommentEntry';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {utcTime,utcTimeOrg} from '../utils/MantawiTime'; 
import { OpenSourceInitiative, FireHydrantAlert } from 'mdi-material-ui';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import green from "@material-ui/core/colors/green";
 
import SEO from '../components/SEO';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import ConfirmShareDialog from './ConfirmShareDialog';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import BlockIcon from '@material-ui/icons/Block';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//const useStyles = ( props ) => makeStyles((theme: Theme) =>
const useStyles =  makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 345,
      minHeight: 420,      
      

    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    
    button: {
      textTransform: "none"
    },
    iconUi:{
        width:'100%',
        marginTop: -6
    },
    buttonPublish:{
      textTransform: "none",
      marginTop: '10px'
    },
    buttonPublish2:{
      textTransform: "none",
   
    },
    cardContent:{
      oveflow: "auto",
    },
    actionsUi1:{
      
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      width: "50%"
    },
    actionsUi2:{
   
      marginTop: '-15px',
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
      float: "right",
      width: "50%"
    },
    cardActionsUi:{
      width: "100%"
    },
   gridUi:{
    
    marginTop: '-15px'
   },
   customBadge: {
    backgroundColor: "green",
    color: "white"
  },
    menuItemUi:{
      width: 'auto'
    },
    titleUi:{
      padding: 10,  
    } ,
    descriptionUi:{
      minHeight: 80,
      maxHeight: 80,
      overflow: "auto",
      display: "block",
     
    } ,

    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }, 
    adds:{
      width: 330,
      height: 80
    },
    storyUi:{
      overflow: 'scroll'
    },
    avatarParentUi:{
      marginTop: -8,
    },
    avatarChildUi:{
      marginLeft: -10,
    },
    sourceUi:{
      paddingLeft: 10,
    }
  
  }),
);
 
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      
      right: -20,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    }, 
  }),
)(Badge);

 
TimeAgo.addLocale(en);

 
const Product = ({product,tagPost, store, handleProductOrderCount, currentOrders, colCount, index, isLoggedIn}) => {

//  console.log("tagPost: " + tagPost +  "=" + index);
 //const classes = useStyles( {rootHeight: colCount ===12? 0: 300} )(); 
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [ postDialogView, setPostDialogView] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [showMore, setShowMore] = useState(0);
  const myRef = useRef(null);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const handleCloseSnackBar = () =>{
    setOpenSnackBar(false);
  }

  let formatter = new Intl.NumberFormat('en-IN', {
   
    minimumFractionDigits: 2
  })

  
function linkify_o(text) {
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
  });
}

//making your text to html
function createInnerHtml(key, story){
  //var ht = linkify_o(story);
  document.getElementById(key).innerHTML =story;
}

  function copyToClipboard(theLink) {
   // checkView();
    const el = document.createElement('textarea');
    el.value = encodeURI(theLink);
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function textSlice(text, count, insertDots){
    if(insertDots){ 
      text.length > count? setIsMore(true) :setIsMore(false);
    }
    return text.slice(0, count) ;
  }

  const hanldeShowMore = () =>{
     if(showMore === 0){
      setShowMore(1);
     }else{
      setShowMore(0);
     }
    
  }

  

  // const scrollToRef = (ref) =>{
  //   ref.current.scrollIntoView();
  //   //window.scrollTo(0, ref.current.offsetTop)   
  // } 

  const handleExpandClick = () => {
   
    setExpanded(!expanded);
  };

  const handlePostDialogView =(status: boolean) =>{
    //tomorrow move it to the index.
   // setPostDialogView(status);
  }

  const timeAgo = new TimeAgo('en-US');
  const [newComment, setNewComment] = useState(null);
  const [editStory, setEditStory] = useState(true);
  const [orderCount, setOrderCount] = useState(0);

  const handleOrderCount = (operation) =>{
    if(product.status !== "new" && product.status !== "available") {
      return;
    }
    
    if(  store.userId !== globalThis.PROFILE_UID  ){ 
       setOrderCount(handleProductOrderCount(product,operation));
    }
  }

  useEffect(()=>{
    let _orderCount = null;
    console.log("currentOrders");
    if( currentOrders !== null && currentOrders !== undefined  ){
      if(currentOrders.items){ 
        _orderCount=currentOrders.items.find(e => e.productId === product._id);
      }
    }
    if(_orderCount === null || _orderCount === undefined){
      setOrderCount(0);
    }else{
      setOrderCount(_orderCount.count);
    }

  },[currentOrders])

  useEffect(()=>{
    createInnerHtml("PD"+product._id, product.description)  
  }) ;

//  const [order, setOrder] = useState((orderCount&&orderCount.count)?orderCount.count:0);
  // const findUserPostTag = () =>{
  //     var isFound = false;
  //     return tagPosts.find(e => e._id === postAndUserId );
  // }

  // const [tagPost, setTagPost] = useState(null);
  //media 
  //https://stackoverflow.com/questions/39188405/how-to-use-video-html-tag-instead-of-img-tag-in-material-ui-card-component
  // useEffect(()=>{
  //   setOrder((orderCount&&orderCount.count)?orderCount.count:0);
  // },[orderCount])

  const handleActionClick = (optType) =>{
   // console.log(optType);

   if(store.userId === globalThis.PROFILE_UID  || globalThis.PROFILE_UID == undefined ){
     return;
   }
   
    var key =  product._id+globalThis.PROFILE_UID;
    if(tagPost){
      //most updates happens only to like, dislike, tag

      tagPost.productName = product.productName;
      tagPost.description = product.description;
      tagPost.storeName = store.name;
      if(optType ==='like' || optType ==='disLike' || optType ==='tag' || optType ==='shared' ){
          var isLike = false;
          var isDisLike = false;
          var isTag = false;
          var isShared = false;
          var tagStatus = false;
          var isLikeAndDisLikeEqual = tagPost.like === tagPost.disLike;
          if(optType === 'like' && !tagPost.like){
              tagPost.like = true;  
              tagPost.disLike = false; 
              isLike = true;
          }
          if(optType === 'disLike' && !tagPost.disLike){
              tagPost.disLike = true;
              tagPost.like = false; 
              isDisLike= true;
          }
          if(optType === 'tag'){
              tagPost.tag = !tagPost.tag;
              tagStatus = tagPost.tag ;
              isTag= true;
          }

          if( optType ==='shared'){
            tagPost.shared = !tagPost.shared;
            tagStatus = tagPost.shared ;
            isShared= true;
          }
         
          tagPost.updated = utcTime();
          

          firestore.collection("tags").doc(key)
          .update( tagPost ).then(()=>{
            console.log("tag post updated........");
         
          }).then(()=>{
            if(isLike || isDisLike || isTag || isShared){
             firestore.collection("products").doc(product._id).get().then(
              (doc) =>{
                if(doc.exists){
                   var data = doc.data();
                   if(isLike){
                      data.countLike = data.countLike + 1;
                      if(data.countDisLike > 0 && !isLikeAndDisLikeEqual){
                       data.countDisLike = data.countDisLike - 1;
                      }
                   } 
                   if(isDisLike){
                     if(data.countLike > 0 && !isLikeAndDisLikeEqual){ 
                       data.countLike = data.countLike - 1;
                     }
                      data.countDisLike = data.countDisLike + 1;
                   } 
                   if(isTag){
                      if(tagStatus){
                        data.countTag = data.countTag + 1;
                      }else{
                        if(data.countTag > 0){
                          data.countTag = data.countTag - 1;
                        }
                      }
                   }
                   if(isShared){
                     
                      if(tagStatus){
                        data.countShare = data.countShare + 1;
                      } 
                   }

                   firestore.collection("products").doc(product._id)
                   .update( data ).then(()=>{
                     console.log("post updated........");
                  
                   }).catch( (error)=>{
                    console.error(error);
                  });

                }
              })
            }
          }).catch( (error)=>{
            console.error(error);
          });
 
      }

    }else{
      //does not exists creat a new one
      var createdTime = utcTime();
      
      var _tagPost = {
        productId: product._id,
        storeId: store._id,
        productOwnerId: product.userId,
        productName: product.productName,
        storeName: store.name,
        userId: globalThis.PROFILE_UID,
        description: product.description,
        view: true,
        tag: optType==='tag'?true:false,
        like: optType==='like'?true:false,
        disLike: optType==='disLike'?true:false,
        shared: optType==='shared'?true:false,
        hasUpdates: true,
        created: createdTime,
        updated: createdTime,
      }
 
      firestore.collection('tags').doc(key).set(_tagPost)
          .then( ()=>{
            
            tagPost ={ tagId: key, ..._tagPost};
          }).catch( (error)=>{
            console.error(error);
          });;
    }
    
    console.log("tagPost: " + tagPost);
  }

  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSending,setIsSending] = useState(false);
  const [postMessage, setPostMessage] = useState("");
  

  const cancelPostRecord =() =>{
    setEditStory(true);
    setShowMore(0);
    setIsSending(false);
  }
  
  const handleClickMenu = (event) => {
   
    setAnchorEl(event.currentTarget);
    //checkView();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenu =(stype) =>{
    setAnchorEl(null);
    if(stype==='edit'){
      setEditStory(false);
      setShowMore(-1);
    }
 

    if(stype==='delete'){
       
        firestore.collection("products").doc(product._id).delete().then(
          () =>{
            console.log('posted delete: ' + `${product.userId}` );   
          }
        ).catch( (error)=>{
          console.error('posted delete error: ' + error);
        });
    }

    console.log(stype)
  }
  const handlePostMessage = (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     

    setPostMessage(value );
  }

  const [confirmSharedDialog, setconfirmSharedDialog] = useState(false);

  const handleCloseConfirmShareDialog =() =>{
    setconfirmSharedDialog(false);
  }

  const handleOpenConfirmShareDialog = () =>{
    console.log("handleOpenConfirmShareDialog:");
    var openConfirm = false;
    if((tagPost === undefined || tagPost === null)){
      openConfirm = true;
    } else{
      openConfirm = !tagPost.shared;
    }

    if(product.userId !== globalThis.PROFILE_UID &&  openConfirm ){
      setconfirmSharedDialog(true);
    }

   
  }

    
  const [privacy, setPrivacy] = useState(product.sharedType);
  const [anchorElP, setAnchorElP] = React.useState<null | HTMLElement>(null);
   
  const handleClickFeelings = (event: React.MouseEvent<HTMLElement>) => {
    if( !editStory){
    
      setAnchorElP(event.currentTarget);
    }
  
  };

  const handleCloseFeelings = () => {
    setAnchorElP(null);
  };

  const handleSelectPrivacy = (level)=>{
     setPrivacy(level);
     handleCloseFeelings();
  }

  const handleUrlLink =() =>{
   
    if( product.linkFiles === undefined){

    }else if( product.linkFiles.length > 0){
         console.log("post.linkfiles");
      
    }
 

  }
 
  const checkView =() =>{
    if(tagPost === null || tagPost === undefined){
      firestore.collection("posts").doc(product._id).get().then(
        (doc) =>{
          if(doc.exists){
             var data = doc.data();
             var count  = data.countView + 1;
             firestore.collection("posts").doc(product._id)
             .update( {countView : count} ).then(()=>{
               console.log("post view count updated........");
            
             }).catch( (error)=>{
              console.error(error);
            });

          }
        })

    }
  }
  const preventDefault = (event) =>{ 
    event.preventDefault();
    checkView();
  }

  
  const [psHeight, setPsHeight] = useState(300);
  // useEffect(()=>{
  //   if(colCount === 12){
  //     setPsHeight(0);
  //   }else{
  //     setPsHeight(300);
  //   }
  // },[ colCount]) 

  const productStatusType = (statusType)=>{
    if(  statusType==='cancelled' ){
      return 'Cancelled'
    }
    else if( statusType==='available' ){
      return 'Available'
    }
    else if(  statusType==='outofstock' ){
      return 'Out of Stock'
    }  
    else if(  statusType==='delete' ){
      return 'Deleted'
    }else{
      return 'New'
    }
  }
  
  return (
    <Card ref={myRef}  className={classes.root}  variant="outlined">
        
         <SEO title={product.productName} description={product.description} 
            url={`${process.env.GATSBY_HOST}?store=${store._id}&fid=${product._id}`}
            image="https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fstores%2Fstr-ABoolHgxSnW0AtwaG2wiObdCdmv2%2FddWypHvsrWt5kzIVsTe8%2Fcow.png?alt=media&token=6f83f537-0feb-4a46-b262-82c721baf03a"
            >
          
        </SEO>
      
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${product.userId}?alt=media`}  className={classes.avatar}>
              {store.name}
          </Avatar>
        }
        action={
           <>
           {/** nice, this is working
            <IconButton onClick={ (event) => handleClickFeelings(event, post)}></IconButton>
             */}

            <Tooltip title={ `${productStatusType(product.status)}`  }>
              <IconButton onClick={handleClickFeelings}>
              {   product.status==='new' &&  <NewReleasesIcon   color="primary" fontSize="small" />  }
                {   product.status==='cancelled' && <BlockIcon color="primary"  fontSize="small" />  }
              {   product.status==='available' && <EventAvailableIcon color="primary" fontSize="small" /> } 
                {   product.status==='outofstock' &&  <LockIcon  color="primary" fontSize="small" /> }
              
              </IconButton>
           </Tooltip>

          <IconButton aria-label="settings" aria-controls="setting-menu" aria-haspopup="true" onClick={handleClickMenu}>
            <MoreVertIcon />
          </IconButton>
          </>
      
        }
        title= { <Typography variant="h5"> {product.productName} - {productStatusType(product.status)} </Typography>} 
      
        subheader= { /**subheader={`${timeAgo.format(utcTimeOrg(product.updated),'twitter')}`} */
         product.productType !== 'ads' ?   (store.showPrice ?
          <Typography variant="h5" color="primary">   {store.currency ==='PHP' ? <>&#8369;</>  : '$'}  {  formatter.format(product.price) } / {product.unit}</Typography>
          :
          <Typography variant="subtitle2"  > Price: --</Typography>)
          : <Typography variant="subtitle2"  >   </Typography>
         } 

      />
    

        <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                id="customized-menu"
                anchorEl={anchorElP}
                keepMounted
                open={Boolean(anchorElP)}
                onClose={handleCloseFeelings}
              >
                             
                  <MenuItem   className={clsx({ [classes.menuItemSelected]: privacy==='public', })} 
                      onClick={()=>{handleSelectPrivacy("public")}}>
                    <ListItemIcon>
                      <NewReleasesIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="New" />
                  </MenuItem>
                  <MenuItem  className={clsx({ [classes.menuItemSelected]: privacy==='friend', })} onClick={()=>{handleSelectPrivacy("friend")}}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Friend" />
                  </MenuItem>
                  <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='shared', })} onClick={()=>{handleSelectPrivacy("shared")}}>
                    <ListItemIcon>
                      <PeopleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Friend Of Friends" />
                  </MenuItem>
                  <MenuItem className={clsx({ [classes.menuItemSelected]: privacy==='private', })} onClick={()=>{handleSelectPrivacy("private")}}>
                    <ListItemIcon>
                      <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Private" />
                  </MenuItem>
                  
                </Menu>        

        <Typography
            className={classes.titleUi}
            id="title-textarea"
            variant="h6"
        >
        { product.productName  }
        </Typography>
        <PostImage tileData={product.urlFiles} storeId={product.storeId} productId={product._id} psHeight={psHeight} source={'product'}  productType={product.productType} colCount={colCount} />
         
 
    
      <CardContent>
      <Typography id={"PD"+product._id} className={classes.descriptionUi}>          
               
        </Typography>
 
  {/**
   *  <style>
    div {
      width: 200px;
      height: 100px;
      line-height: 20px;
      word-break: break-all;
      background-color: skyblue;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 5;
      -moz-line-clamp: 5;
      -ms-line-clamp: 5;
      line-clamp: 5;
      overflow: hidden;
	}
  </style>
   *   <div className={classes.descriptionUi}>
        { product.description}      
        </div>
   * <Typography className={classes.descriptionUi}  noWrap 
            display="block"
            >          
        </Typography>
        <TextareaAutosize
          rowsMax={4}          
          placeholder=" "
          defaultValue={ product.description}      
        />
 */}     
      </CardContent>

     
      <CardActions disableSpacing >
  
            <Grid className={classes.gridUi} container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                      <IconButton  aria-label="view" onClick={()=>handlePostDialogView(true)}>
                        <Badge  color="primary"  badgeContent={product.countView}  max={999} >
                          <VisibilityIcon  fontSize="small" color={!tagPost?"disabled":tagPost.view?"primary":"disabled"} />
                              </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton   aria-label="feelings" onClick={()=>handleActionClick('like')}>
                        <Badge  color="primary"  badgeContent={product.countLike}  max={999} >
                          <ThumbUpIcon fontSize="small" color={!tagPost?"disabled":tagPost.like?"primary":"disabled"}/>
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton  aria-label="feelings"  onClick={()=>handleActionClick('disLike')} >
                        <Badge  color="primary"  badgeContent={product.countDisLike}  max={999}>
                          <ThumbDownIcon fontSize="small"  color={!tagPost?"disabled":tagPost.disLike?"primary":"disabled"}/>
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                    <Tooltip title="Copy and paste, this Product url to share with your friends">
                      <IconButton  aria-label="url link"  
                            onClick={()=>copyToClipboard(`${process.env.GATSBY_HOST_PRLINk}?store=${store._id}&fid=${product._id}&prt=${product.productName}&prd=${product.description.substring(0, 140)}&pri=${product.urlFiles.length > 0?product.urlFiles[0].fileName:"miniens.png"}`)} >
                         
                          <LinkSharpIcon fontSize="small"  color="primary"/>  
                        
                      </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
 
                    </Grid>
                      {product.productType !== 'ads' &&  
                   
                        <Grid item>
                            <Tooltip title={ "Remove item"} >
                              <IconButton aria-label="remove"    onClick={()=>{handleOrderCount("remove")}} >                             
                                    <RemoveIcon fontSize="small" color={ (store.userId !== globalThis.PROFILE_UID  && globalThis.PROFILE_UID !== undefined && product.status !== "new" && product.status !== "available")? "primary":"disabled"} />                   
                              </IconButton>
                            </Tooltip>
                          
                            <Tooltip title={ "Buy now."} >
                              <IconButton aria-label="buy now">
                                  <Badge badgeContent={orderCount}  max={999} color="primary">
                                    <ShoppingCartIcon fontSize="small" color={(store.userId !== globalThis.PROFILE_UID  && globalThis.PROFILE_UID !== undefined  && product.status !== "new" && product.status !== "available") ? "primary":"disabled"} />
                                  </Badge>
                              </IconButton>
                            </Tooltip>
                        
                            <Tooltip title={ `Add an Item and start a conversation with the seller privately on the cart dialog. just click "Cart Button"`} >
                              <IconButton aria-label="addition" onClick={()=>{handleOrderCount("addition")}}>                            
                                  <AddIcon fontSize="small" color={ (store.userId !== globalThis.PROFILE_UID  && globalThis.PROFILE_UID !== undefined  && product.status !== "new" && product.status !== "available")? "primary":"disabled"} />                        
                              </IconButton>
                            </Tooltip>
                        </Grid>                   
                      }
                    <Grid item>

                    <Tooltip title={tagPost&&tagPost.tag?'You are following this item':'Tag this item.'}>
                      <IconButton  aria-label="add to favorites" onClick={()=>handleActionClick('tag')}>
                        {tagPost&&(tagPost.tag?
                          <Badge classes={ {badge:classes.customBadge}}  badgeContent={product.countTag}  >
                             <LocalOfferIcon fontSize="small" color="primary"/>
                          </Badge>
                        :
                          <Badge color="primary"  badgeContent={product.countTag}  >
                            <LocalOfferIcon fontSize="small" color="primary"/>
                          </Badge>)
                        }
                        {!tagPost&& <Badge color="primary" badgeContent={product.countTag}  >
                            <LocalOfferIcon fontSize="small" color="disabled" />
                          </Badge>
                        }
                      
                      </IconButton>
                      </Tooltip>
                    </Grid>
                 
        
              </Grid>
                 
      </CardActions>
 
      <Divider/>
   
      <Button  fullWidth color="primary" 
            disabled={!isLoggedIn}
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {product.countComment>0?product.countComment:""} Comments              
        </Button>
  
         

      <Collapse in={expanded} timeout="auto" unmountOnExit>
      
         <Grid container>   
             <CommentEntry  tagPost={tagPost} product={product} storeId={store._id} /> 
         </Grid>
          <Grid container>
              {expanded &&
              <Comments2  productId={product._id}/>    
            }  
          </Grid>

        {product.countComment > 3   ?
            <Button  fullWidth color="primary" 
            className={classes.button} 
            aria-label="show more"
            onClick={handleExpandClick}
            endIcon={ <Icon className={classes.iconUi}>
              <ExpandMoreIcon  color="primary" className={clsx(  {
                [classes.expandOpen]: expanded,
              })}/>
            </Icon>}
        > 
            {product.countComment>0?product.countComment:""} Comments  {product.countShare>0?product.countShare:""} Shares              
        </Button>
        :
        <></>
        }


      </Collapse>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Card>
 
  
  );
}


export default Product;


