import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {   makeStyles, Theme, createStyles } from '@material-ui/core/styles';
 


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    buttonUi: {
      textTransform: "none",
  
    },
  
  }),
);
export default function RegsiteredDialog({open,  setRegisteredOpen, handleRegisteredDialog}) {
 
  
  const classes = useStyles();

  return (
    
     
      <Dialog
        open={open}
        onClose={()=>{setRegisteredOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Post"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              The Post you have accessed, requires that you are, login-in.
           </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  variant="outlined" className={classes.buttonUi} onClick={()=>{handleRegisteredDialog('register')}} color="primary">
            Login or Register Now
          </Button>
          
          <Button   variant="outlined" className={classes.buttonUi}  onClick={()=>{handleRegisteredDialog('proceed')}} color="primary" >
            Proceed To Public Post
          </Button>
         
        </DialogActions>
      </Dialog>
  
  );
}