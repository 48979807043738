import 'date-fns';
import React , {useState,useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Grid, Divider, AppBar, Toolbar, IconButton, Typography , Slide, Card, CardHeader, Avatar, CardContent, CardActions, Badge, Collapse, Container, Icon, TextareaAutosize, TextField, ListItemIcon, ListItemText, Snackbar, CardMedia, GridList, GridListTile, GridListTileBar, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import PostingImage from '../templates/PostingImage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import CancelIcon from '@material-ui/icons/Cancel'; 
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MyMapBox from './myMapBox';
import {auth, useAuth,  firestore , firebase, useFirestoreDoc, useFirestoreQuery}  from 'gatsby-theme-firebase';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
//import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import InfoIcon from '@material-ui/icons/Info';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime} from '../utils/MantawiTime'; 
import { Alert, AlertTitle } from '@material-ui/lab';
import PeopleIcon from '@material-ui/icons/People';
// SHARING -- PRIVATE, FRIEND, PUBLIC
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// UPLOAD VIDEO/IMAGE
// TAG A FRIEND ???
// WHERE ARE YOU LOCATION
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PublishIcon from '@material-ui/icons/Publish';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import CommentsSnO from '../templates/CommentsSnO';
import CommentEntrySnO from '../templates/CommentEntrySnO';
import ScheduleIcon from '@material-ui/icons/Schedule';
//npm i @date-io/date-fns use 1.3 something 
//npm i date-fns
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDialog:{
        background: "rgba(0,0,0,0)",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    expand4: {
        transform: 'rotate(0deg)',
        marginLeft: '5%',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      border: 1,
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "none",
      minWidth: '200px',
    },
    buttonMenu: {       
      marginTop: 10,
      textTransform: "none",     
    },
    buttonSchedule: {             
      textTransform: "none",   
      
      marginBottom: 10
    }
    ,
    input: {
      display: 'none',
    },
    textLocationField: {
      marginTop: '10px',
    },
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleTextUi:{
      marginTop: 20,
    },
    loadingUi:{
      width: "100%",        
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    imageUi:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 200,
      minWidth: 200
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    detailsUi:{
      border: '1px solid rgba(0, 0, 0, .125)',
    },
    iconUi:{
      width:'100%',
      marginTop: -6
   },
   negTA1:{
    marginRight: 5,
  }
  
  }),
);
 

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

let storage = undefined;


 
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

 //store purpose was just to defualt to store, same with userprofile
const SalesOrder = ( { openSalesOrderDialog, handleCloseSalesOrderDialog,  userProfile, store ,  slid, matchesXS}) =>{
  const classes = useStyles();
  const messageInput = useRef(null);
  
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [widthLabel, setWidthLabel] = React.useState("md"); 
  
  const [isSending,setIsSending] = useState(false);

  const gotoTokTok=()=>{
    const showMapLocationUrl   = `https://toktok.ph/index.php?/delivery/1111090526`;    
    window.open(showMapLocationUrl, '_blank');
  }

  
  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setFullWidth(event.target.checked);
     
    if(fullWidth){
        setWidthLabel("md");
    }else{
        setWidthLabel("sm");
    }
    setMaxWidth('md' as DialogProps['maxWidth']);
  };

  const deleteMe = (event, item) =>{
    console.log("deleteme: " + item);
    salesOrder.totalCount = salesOrder.totalCount - item.count;
    salesOrder.items = salesOrder.items.filter( (_item) => _item.productId !== item.productId);
    updateSalesOrder();

  }
  
  const [expanded, setExpanded] = React.useState(false);
 
  const [today, setToday] = useState(new Date().toLocaleDateString());
  const [progress,setProgress] = useState([]);
 
  const [openSnack,setOpenSnack] = useState(false);
  const [alertMessage,setAlertMessage] = useState("");
  const [status, setStatus ]=  useState("open");
  const [schedule,setSchedule] = useState(null);
  const [schedule2,setSchedule2] = useState("Schedule");
  // const [scheduleDate,setScheduleDate] = useState(null);
  // const [scheduleTime,setScheduleTime] = useState(null);
  
  const [notes, setNotes ]=  useState(null);
  const [details, setDetails ]=  useState(null);
  const [totalAmount, setTotalAmount ]=  useState(0);
  const [negTotalAmount, setNegTotalAmount ]=  useState(0);
  const [ order, setOrder] = useState(null);
  const [anchorElSchedule, setAnchorElSchedule] = React.useState<null | HTMLElement>(null);
  const [scheduleTemp, setScheduleTemp] = useState(null)

  const handleSchedulePage = (event) =>{
    if(schedule === null){
      var _today = new Date();
      setSchedule(_today);
      setScheduleTemp(null);
      setSchedule2(_today.toLocaleString().replace(":00",""));     
    }

    setAnchorElSchedule(event.currentTarget);
  }
  const handleScheduleClosePage = (event) =>{
    setAnchorElSchedule(null);
  }

  const handleScheduleCancel = (event) =>{
    setSchedule(scheduleTemp);
    setSchedule2(scheduleTemp ? scheduleTemp.toLocaleString().replace(":00","") : "Schedule");    
    setAnchorElSchedule(null);
  }

  const handleScheduleOk = (event) =>{
    setScheduleTemp(schedule);
    setAnchorElSchedule(null);
  }
  

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };


 
  
  // const handleDateChange = (event) => {

  //   setScheduleDate(new Date(event.target.value));
  // };

  const handleDateChange = (date: Date | null) => { 
    setSchedule(date);
    setSchedule2( date.toLocaleString().replace(":00",""));
  };

  // const handleTimeChange = (event) => {
   
  //   console.log("handleTimeChange");
  //   var _date = new Date(scheduleDate.getUTCFullYear(), 
  //                        scheduleDate.getUTCMonth(),
  //                        scheduleDate.getUTCDate(),
  //                        event.target.valueAsDate.getUTCHours(),
  //                        event.target.valueAsDate.getUTCMinutes(),
  //                        0,0);
  //   setScheduleTime(_date);
  // };

  // useEffect(()=>{
  //    if(scheduleTime || scheduleDate){ 
  //     var _date = new Date(scheduleDate.getUTCFullYear(), 
  //             scheduleDate.getUTCMonth(),
  //             scheduleDate.getUTCDate(),
  //             scheduleTime.getHours(),
  //             scheduleTime.getMinutes(),
  //           0,0);
  //       console.log("handleTimeChange1: " + _date.toDateString());
  //       console.log("handleTimeChange2: " + _date.toLocaleDateString());
  //     //"2017-05-24"
  //     setSchedule( _date );
  //     setSchedule2( _date.toLocaleString());
  //    }
  //  // setSchedule(_schedule);

  // },[scheduleDate,scheduleTime])

  const handleNotes= (event) =>{     
    setNotes(event.target.value ? event.target.value.substring(0,1000) : "");
  }
  const handleNegTotalAmount= (event) =>{     
    setNegTotalAmount(event.target.value);
  }
 
  const handleDetails = (event) =>{   
    setNotes(event.target.value);
  }
  
  const checkClosingDialog =()=>{
    if(!isSending){ 
      handleCloseSalesOrderDialog();
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
 
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const updateSalesOrder = () =>{
    if(isSending){
      return;
    }
    const postedDate = utcTime();
    setIsSending(true);
    setSnackMessage("Sales Order Update sucessfull.");
    firestore.collection("sales").doc(slid).update(
      {notes:notes,status: status, hasUpdates: true, updated: postedDate,
        schedule: schedule ? schedule.getTime() : null,
        negTotalAmount: negTotalAmount, items: salesOrder.items, totalCount: salesOrder.totalCount
      })
    .then( () =>{
      firestore.collection("orders").doc(salesOrder.orderId)
        .update({status: status,hasUpdates: true,updated: postedDate, 
          soSchedule:  schedule ? schedule.getTime() : null, negTotalAmount: negTotalAmount})
        .then( ( ) =>{ 
          console.log( "salesorder: " + status );
        });
        createdHistory("sales",slid,salesOrder.orderId,notes,status);
    }).then(()=>{
      setOpenSnack(true);
      setIsSending(false);
    }).catch((err)=>{
      setSnackMessage("Something wrong with your update. : " + err);
      setOpenSnack(true);
      setIsSending(false);
    });
  }

  const createdHistory = (_source: string, _storeId: string, _orderId: string, _notes: string, _status: string) =>{
    try{
      const key = `${_orderId}${_storeId}`;
      var today = utcTime();
     console.log("history: " + key);
      firestore.collection("history").doc(key).get().then( (doc) =>{
        if(doc.exists){
          console.log("doc exits history: " );
            var history = doc.data();
            var details = [...history.details, {
              created: utcTime(),
              source: _source,
              status: _status,
              notes: _notes,
            }];
            history.updated = today;
            history.details = details;
            firestore.collection("history").doc(key).update(history).then(()=>{
              console.log("history updated");
            })
        }else{

          var _history = {
            historyId: key,
            created: today,
            updated: today,
            orderUserId: salesOrder.orderUserId,
            storeUserId: salesOrder.storeUserId,
            details: [{  created: today,  source: _source,
              status: _status,  notes: _notes,  }]
          }
          firestore.collection("history").doc(key).set(_history).then(()=>{
            console.log("history created");
          })
        }
      })
    }catch(error){
      console.log("error: " + error);
    }
  }
  
 const [overSize, setOverSize] = useState([]);
 const [snackMessage, setSnackMessage] = useState("");
 const [fileCountUpload, setFileCountUpload] = useState(0);

 const salesStatus =[
   {value: 'open',label: 'Open'},
   {value: 'processing',label: 'Processing'},
   {value: 'delivery',label: 'For Pickup'},
   {value: 'intransit',label: 'In Transit'},
   {value: 'delivered',label: 'Delivered'},
   {value: 'completed',label: 'Completed'},
   {value: 'canceled',label: 'Canceled'},
 ]
 console.log( "store.currency: "+  store.currency);
//  const formatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: store.currency,
//   minimumFractionDigits: 2
// })
let formatter = new Intl.NumberFormat('en-IN', {
   
  minimumFractionDigits: 2
})

   
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [messageBoxFocus, setMessageBoxFocus] = useState(false);
    
  const handleMessageBox = () => {
    messageInput.current.focus();
  }

  const [salesOrder, isLoading] = useFirestoreDoc(
    firestore.collection("sales").doc(slid)
  );


  const [products, isLoadingProducts] = useFirestoreQuery (
    firestore.collection("products").where("storeId", "==", store._id)
  );
  
 const onErrorImg = (event:  any ) =>{ 
    event.target.src= require('../images/error_image.png');
 }

 const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
 
 const postImageUrl = (mainStoreId: string, imageName: string, productId: string) => {

  let completeName =require('../images/error_image.png');
  try{ 
      const lastDot = imageName.lastIndexOf('.');
      const fileName = imageName.substring(0, lastDot);
      const ext = imageName.substring(lastDot + 1);  
      const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
      completeName= `${imageUrl}${mainStoreId}%2F${productId}%2F${newName}?alt=media`;
     // const completeName = `images/stores/${store._id}/${productId}/thumbnails/${fileName}_400x400.${ext}`;
     // console.log("imageURL: " + completeName);
  }catch{
      console.log("no image: " + productId);
  }
  return completeName;

} 
 

const useProductImageUrl = (mainStoreId: string, imageName: string, productId: string) =>{
  var product = products.find( e => e._id === productId);
  var pName = imageName;
  if(product){
    if(product.urlFiles && product.urlFiles.length > 0 ){
      pName = product.urlFiles[0].fileName;
    }
  }
  return postImageUrl(mainStoreId, pName, productId);
}

  const computeTotalAmount = () =>{
    var _totalAmount = 0;
    salesOrder.items.forEach( (item) =>{ 
      _totalAmount = _totalAmount + (item.productPrice* item.count)

    } );
    setTotalAmount(_totalAmount);
 
  }
  
  useEffect(()=>{

    if(salesOrder){
      setNotes(salesOrder.notes||"");
      setStatus(salesOrder.status||"");
      
    
      if(salesOrder.schedule){
        var _today = new Date(salesOrder.schedule);
        console.log("today: " + _today.toLocaleString());
        console.log("today: " + _today.getTime());
        setSchedule(_today);   
        setScheduleTemp(_today);
        setSchedule2( _today.toLocaleString().replace(":00",""));
      }else{ 
        // var _today = new Date(utcTime());
        // setSchedule(_today);
        // setSchedule2(_today.toLocaleString());
        setScheduleTemp(null);
        setSchedule(null);
        setSchedule2("Schedule");
      }
      computeTotalAmount();
      firestore.collection("orders").doc(salesOrder.orderId).get()
      .then((doc)=>{
        if(doc.exists){
          var data = doc.data();
          setOrder({_id: doc.id, ...data})
          
        }
      })
      setNegTotalAmount(salesOrder.negTotalAmount ? salesOrder.negTotalAmount :  totalAmount);
    }
  
  },[salesOrder])



  const getTotal =(qty, price, isPm) =>{
    if(isPm){
      return "pm s 🔑";
    }else{ 
      var amount = 0;
      try{ 
        const _qty = qty || 0;
        const _price = price || 0;
        amount = (qty*price);
     
      }catch(err) {
          console.log("err:" + err);
       };

      return amount.toFixed(2);
 
    }
  }


  return (
    
  <>
  
      <Dialog
        fullScreen={matchesXS}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openSalesOrderDialog}
        onClose={checkClosingDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        className={classes.postDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
          
        <DialogTitle id="max-width-dialog-title"> 
          {store.name} - Sales Order
          {salesOrder && 
                <Card >
                    <CardHeader
                        avatar={
                              <Avatar alt="username" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${salesOrder.orderUserId}?alt=media`}  className={classes.avatar} />    
                        }
                        action={
                          <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                              <Grid item>
                                <Typography  component="span"
                                  variant="subtitle2"
                                  display="block"
                                  color="primary"
                                  noWrap >
                                  SN: {salesOrder.transNumber}   
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton aria-label="close"   onClick={checkClosingDialog}>
                                  <CloseIcon color="primary"/>
                                </IconButton>
                              </Grid>
                              </Grid>
                          }
                        
                        title={`${salesOrder.orderUsername} `}
                        subheader={ new Date(salesOrder.created).toLocaleDateString()}
                    />
                </Card>
            }
        </DialogTitle>
        <DialogContent>
             { (isLoading || isLoadingProducts) && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
              
              { salesOrder && 
                <div>
                  <GridList cellHeight={300} className={classes.gridList} cols={2}>
                  { salesOrder.items.map( (item) =>( 
          
                        <GridListTile key={item.productId} cols={1}  style={{ width: '200px' }}>
                          
                            <img  className={classes.imageUi} src={useProductImageUrl(salesOrder.storeId,item.productImage,item.productId)} alt={item.productName} 
                            onError={onErrorImg}/>

                          <GridListTileBar
                            title={item.productName}
                            subtitle={<span>
                              {store.currency ==='PHP' ? <>&#8369;</>  : '$'}
                              {  formatter.format(item.productPrice) } Qty: {item.count}                              
                              </span>}
                            actionIcon={
                              <IconButton aria-label={`info about ${item.productName}`} className={classes.icon}>
                                <InfoIcon />
                              </IconButton>
                            }
                          />

                        </GridListTile>
                      
                    ))}
                  </GridList> 

                  <Grid  container  alignItems="center" spacing={1}>
                    <Grid item key="j1" xs={12}>
                      <TextField
                        id="description-textarea"
                        label= "Notes"
                        placeholder="Notes"
                        multiline
                        variant="outlined"
                        fullWidth
                        value = {notes||""}
                        color="primary"
                        rowsMax={2}
                        size="small"
                        inputRef = {messageInput}
                        onChange={handleNotes}
                        className ={classes.textLocationField}
                      />
                    </Grid>
              
                    <Grid item key="j2"  xs={6}>
                      <TextField
                        id="status-selector"
                        select
                        label= "Status"
                        placeholder="Set Sales Order Status"
                        variant="outlined"
                        fullWidth
                        value = {status}                  
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                        className ={classes.textLocationField}
                      >
                        {salesStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    {store.services && 
                      <Grid item key="j21"  xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Button fullWidth 
                            
                            variant="contained"
                            onClick={ (e) => {handleSchedulePage(e)} } 
                            startIcon={<ScheduleIcon />}
                            className={classes.buttonMenu}
                            style={{ backgroundColor: schedule < new Date().getTime() ? '#FF0000' : '#33A3FF', 
                                      color: schedule < new Date().getTime() ? '#FFFFFF' : '#000000' }}
                            
                            >
                              {schedule2}
                              
                            </Button>  
                          { schedule &&
                          <Menu
                              id="simple-menu-schedule"
                              anchorEl={anchorElSchedule}
                              keepMounted
                              open={Boolean(anchorElSchedule)}
                              onClose={handleScheduleClosePage}
        
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                          >
                            <MenuItem key="datetime"  >
                              <Grid container direction="column" spacing={1}>
                                <Grid item key="j211"  >
                                      <KeyboardDatePicker                                          
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            name="scheduleDate" 
                                            id="scheduleDate"
                                            label="Schedule Date"
                                            value={schedule}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                            inputProps={{
                                              min: 0,
                                              max: 100,
                                              step: 2,
                                              onKeyDown: (event) => {
                                                 event.preventDefault();
                                              },
                                            }}
                                          />
                                  </Grid>
                                  <Grid item key="j22"  >

                                      <KeyboardTimePicker                                              
                                              margin="normal"
                                              id="scheduleTime"
                                              label="Schedule Time"
                                              value={schedule}
                                              onChange={handleDateChange}
                                              KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                              }}
                                              inputProps={{
                                                min: 0,
                                                max: 100,
                                                step: 2,
                                                onKeyDown: (event) => {
                                                   event.preventDefault();
                                                },
                                              }}
                                          />
                                
                                  </Grid>
                                  <Grid item key="j23"  >
                                    <Grid container direction="row"  justify="flex-end" spacing={2}>
                                        <Grid  item xs={6} >
                                          <Button  
                                              fullWidth                                                              
                                              variant="contained"                                      
                                              startIcon={<CancelIcon />}
                                              className={classes.buttonSchedule}
                                              onClick={ (e) => {handleScheduleCancel(e)} } 
                                              style={{ backgroundColor: '#33A3FF', 
                                                        color:  '#FFFFFF' }}                                      
                                              >
                                              Cancel
                                          </Button>  
                                        </Grid>
                                        <Grid item xs={6} >
                                          <Button      
                                          fullWidth                                              
                                              variant="contained"                                      
                                              startIcon={<CheckCircleIcon />}
                                              onClick={ (e) => {handleScheduleOk(e)} } 
                                              className={classes.buttonSchedule}
                                              style={{ backgroundColor: '#33A3FF', 
                                                color:  '#FFFFFF'}}                                      
                                              >
                                              Ok
                                          </Button>  
                                        </Grid>
                                      </Grid>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            </Menu>
                          }
                              </MuiPickersUtilsProvider>
                      </Grid>
                    }
                    <Grid item key="j3"  xs={12}> 
                        {store.showPrice ?
                        <Typography variant="caption" color="primary">  "Prices is shown in your Store"</Typography>
                          : <Typography variant="caption" color="secondary"> "Your Prices are not shown in your shop, The prices could be different what is shown below." 
                        </Typography>}  
                    </Grid>

                    <Grid item key="j4"  xs={12}>
                      <TableContainer component={Paper}>
                          <Table   aria-label="item table">
                            <TableHead>
                              <TableRow>
                              <TableCell> </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Qty.</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Total</TableCell>     
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {salesOrder.items.map((item) => (
                                <TableRow key={item.productId}>
                                  <TableCell padding="checkbox">
                                      <IconButton>
                                        <DeleteForeverIcon onClick={(event)=>{  deleteMe(event, item)}} />                                    
                                      </IconButton>
                                  </TableCell>  
                                  <TableCell component="th" scope="row">
                                    {item.productName}
                                  </TableCell>
                                  <TableCell align="right">{item.count}</TableCell>
                                  <TableCell align="right">{ Number.parseFloat(item.productPrice).toFixed(2) }</TableCell>
                                  <TableCell align="right">{ getTotal(item.count, item.productPrice,  false ) }</TableCell>
                                  
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item key="j5"  xs={12}>
                       <Typography variant="body2" color="primary">                          
                         Total Amount: { store.currency ==='PHP' ? <>&#8369;</>  : '$' }{ formatter.format(totalAmount)}                          
                       </Typography>
                    </Grid>
                    <Grid   container direction="row" justify="flex-end" alignItems="center" className={classes.negTA1} >

                        <Grid item xs={1} >
                        <Button  variant="outlined" color="primary" className={classes.button}  onClick={gotoTokTok} fullWidth>
                               
                               <LocalShippingIcon color="secondary" /> &nbsp; Schedule Shipping
                          </Button>
                        </Grid>
                        <Grid item xs  className={classes.negTA1}  >
                         <Typography variant="body2" color="primary" align="right"> 
                         {negTotalAmount < totalAmount?"Discounted Total Amount" :"Total Amount"}  { store.currency ==='PHP' ? <>&#8369;</>  : '$' }  </Typography>

                        </Grid>
                        <Grid item xs={3}    >
                         

                        <CurrencyTextField
                                id="negTotalAmount-textarea"
                                 
                                variant="outlined"
                                color="primary"
                                size="small"                             
                                value={negTotalAmount}
                                onChange={(event, value)=>  setNegTotalAmount(value)}                         
                                name="numberformat"                               
                                decimalCharacter="."
                                digitGroupSeparator=","
                                currencySymbol=""
                              />
                        </Grid>
                      
                    </Grid>
                  </Grid>
                </div> 
              }        
        </DialogContent>
        <DialogActions>
 
              <Grid container direction="row" justify="flex-end" alignItems="center" >     
                      <Grid xs={12} md={6} sm={6} item container direction="row">
                   {/**
                          <Grid item  key="j6" xs={3} sm={2} md={2}>
                          
                           
                              <Tooltip title="Upload images, limit 2mb size.">
                                <IconButton aria-label="upload video/image" component="span">
                                <PhotoLibraryIcon color='primary'/>
                                </IconButton> 
                                </Tooltip>
                             
                          </Grid>
                          */}  
                          <Grid item key="j7"  xs={3} sm={2} md={2}>
                            <Tooltip title="You must enter the name and the description.">
                                  <IconButton aria-label="message text" onClick={handleMessageBox} > 
                                    <MessageIcon  color='primary'/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>                      
                      </Grid>  
                      <Grid item  key="j8" xs={12} md={6} sm={6} container direction="row" justify="center"  alignItems="center">                          
                          <Button  variant="outlined" color="primary" className={classes.button}  onClick={updateSalesOrder} fullWidth>
                              {isSending && <> <CircularProgress/> Submit Changes </> }
                              {!isSending && <> <PublishIcon/> Submit Changes </>}
                          </Button>
                      </Grid>
              </Grid>
        </DialogActions>
        {order &&
          <Button  fullWidth color="primary" 
              className={classes.button} 
              aria-label="show more"
              onClick={handleExpandClick}
              endIcon={ <Icon className={classes.iconUi}>
                <ExpandMoreIcon  color="primary" className={clsx(  {
                  [classes.expandOpen]: expanded,
                })}/>
              </Icon>}
          > 
              {order.countComment>0?order.countComment:""} Comments              
          </Button>
        }
          
        { (salesOrder && order) && 
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          
            <Grid container>   
                <CommentEntrySnO  storeId={salesOrder.storeId} orderId ={salesOrder.orderId}/> 
            </Grid>

              <Grid container>
                  {expanded &&
                  <CommentsSnO   store={store} orderId ={salesOrder.orderId}  />    
                }  
              </Grid>

            {order.countComment > 3   ?
                <Button  fullWidth color="primary" 
                className={classes.button} 
                aria-label="show more"
                onClick={handleExpandClick}
                endIcon={ 
                  <Icon className={classes.iconUi}>
                    <ExpandMoreIcon  color="primary" className={clsx(  {
                      [classes.expandOpen]: expanded,
                    })}/>
                  </Icon>}
               > 
                {order.countComment>0?order.countComment:""} Comments               
            </Button>
            :
            <></>
            }
 
          </Collapse>
        }
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} >
            
                <Alert  onClose={handleCloseSnack} severity="success">
                    <AlertTitle> Update send   </AlertTitle>
                    {snackMessage}
                </Alert>
                
          
        </Snackbar>
      </Dialog>
     
     
      </>
  
  );
}

 
export default SalesOrder;

//The Bootstrap grid system has four classes: 
//xs (phones),
// sm (tablets), 
//md (desktops), and 
//lg (larger desktops). 
//The classes can be combined to create more dynamic and flexible layouts.