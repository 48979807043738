//import withRoot from "../utils/withRoot";
import React, {useState, useEffect} from "react";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";
import PropTypes from "prop-types";
import SEO from "./SEO";
import Card from "./Card";
 
import Button from "@material-ui/core/Button";
import queryString from 'query-string';

import Avatar from "@material-ui/core/Avatar";
import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { makeStyles, Theme, createStyles, CircularProgress, Container, Grid, CardMedia, CardContent } from "@material-ui/core";
import {auth, useAuth,  firestore , firebase, useFirestoreDoc, useFirestoreQuery}  from 'gatsby-theme-firebase';
import CarouselPost from "./CarouselPost";
import green from "@material-ui/core/colors/green";
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: "bold",
      textTransform: "none"

    },
 
    contentBox: {
      maxWidth: "calc(1136px - 60px)",
      width: "calc(100% - 60px)",
 
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      marginLeft: '50%',
      height: '350px',
    },
    headerSpacer3: {
      flex: 1,
      display: 'fixed',
      marginTop: '35%',
 
    },
    containerUi: {
    
      marginLeft: -15,

    },
    adds:{
      width: 750,
      height: 107,
      borderColor: "white",
      border: 0,
    }
  }),
);
 


const  Welcome = ( props , { storeId, prt, prd, fid}) => {

  const classes = useStyles();
  var [postsData, setPostsData] = useState<firebase.firestore.DocumentData[]>([]);
 
  var [isLoading,setIsLoading] = useState(false);
  var [error, setError] = React.useState<Error | null>(null);
   
 
 var [product, setProduct] = useState(null);
 var [title,setTitle] = useState(prt);
 var [description, setDescription] = useState(prd);
 var [url, setUrl] = useState(`${process.env.GATSBY_HOST}?store=${storeId}&fid=${fid}`);
 var [image, setImage] = useState("https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fminiens.png?alt=media&token=5b17906e-f077-4a04-844f-32717058c98d");

  const useFirestoreQuery3 = <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    
    const unsubscribe = query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
        setIsLoading(false);
        setPostsData(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
      
      },
      (err: Error) => {
        console.log("useFirestoreQuery3: " + err);
        console.error(err);
        setError(err);
      },()=>{
      
      }
    );  
    return () => unsubscribe();
  }

  //disable for now only display miniens product
  // useEffect(() => {
  //   console.log("wheretext and whervalue change");
  //      setIsLoading(true);
  //      useFirestoreQuery3(firestore.collection('products').where( "status","in", ["new","available"]).orderBy("countLike","desc").limit(+process.env.GATSBY_LIMIT_TOPS) );
       
  // },[]);
 
  useEffect(() => {
    console.log("wheretext and whervalue change");
       setIsLoading(true);
       useFirestoreQuery3(firestore.collection('products')
          .where("storeId","==",process.env.GATSBY_DEFUALT_STORE)
          .where( "status","in", ["new","available"])
          .orderBy("indexOrder").limit(+process.env.GATSBY_LIMIT_TOPS) );


      // if( props && props.location && props.location.search  ){ 
      //       const params = queryString.parse( props.location.search);
      //       let _storeId = process.env.GATSBY_DEFUALT_STORE;
      //       if(params.store && params.store !== 'create' && params.fid  && params.prt && params.prd){
                            
      //         // firestore.collection("products").doc(params.fid+'').get().then(
      //         //   (doc) =>{
      //         //     if(doc.exists){
      //         //        var data = { _id: doc.id, ...doc.data()};
      //         //        console.log("wheretext and whervalue change junald junald: " );
      //         //        setProduct(data);
      //         //        setTitle(doc.data().productName);
      //         //        setDescription(doc.data().description);
      //         //        setUrl(`${process.env.GATSBY_HOST}?store=${params.store+''}&fid=${data._id}`);
      //         //        setImage("https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fstores%2Fstr-ABoolHgxSnW0AtwaG2wiObdCdmv2%2FddWypHvsrWt5kzIVsTe8%2Fcow.png?alt=media&token=6f83f537-0feb-4a46-b262-82c721baf03a");
      //         //     }
      //         //   }
      //         // )

      //         setTitle(params.prt+'');
      //         setDescription(params.prd+'');
      //         setUrl(`${process.env.GATSBY_HOST}?store=${params.store+''}&fid=${params.fid}`);
      //         setImage("https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fstores%2Fstr-ABoolHgxSnW0AtwaG2wiObdCdmv2%2FddWypHvsrWt5kzIVsTe8%2Fcow.png?alt=media&token=6f83f537-0feb-4a46-b262-82c721baf03a");
      //       }
      // }
       
  },[]);



   
  const data = useStaticQuery(graphql`
  query{
      site{
          siteMetadata{
              title
              image
              url
              description
              contact {
                email
                phone
              }
          }
      }
  }
`)
 
  return (
   <Container>
 
  <SEO title={title} description={description} 
            url={url}
            image={image}
            >
            
        <meta
          name="description"
          content={description} 
        />
        
        
           
   </SEO> 

      <Container className={classes.containerUi}>
            
      <Grid container spacing={2}  direction="row" justify="center"  alignItems="center">
              <Grid item>
              {process.env.GATSBY_ADDS_ENABLE === 'true' &&
                      <CardMedia className={classes.adds}  component='iframe' title="Ads" 
                      src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds728x90.html?alt=media&token=c04a5eb6-5904-45dd-bbe8-aa48455cf58c`}></CardMedia>
                       }
              </Grid>
        </Grid>
    
      <Card
        title="Latest and Treding Products."
        avatar={
          <NewReleasesIcon color="primary"/>
          /**
          <Avatar style={{ backgroundColor: green[500] }} >
            <NewReleasesIcon color="primary"/>
          </Avatar>
           */
        }
        action={
          <Button
            variant="outlined"
            color="secondary"
            className={classes.root}
            component={Link}
            to= { `/?fid=post&where=postType`} 
          >
            View All Post
          </Button>
        }
       
        className={ classes.contentBox}
      >
        <CardContent>
        {isLoading  ? <div className={classes.headerSpacer2} >  
        <CircularProgress className={classes.headerSpacer3} color="primary" />   </div> 
        :
        postsData && postsData.length > 0 &&<CarouselPost posts={postsData} /> } 
       </CardContent>
      </Card>
     </Container>
     </Container>
  );
  };

 
export default Welcome;
 
 