import React, {useState}  from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from './ProTip';
import Divider from '@material-ui/core/Divider';
import { CardMedia, Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {utcTime, sortByProperty, Copyright} from '../utils/MantawiTime'; 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        height: "650px",
       
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    headerSpacer: {
      flex: 1,
      display: 'fixed',
      marginTop: '5%',
 
    },
    
  }),
);
 
const helpImageUrl =  () => {
 
  const pdf = require(`../images/docs/mantawi.pdf`);
  return pdf;
}

const errorBannerUrl = require(`../images/Miniens1.png`);
const onErrorImg = (event:  any) =>{ 
  event.target.src= require(`../images/Miniens1.png`);
}
//const errorBannerUrl = require(`../images/Miniens1.png`);
export default function HelpPage() {

  const classes = useStyles();

  const [helpPdf, setHelpPdf] = useState(helpImageUrl());
  const bannerUrl =  `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${process.env.GATSBY_DEFUALT_STORE}%2F${process.env.GATSBY_DEFUALT_STORE}?alt=media`;
  return (
      
       <Container>
         <Grid container  direction="column" > <Grid item>
                  <img
                      src={bannerUrl}
                      alt="Store Banner"
                      height="200"
                      width="100%"
                      onError={onErrorImg}
                  />
                </Grid>
        </Grid>
       <Grid container direction="row" justify="center" alignItems="center">      
          <Grid item>    
              <Typography variant="h5">-- HOW TO --</Typography> 
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              <Typography variant="h5">Buyer's Guide :</Typography> 
              <Typography variant="h6">Registration and Sign in</Typography>
              <img  src={require(`../images/help/reg1.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />

              <p>1. Click the Sign In/Register at the right top menu</p>
              <p>2. A dialog showing the options to login or register</p>
              <p>3. You can choose any social login option, facebook, gmail, twitter</p>
              <p>4. If you don't have any social login, you can choose the email option to register</p>
          </Grid>         
        </Grid>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Account / Profile Settings. </Typography> 
              <img  src={require(`../images/help/account.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />

              <p>1. On left side the panel, Click "Account Settings"</p>
              <p>2. Enter your information</p>
              <p>3. Setup your location, To accurately locate your location, refer to the document below</p>
              <p>4. YOU are given a free one year store subcription.</p>
              <p>5. To create your own online store, follow the Seller Guide.</p>
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Finding a Store </Typography> 
              <img  src={require(`../images/help/storesearch1.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />
              <img  src={require(`../images/help/storesearch2.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />

              <p>1. You can filter and find mini online store, close to your location.</p>
              <p>2. On Top menu, Click "Store's"</p>
              <p>3. Click the "Search Online Shop"</p>
              <p>4. Enter your closes location, example: Country, Province, City </p>
              <p>5. A List of store will show up.</p>
              <p>6. If no store show's up, limit your search text.</p>
              <p>7. To limit your search, Just enter a Country, or Country and province.</p>
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Tagging Products</Typography> 
              <img  src={require(`../images/help/product2.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />

              <p>1. You can tag a product and keep track on it.</p>
              <p>2. To tag a product, Click a Tag icon on the product, located on the Right side, beside the Cart and Plus icon.</p>
              <p>3. Your tag products will appear on the right panel and "Tag Products"</p>
              <p>4. On the tag product list, A Ringing bill means an item has been update.</p>
              <p>5. A List of store will show up.</p>
              <Typography variant="h6">UnTagging Products</Typography> 
              <p>6. Just click the tag icon again to untag the products.</p>
              
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Draft Order</Typography> 
              <img  src={require(`../images/help/draft1.png`)}
                  alt="Uploaded Images" height="50%"  width="50%"  onError={onErrorImg}  />

              <p>1. If you see items you like you, just click the plus icon and it will be added to your cart.</p>
              <p>2. The Green cart icon will be updated the number of items you order. </p>
              <p>3. Once the green cart has an Item, You just created a "Draft Order"</p>
              <p>4. Your draft order will appear on the left side panel "My Orders" with status draft.</p>
              <p>5. On the main page, You can click the Green cart with items.</p>
              <p>6. Draft Order dialog will show up.</p>
              <p>7. You can add comments on this dialog and start to negotiate with the seller.</p>
              <p>8. Add notes when you confirm this order.</p>

              <Typography variant="h6">My Orders Panel</Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/myorderpanel.png`)} />
              <p>1. My orders panel, containts the list of orders you have made.</p>
              <p>2. You can filter the list by Processing or Completed</p>
              <p>3. Processing orders are orders with statuses 'ordered','open','draft','processing','delivery','intransit' and 'delivered'</p>
              <p>4. Completed orders ard orders with statuses 'completed' or 'canceled'</p>

          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              <Typography variant="h5">Services Guide:</Typography> 
              <Typography variant="h6">To schedule a services. </Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/schedule1.png`)} />
              <p>1. This mini online store and has a scheduling services feature. </p>
              <p>2. Look for a store that provide services.</p>
              <p>3. To schedule a services. Same as you do on the orders, Just add item's on your cart.</p>
              <p>4. Just click the Green Cart button with items.</p>
              <p>5. If the store you order from has offered a services, a schedule text field with will show on the "Draft Order Dialog", with "No Schedule Set" value.</p>
              <p>6. You can asked in the comments to schedule you on certain date. </p>
              <p>7. If the seller or the service provider is online you can chat together and asked for a schedule.</p>
              <p>8. If you have agreed on a date and time for your services, the seller will set the schedule.</p>
              <p>9. Then you can confirm your orders, so that the seller or service provider, can prepare your orders.</p>
          </Grid>         
        </Grid>

        <Divider/>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              <Typography variant="h5">Seller Guide : </Typography> 
              <Typography variant="h6">Creating your own mini online store.</Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/store1.png`)} />
              <p>1. Once you have registered and login. </p>
              <p>2. On the left panel, click "Create Your Own Store and Start Selling."</p>
              <p>3. You will be shown the store settings.</p>
              <p>4. Enter your store name and your contact information.</p>
              <p>5. Your store information will be shown on your online store page.</p>
              <p>6. Setup your Geo location properly. You buyer can easely locate your physical store using google maps. </p>
              <p> Goto Geo Location Help.</p>
              <p>7. You can add 25 maximum items on your product catalog.</p>
              <p>8. Show Prices, if checked, product prices will be shown on your website and order dialogs.</p>
              <p>9. Business Type, if checked, you can add a product that is services in nature. A schedule text field will appear on your Order dialog.</p>
              <p>10. Submit your changes. Scroll down if you don't see the submit button.</p>
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Product Catalog: Adding Products </Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/product1.png`)} />
              <p>1. Once you have setup your store and login. </p>
              <p>2. On the left panel, click "Product Catalog" to expand.</p>
              <p>3. Click the plus icon to Add Products or Services</p>
              <p>4. A Product dialog whill show up.</p>
              <p>5. Drog and drop , click the cloud icon or click the image icon below(first icon) to add images.</p>
              <p>6. You are allowed to have at least 6 images with 250 kilobytes size each </p>
              <p>7. Enter the name and description of the product</p>
              <p>8. The description will appear on your mini online store.</p>
              <p>9. Describe your product type, this could be items, services, consultation.</p>
              <p>10. Choose the status of your. It could be new, cancelled, available, pre-order or out of stock.</p>
              <p>11. Don't forget to publish your new item.</p>
              <p>12. You will not immediately see your item images, just refresh page.</p>
              <p>Notes: Carefully name your product, once you rename your product, old transaction will still be using the old product name.</p>
              <Typography variant="h6">Product Catalog: Editting Products</Typography> 
              <img height="25%"  width="25%"  onError={onErrorImg}  src={require(`../images/help/productentry1.png`)} />
              <img height="25%"  width="25%"  onError={onErrorImg}  src={require(`../images/help/productpanel.png`)} />
              <p>1. Just click any item you, want to edit from Product Catalog </p>
              <p>2. Make any changes and don't forget to publish your changes.</p>
              
          </Grid>         
        </Grid>

    

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Sales and Services Order: </Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/schedule1.png`)} />
              <p>1. Once you have marketed your store, you will be receiving orders</p>
              <p>2. On left panel, click Sales and Services Order</p>
              <p>3. You will see a list of sales orders or services. Filtered by "Processing"</p>
              <p>4. Orders with "Processing" filter, Are Open sales, It could be on delivery, any status that the orders is still alive. </p>
              <p>5. Sales with "Completed" filter, Are Orders that is completed or cancelled.</p>
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Sales and Services Order: Fulfilling Sales Order</Typography>             
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/sales1.png`)} />
              <p>1. On left panel, click Sales and Services Order</p>
              <p>3. You will see a list of sales orders or services. Filtered by "Processing"</p>
              <p>4. Click any sales orders</p>
              <p>5. Sales Order dialog will appeared, It looks similar with the Order dialog.</p>
              <p>6. You can update the status of the sales orders.</p>
              <p>7. You can Reschedule services, that is already due, but not fulfilled.</p>
              <p>8. To change the schedule, just click schedule button with a clock icon.</p>
              <p>8. You can see the price you have setup on the product cataglog.</p>
              <p>9. Or Enter the exact amount you have negotaited with your customer.</p>
              <p>10. Enter the shippinng cost, if it's inlcuded in your orders.</p>
              <p>11. And Chat with your customer to succesfully complete the sales.</p>
          </Grid>         
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              
              <Typography variant="h6">Sales and Services: Draft Order</Typography> 
              <p>1. Click Draft Order panel, on the left side.</p>
              <p>2. You will see all draft orders (draft orders are Orders not yet confirmed by the customer. ) </p>
              <p>3. Click any draft order on the list.</p>
              <p>4. An Draft order dailog will show.</p>
              <p>5. You can send messages privately over the draft dialog.</p>
              <p>6. Same with the customer, The customer, can add comments and privately negotaite you with thier orders.</p>
              <p>7. You can update the final total amount that you charge on that order. </p>
              <p>8. It's very usefull if the customer are asking for discount. </p>

               <Typography variant="h6">Sales and Services: Draft Order with Services </Typography> 
               <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/draft1sales.png`)} />
              <p>1. Click Draft Order panel, on the left side.</p>
              <p>2. You will see all draft orders (draft orders are Orders not yet confirmed by the customer. ) </p>
              <p>3. Click any draft order on the list.</p>
              <p>4. An Draft order dailog will show.</p>
              <p>5. You can send messages privately over the draft dialog.</p>
              <p>6. Same with the customer, The customer, can add comments and privately negotaite you with thier draft orders.</p>
              <p>7. If you are business is on services. The customer could asked you for the schedule.</p>
              <p>8. With your back and fort communications using the draft order chat box.</p>
              <p>9. You can set the schedule, When are your going to render your services.</p>
              <p>10. Once done you can asked your customer to confirmed the order.</p>
              
          </Grid>         
        </Grid>

        <Divider/>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.headerSpacer}>      
          <Grid item>    
              <Typography variant="h5">Location Setting :</Typography> 
              <Typography variant="h6">To accurately pinpoint your store location. </Typography> 
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/map1.png`)} />
              <img height="50%"  width="50%"  onError={onErrorImg}  src={require(`../images/help/map2.png`)} />
              <p>
              1. Goto your store setting.</p><p>             
              2. Enter your correct address. </p><p> 
              3. Under Geolocation field, click Search Icon</p><p> 
              4.  Enter your closes location, example: Country, Province, City,</p><p> 
              5.  A list of location will show under the textfield.</p><p> 
              6.  Select the closes location in the list.</p><p> 
              7. A red dot appears on the map.</p><p> 
              8. Zoom in at the red dot to locate your exact location.</p><p> 
              9. Click the location on the map. a red dot appears.</p><p> 
              10. Click the “select location” on green dialog below or click the arrow sign icon on top.</p><p> 
              11.  Do not forget to submit the changes.</p><p> 
              12.  Click home on your top Menu.</p><p> 
              13.  In your store page. click a reverse drop icon or location icon</p><p> 
              14.  A new tab will open with google maps tagging your exact location.</p>

             
          </Grid>         
        </Grid>
 
        <Grid container direction="row" justify="center" alignItems="center">        
          <Grid item >     
            <Typography variant="caption"></Typography> 
           </Grid>
        </Grid>

        <Copyright/>
      </Container>
       
     
  );
}
