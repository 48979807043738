import React, { useState, useEffect, useContext } from 'react';
 
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {UserProfile} from '../utils/mantawiObject';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
import {Snackbar,  IconButton, Grid, Paper, LinearProgress, MenuItem, Typography, Box} from '@material-ui/core';
import { Link  } from 'gatsby-theme-material-ui';
import { navigate } from "gatsby"
import SearchIcon from '@material-ui/icons/Search';
import MyMapBox from './myMapBox';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { utcTime } from '../utils/MantawiTime';
import {nanoid} from 'nanoid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootEntry: {
      display: 'flex',
      flexGrow: 1,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    prgBar: {
      width: '100%',
      height: '10px'
    },
    paper: {
      backgroundColor: 'black',
       
    },
    expandButton: {
      
      minWidth: '100px'
    },
    snackTop:{
      marginTop: 50,
    },
    contactUi:{
      width: '90%',
      height: '100vh'
    },
    loadingUi:{
      width: "100%",        
    },
    centerImage:{
      display: 'block',
      alignItems: 'center',
      alignContent: 'center'
    },
    marginAutoItem: {
      margin: 'auto'
    },
  }),
);

let storage = undefined;
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())
const errorBannerUrl = require(`../images/Miniens1.png`);

const onErrorImg = (event:  any) =>{ 
  event.target.src= require(`../images/Miniens1.png`);
}

// const formatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 2
// })

const StoreSetting = ({setShowPage, storeId, userProfile}) => {
 
  const currencies =[
    {id:'en-US',label:'US Dollar',  style: 'currency',currency: 'USD',minimumFractionDigits:2},
    {id:'en-PH',label:'Philippine Peso', style: 'currency',currency: 'PHP',minimumFractionDigits:2},
    
  ]
 
    const classes = useStyles();
    const [bannerUrl, setBannerUrl] = useState(errorBannerUrl);
    const [progress, setProgress] = useState(0);
    const [showPrice, setShowPrice] = useState(false);
    const [showQuantity, setShowQuantity] = useState(false);
    const [services, setServices] = useState(false);
    const [store, setStore] = useState(null);
    const [isLoading, setisLoading] = useState( true );
    const [errorMsg, setError] = useState(null);
    const [image, setImage] = useState(null);
    const [displayName,setDisplayName] = useState("");
    const { isLoggedIn, profile }  = useAuth();
    const [displayLocation, setDisplayLocation] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [openMap, setOpenMap]= useState(false);
    const [open, setOpen] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [severity, setSeverity] = React.useState("warning");
    const [message, setMessage] = React.useState("");
    const [dateCreated, setDateCreated] = useState("");
    const [currentStoreId,setCurrentStoreId] = useState(storeId);
    const [storeKey, setStoreKey] = React.useState(null);
    
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const handleMapDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(true);
     
    }

    const handleMapCloseDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(false);
     
      handleLocation(sdLocation);
    }

    const handleLocation =(location)=>{
    
      if(  location !== undefined  && location !== null && location.name && location.name !== "" ){
        
        var _location = location.name.split(',');
        var pCountry = "";
        var pDistrict ="";
        var pCity = "";
        var pOther ="";

        if(_location.length>0){ 
          pCountry = _location.pop().trim();
        }
        if(_location.length>0){ 
          pDistrict = _location.pop().trim();
          if(pDistrict.indexOf('City') !== -1){
            pCity = pDistrict;
            pDistrict = "";
          }
        }
        if(_location.length>0 && pCity.length===0){ 
          pCity = _location.pop().trim();
        }
        if(_location.length>0){ 
          pOther = _location.pop().trim();
        }

          let _storeLocation =  {
            address: location.name,
            center:  location.center,
            city: pCity,
            country: pCountry,
            district: pDistrict,
            other: pOther
          }
         setDisplayLocation(_storeLocation.address);

         setStore(
          {...store,
            address: location.name,
            center: location.center,
            city: pCity,
            country: pCountry,
            district: pDistrict,
            other: pOther
          }
        );
      }
    }

    const handleCheckedShowPrice = () =>{
      var _status = !showPrice;
      setShowPrice(_status);
      setStore(
        {...store,
         showPrice: _status
        }
      );
    }

    const handleCheckedShowQuantity = () =>{
      var _status = !showQuantity;
      setShowQuantity(_status);
      setStore(
        {...store,
          showQuantity: _status
        }
      );
    }

    const handleCheckedServices = () =>{
      var _status = !services;
      setServices(_status);
      setStore(
        {...store,
          services: _status
        }
      );
    }
  
    useEffect(()=>{
     
      if(storage === undefined){ 
        storage = firebase.storage();
      }
 
      // it should not create the store when user click create online store
      if(profile){
        setisLoading(true);
        setDisplayName(profile.displayName);
        console.log("StoreSettings: " + currentStoreId);

        
        if(currentStoreId === 'create'){
            setStoreKey(null);
            // use name for keys
            // let key = `str-${profile.uid}`
           //let key = `str-${userProfile.name.replace(/[^A-Za-z0-9]/g, '')}-${nanoid(6)}`;
           let key =`str-${profile.uid}`

         //let key = `str-${userProfile.name.replace(/[^A-Za-z0-9]/g, '')}-${profile.uid}`;

            var today = utcTime();
            var data = {
              userId: profile.uid,
              key: key,
              link: key,
              userName: userProfile.name,
              name: `${userProfile.name}(Store)`,
              email: userProfile.email,
              contactNo: "",
              showPrice: false,
              showQuantity: false,
              services: false,
              currency: 'PHP',
              created: today,
              updated: today,
              maxItemCount: 25,
              address: userProfile.address || "",
              center: userProfile.center  || "",
              city: userProfile.city  || "",
              district: userProfile.district  || "",
              country: userProfile.country  || "",
              streetAddress: ""
            }

         
            // firestore.collection("stores").doc(key).set(data).then( 
            //     (doc)=>{
            //         setCurrentStoreId(key);
            //         var _started = new Date(today);
            //         var _ended = new Date( _started.getFullYear(), _started.getMonth() + 1, _started.getDate());
            //         var freeMembership = {
            //           userId: profile.uid,
            //           code: "P100A",
            //           description: "One Year Free Subscription",
            //           started: today,
            //           ended: _ended.getTime(),
            //           price: 24,
            //           status: 'Active',
            //           created: today,
            //           updated: today,
            //         }
            //         firestore.collection('membership').add(freeMembership).then(()=>{});
            //       return key;
            //     }
            // ).then( (_currentStoreId)=>{
            //   setStore(data);
            //   setBannerUrl(`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${_currentStoreId}%2F${_currentStoreId}?alt=media`);
            //   setShowPrice( data.showPrice);
            //   setShowQuantity(data.showQuantity);
            //   setServices(data.services);
            //   var _dateCreated = new Date(data.created);
            //   setDateCreated(_dateCreated.toLocaleDateString());
            //   setDisplayLocation(data.address && data.address);
            //   setStreetAddress(data.streetAddress && data.streetAddress);
            //   setisLoading(false);
            // });

            setStore(data);
            setBannerUrl(`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${key}%2F${key}?alt=media`);
            setShowPrice( data.showPrice);
            setShowQuantity(data.showQuantity);
            setServices(data.services);
            var _dateCreated = new Date(data.created);
            setDateCreated(_dateCreated.toLocaleDateString());
            setDisplayLocation(data.address && data.address);
            setStreetAddress(data.streetAddress && data.streetAddress);
            setisLoading(false);
             
        }else{
          firestore.collection("stores").doc(currentStoreId).get().then(
            function(doc){
              if (doc.exists) {
                var data = doc.data();
                setStore(data);
                setStoreKey(data.key);
                setBannerUrl(`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${currentStoreId}%2F${currentStoreId}?alt=media`);
                setShowPrice( data.showPrice);
                setShowQuantity(data.showQuantity);
                setServices(data.services);
                var _dateCreated = new Date(data.created);
                setDateCreated(_dateCreated.toLocaleDateString());
                setDisplayLocation(data.address && data.address);
                setStreetAddress(data.streetAddress && data.streetAddress);
              }  
            }
  
          ).then(()=>{
            setisLoading(false);
          }).catch( (error)=> {
            console.error(error);
          }); 
        }
   
      }else{
        setDisplayName('fetching...');
      }
      console.log("mount useefffect account");
   

    },[profile]);

    const saveStore =  () => {  
      if(storeKey === null){
          setisLoading(true);

          //let key = `str-${store.name.replace(/[^A-Za-z0-9]/g, '')}-${nanoid(6)}`;
          //let key = `str-${userProfile.name.replace(/[^A-Za-z0-9]/g, '')}-${profile.uid}`;
          //let key = `str-${userProfile.name.replace(/[^A-Za-z0-9]/g, '')}-${nanoid(6)}`;
          // if(store.name.trim().length > 0){            
          //   key = `str-${store.name.replace(/[^A-Za-z0-9]/g, '').toLowerCase()}-${nanoid(6)}`;
          //   //key = `str-${store.name.replace(/[^A-Za-z0-9]/g, '')}-${profile.uid}`;                        
          // }

          let key = `str-${profile.uid}`;
          let link= key;
          
          if(store.name.trim().length > 0){
            link= store.name.replace(/[^A-Za-z0-9]/g, '').toLowerCase();       
          }

          firestore.collection("stores").where("link", "==" ,link).get()
          .then(function(querySnapshot) {
            let continueSave = false;
            
            if(querySnapshot.empty){
              continueSave = true
            }
        
            return continueSave;
           }).then( (continueSave) =>{
             if(continueSave){
              store.key = key;
              store.link = link;
              setStoreKey(key);
              setCurrentStoreId(key);
              
              firestore.collection("stores").doc(key).set(store).then( 
                (doc)=>{
                  
                    var _started = new Date(today);
                    var _ended = new Date( _started.getFullYear(), _started.getMonth() + 1, _started.getDate());
                    var freeMembership = {
                      userId: profile.uid,
                      code: "P100A",
                      description: "One Year Free Subscription",
                      started: today,
                      ended: _ended.getTime(),
                      price: 24,
                      status: 'Active',
                      created: today,
                      updated: today,
                    }
                    firestore.collection('membership').add(freeMembership).then(()=>{});
                  return key;
                }
            ).then( (_currentStoreId)=>{
          
                    setisLoading(false);
                //   navigate(`/?store=${key}`);
                    navigate("/");
                });    
              
              }
           })
 

      }else{
        firestore.collection("stores").doc(currentStoreId)
          .update( store ).then(()=>{
          console.log("updated........");
          setSeverity("success");
          setMessage("Store detail updated.")
          setOpen(true);
        });
      }
    }  

    const handleInputChange = (event) =>{  
        const value = event.target.value;
        const name = event.target.name;     
        setStore(
          {...store,
          [name]: value}
        );
    }
 
    const spacing = 2;
    const handleUpload = (theImage) => {
     // const newName = `${profile.uid}.${theImage.name.split('.').pop()}` 
    
    const uploadTask = storage.ref(`images/stores/${currentStoreId}/${currentStoreId}`).put(theImage);
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progressCount = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
       
          setProgress(progressCount);
        },
        error => {
          // Error function ...
          console.error(error);
        },
        () => {
          // complete function ...
          storage
            .ref(`images/stores/${currentStoreId}`)
            .child(currentStoreId)
            .getDownloadURL()
            .then(url => {
              console.log("account profile: " + url);
              setBannerUrl(url);
            }).then( ()=>{
            });
        }
      );
    };

    const handleChange = (event)  => {
      if (event.target.files[0]) {
        const imageFiles = event.target.files[0];
        if (imageFiles.size > 1000000) // 100,000 for bytes.
        {
          setSeverity("warning");
          setMessage("Profile image size exceed 1000 kilobytes or 1mb.")
          setOpen(true);
        
        } else{

          setImage(imageFiles);
          handleUpload(imageFiles);
        }
 
      }
    };
  
    return (   

      <Grid  container  justify="center"  alignItems="center">
         {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
         {!isLoading &&
            <Container className={classes.contactUi} >
                <Card>
                
        
                    <CardContent>
                      <Grid container  spacing={2} >
                      <Grid item xs={12}>
                  {/**  <h2>
                    Store Information - {isLoggedIn && displayName}
                  </h2>  */}
                    </Grid>
                          <Grid key={'profilePicture'} item xs={12} >   
                            <Grid key={'profilePicture-image'} item >
                            <Box 
                                 
                            
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                <label htmlFor="icon-button-file">
                                  <img
                                    
                                    src={bannerUrl}
                                    alt="Uploaded Images"
                                    height="200"
                                    width="100%"
                                    onError={onErrorImg}
                                  />
                                </label>
                                <input
                                  color="primary"
                                  accept="image/*"
                                  type="file"
                                  onChange={handleChange}
                                  id="icon-button-file"
                                  style={{ display: 'none', }}
                                />   
                              </Box>
                            </Grid>
                            <Grid key={'profilePicture-progress'} item>
                                <LinearProgress className={classes.prgBar}  variant="determinate" value={progress}  />
                            </Grid>
                          </Grid>
      
                          <Grid key={'textfields'} item xs={12} sm container>
                            <Grid item xs container>
                              <Grid container   spacing={2}>
                                    <Grid item key={'textfields1'} xs={12}>
                                      <TextField   className={classes.rootEntry} id="name" name="name" label="Name" value={store.name}  onChange={handleInputChange}/>
                                    </Grid>
                                    <Grid item key={'textfields2'} xs={6}>
                                      <TextField   className={classes.rootEntry} id="contactNo" name="contactNo" label="Contact No" value={store.contactNo}  onChange={handleInputChange}/>
                                    </Grid>
                                    <Grid item key={'textfields3'} xs={6}>
                                      <TextField
                                        id="currency-selector"
                                        select
                                        label= "Currency"
                                        placeholder="Set Your Currency"
                                        variant="outlined"
                                        name="currency"
                                        fullWidth
                                        value = {store.currency}                  
                                        color="primary"
                                        size="small"
                                        onChange={handleInputChange}
                                        className ={classes.rootEntry}
                                      >
                                        {currencies.map((option) => (
                                            <MenuItem key={option.id} value={option.currency}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item key={'textfields4'} xs={6}>
                                      <TextField   className={classes.rootEntry} id="email" name="email" label="Email" value={store.email}  onChange={handleInputChange}/>
                                    </Grid>
                                     
                                    <Grid item key={'textfields41'} xs={6}>
                                      <TextField inputProps={{min: 0, style: { textAlign: 'right' }}} InputProps={{ readOnly: true, }} className={classes.rootEntry} id="maxItemCount" name="maxItemCount" label="Max Item Count" value={store.maxItemCount}  />
                                    </Grid>
                                    
                                    <Grid item key={'textfields5'} xs={11}>
                                        <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="location"  name="location" label="Global Map GeoLocation" value={displayLocation}   />
                                    </Grid>
                                    
                                    <Grid item key={'textfields6'} xs={1}>
                                        <IconButton  color="primary" aria-label="search location" onClick={() => handleMapDialog("posting","") } >
                                          <SearchIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item key={'textfields5a6'} xs={12}>
                                        <TextField  className={classes.rootEntry} id="streetAddress"  name="streetAddress" label="Complete Street Address" value={store.streetAddress}  onChange={handleInputChange}  />
                                    </Grid>
                                    <Grid item key={'textfields7'} xs={6}>
                                     <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={showPrice}
                                            onChange={handleCheckedShowPrice}
                                            name="checkedPrice"
                                            color="primary"
                                            value={store.showPrice}
                                          />
                                        }
                                        label="Show Prices In Your Store."
                                      />
                                    </Grid>
                                    <Grid item key={'textfields8'} xs={6}>
                                       <FormControlLabel
                                        control={
                                          <Checkbox
                                            disabled
                                            checked={showQuantity}
                                            onChange={handleCheckedShowQuantity}
                                            name="checkedQuantity"
                                            color="primary"
                                            value={store.showQuantity}
                                          />
                                        }
                                        label="Show Order Quantity."
                                      />
                                    </Grid>
                                    <Grid item key={'textfields81'} xs={12}>
                                      <Typography> Business Type </Typography>
                                       <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={services}
                                            onChange={handleCheckedServices}
                                            name="checkedServices"
                                            color="primary"
                                            value={store.services}
                                          />
                                        }
                                        label="Services (Maintenance,Repair,Cleaning,others)"
                                      />
                                    </Grid>
                                    
                                    <Grid item key={'textfields9'} xs={12}>
                                      <TextField   className={classes.rootEntry} id="description" name="description" label="Describe your online shop to attract lots of customers" value={store.description} multiline rows={3} onChange={handleInputChange}/>
                                    </Grid>
            
                                  </Grid>
                            </Grid>
                          </Grid>
                      </Grid>  
                    </CardContent>
                    <CardActions disableSpacing>
                        
                        <Grid container spacing={1} justify="flex-end">
                          <Grid item>
                        <Button className={classes.expandButton} variant="outlined" color="primary" onClick={saveStore }>
                            Submit
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button className={classes.expandButton} variant="outlined" color="primary" onClick={()=>{setShowPage(0)} }>
                            Cancel
                        </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                  <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapCloseDialog}  source="account"  />
                  <Snackbar className={classes.snackTop} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}   onClose={handleClose} >  
                    <Alert  onClose={handleClose}  severity={severity}>
                    <AlertTitle>{severity}</AlertTitle>
                    <strong>{message}</strong>
                    </Alert>
                  </Snackbar>
            </Container>
        }
       </Grid>
    )
}

export default StoreSetting;
