import React , {useState,useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Grid, Divider, AppBar, Toolbar, IconButton, Typography , Slide, Card, CardHeader, Avatar, CardContent, CardActions, Badge, Collapse, Container, Icon, TextareaAutosize, TextField, ListItemIcon, ListItemText, Snackbar, CardMedia, GridList, GridListTile, GridListTileBar, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import PostingImage from '../templates/PostingImage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import Menu, { MenuProps } from '@material-ui/core/Menu';
 
import MyMapBox from './myMapBox';
import {auth, useAuth,  firestore , firebase, useFirestoreDoc, useFirestoreQuery}  from 'gatsby-theme-firebase';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';

import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime} from '../utils/MantawiTime'; 
import { Alert, AlertTitle } from '@material-ui/lab';
import PeopleIcon from '@material-ui/icons/People';
// SHARING -- PRIVATE, FRIEND, PUBLIC
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// UPLOAD VIDEO/IMAGE
// TAG A FRIEND ???
// WHERE ARE YOU LOCATION
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PublishIcon from '@material-ui/icons/Publish';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
 
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import CommentsSnO from '../templates/CommentsSnO';
import CommentEntrySnO from '../templates/CommentEntrySnO';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDialog:{
        background: "rgba(0,0,0,0)",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    expand4: {
        transform: 'rotate(0deg)',
        marginLeft: '5%',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      border: 1,
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "none",
      minWidth: '200px',
    },
    input: {
      display: 'none',
    },
    textLocationField: {
      marginTop: '10px',
    },
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleTextUi:{
      marginTop: 20,
    },
    loadingUi:{
      minHeight: 50,
      width: "100%",        
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    imageUi:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 200,
      minWidth: 200
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    detailsUi:{
      border: '1px solid rgba(0, 0, 0, .125)',
    },
    iconUi:{
      width:'100%',
      marginTop: -6
    },
    table: {
      minWidth: 650,
    },
  
  }),
);
 

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

let storage = undefined;
//open,store, order, products, 
//handleCloseConfirmOrderDialog, handleConfirmOrderDialog
 //store purpose was just to defualt to store, same with userprofile
const OrderConfirmation = ( { open,store, order, products,handleProductOrderDelete,
    handleCloseConfirmOrderDialog, 
    handleConfirmOrderDialog  }) =>{
  const classes = useStyles();
  const messageInput = useRef(null);
  
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [widthLabel, setWidthLabel] = React.useState("md"); 
  
  const [isSending,setIsSending] = useState(false);
   
  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setFullWidth(event.target.checked);
     
    if(fullWidth){
        setWidthLabel("md");
    }else{
        setWidthLabel("sm");
    }
    setMaxWidth('md' as DialogProps['maxWidth']);
  };


  const [expanded, setExpanded] = React.useState(false);
 
  const [today, setToday] = useState(new Date().toLocaleDateString());
   
  const [progress,setProgress] = useState([]);
 
  const [openSnack,setOpenSnack] = useState(false);
  const [alertMessage,setAlertMessage] = useState("");
  const [status, setStatus ]=  useState("open");
  const [notes, setNotes ]=  useState("");
  const [schedule, setSchedule] = useState(order.soSchedule ? new Date(order.soSchedule).toLocaleString().replace(":00","") : "No Schedule Set" );
  const [details, setDetails ]=  useState(null);
  const [totalAmount, setTotalAmount ]=  useState(0);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleNotes= (event) =>{  
    
    setNotes(event.target.value);
  }

  const handleDetails = (event) =>{  
    
    setNotes(event.target.value);
  }
  
  const checkClosingDialog =()=>{
    if(!isSending){ 
      handleCloseConfirmOrderDialog();
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
   //junald
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const deleteMe = (event, pid) =>{
    console.log("deleteme: " + pid);
    handleProductOrderDelete(pid);
  }
  

 
  const createdHistory = (_source: string, _storeId: string, _orderId: string, _notes: string, _status: string) =>{
    try{
      const key = `${_orderId}${_storeId}`;
      var today = utcTime();
     console.log("history: " + key);
      firestore.collection("history").doc(key).get().then( (doc) =>{
        if(doc.exists){
          console.log("doc exits history: " );
            var history = doc.data();
            var details = [...history.details, {
              created: utcTime(),
              source: _source,
              status: _status,
              notes: _notes,
            }];
            history.updated = today;
            history.details = details;
            firestore.collection("history").doc(key).update(history).then(()=>{
              console.log("history updated");
            })
        }else{
       
     
          var _history = {
            historyId: key,
            created: today,
            updated: today,
            orderUserId: order.userId,
            storeUserId: order.storeUserId,
            details: [{  created: today,  source: _source,
              status: _status,  notes: _notes,  }]
          }
          firestore.collection("history").doc(key).set(_history).then(()=>{
            console.log("history created");
          })
        }
      })
    }catch(error){
      console.log("error: " + error);
    }
  }
  
 const [overSize, setOverSize] = useState([]);
 const [snackMessage, setSnackMessage] = useState("");
 const [fileCountUpload,setFileCountUpload] = useState(0);

 const salesStatus =[
   {value: 'open',label: 'Open'},
   {value: 'ordered',label: 'Ordered'},
   {value: 'processing',label: 'Processing'},
   {value: 'delivery',label: 'For Pickup'},
   {value: 'intransit',label: 'In Transit'},
   {value: 'delivered',label: 'Delivered'},
   {value: 'completed',label: 'Completed'},
   {value: 'canceled',label: 'Canceled'},
 ]

 const orderStatus =[
  {value: 'ordered',label: 'Ordered'},
  {value: 'processing',label: 'Processing'},
  {value: 'delivery',label: 'For Pickup'},
  {value: 'intransit',label: 'In Transit'},
  {value: 'delivered',label: 'Delivered'},
  {value: 'completed',label: 'Completed'},
  {value: 'canceled',label: 'Canceled'},
]

//  let formatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: store.currency,
//   minimumFractionDigits: 2
// })

let formatter = new Intl.NumberFormat('en-IN', {
   
  minimumFractionDigits: 2
})


 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [messageBoxFocus, setMessageBoxFocus] = useState(false);
    
  const handleMessageBox = () => {
    messageInput.current.focus();
  }
 
  const [isLoadingProducts, setIsLoadingProducts]= useState(false);
  
  
 const onErrorImg = (event:  any ) =>{ 
    event.target.src= require('../images/error_image.png');
 }

 const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
 
 const postImageUrl = (mainStoreId: string, imageName: string, productId: string) => {

  let completeName =require('../images/error_image.png');
  try{ 
      const lastDot = imageName.lastIndexOf('.');
      const fileName = imageName.substring(0, lastDot);
      const ext = imageName.substring(lastDot + 1);  
      const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
      completeName= `${imageUrl}${mainStoreId}%2F${productId}%2F${newName}?alt=media`;
     // const completeName = `images/stores/${store._id}/${productId}/thumbnails/${fileName}_400x400.${ext}`;
      console.log("imageURL: " + completeName);
  }catch{
      console.log("no image: " + productId);
  }
  return completeName;

} 
 
const useProductImageUrl = (mainStoreId: string, imageName: string, productId: string) =>{
  var product = products.find( e => e._id === productId);
  var pName = imageName;
  if(product){
    if(product.urlFiles && product.urlFiles.length > 0 ){
      pName = product.urlFiles[0].fileName;
    }
  }
  return postImageUrl(mainStoreId, pName, productId);
}

  const computeTotalAmount = () =>{
    var _totalAmount = 0;
    order.items.forEach( (item) =>{ 
      _totalAmount = _totalAmount + (item.productPrice* item.count)

    } );
    setTotalAmount(_totalAmount);
 
  }
   

  const getTotal =(qty, price, isPm) =>{
    if(!isPm){
      return "--";
    }else{ 
      var amount = 0;
      try{ 
        const _qty = qty || 0;
        const _price = price || 0;
        amount = (qty*price);
     
      }catch(err) {
          console.log("err:" + err);
       }; 

      return amount.toFixed(2);
 
    }
  }

  useEffect(()=>{
    if(order){
      computeTotalAmount();
    }

  },[order]);


  return (
    
  <>
  
      <Dialog
        
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={checkClosingDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        className={classes.postDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
          
        <DialogTitle id="max-width-dialog-title"> 
          Confirm Your Orders
          {order && 
                <Card >
                    <CardHeader
                        avatar={
                              <Avatar alt="username" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${order.storeUserId}?alt=media`}  className={classes.avatar} />    
                        }
                        action={
                          <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                          
                              <IconButton aria-label="close"   onClick={checkClosingDialog}>
                                <CloseIcon color="primary"/>
                              </IconButton>
                              </Grid>
                          }
                        
                        title={`${order.storeName} `}
                        subheader={ new Date(order.created).toLocaleDateString()}
                    />
                </Card>
            }
        </DialogTitle>
        <DialogContent>
             {  isLoadingProducts && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
              
              { (order && store && !isLoadingProducts)&& 
                <div>
                  <GridList cellHeight={300} className={classes.gridList} cols={2}>
                  { order.items.map( (item) =>( 
          
                        <GridListTile key={item.productId} cols={1}  style={{ width: '200px' }}>
                          
                          <img  className={classes.imageUi} src={useProductImageUrl(order.storeId,item.productImage,item.productId)} alt={item.productName} 
                            onError={onErrorImg}/>

                          <GridListTileBar
                            title={item.productName}
                            subtitle={<span>Price: {item.productPrice} Qty: {item.count}</span>}
                            actionIcon={
                              <IconButton aria-label={`info about ${item.productName}`} className={classes.icon}>
                                <InfoIcon />
                              </IconButton>
                            }
                          />

                        </GridListTile>
                      
                    ))}
                  </GridList> 

                  <Grid  container  alignItems="center" spacing={1}>
                    <Grid item key="j1" xs={12}>
                      <TextField
                        id="description-textarea"
                        label= "Notes"
                        placeholder="Notes"
                        multiline
                        variant="outlined"
                        fullWidth
                        value = {notes}
                        color="primary"
                        rowsMax={2}
                        size="small"
                        inputRef = {messageInput}
                        onChange={handleNotes}
                        className ={classes.textLocationField}
                      />
                    </Grid>
              
                    <Grid item key="j2"  xs={6}>
                      <TextField
                      disabled
                        id="status-selector"
                        select
                        label= "Status"
                        placeholder="Set Order Status"
                        variant="outlined"
                        fullWidth
                        value = {status}                  
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                        className ={classes.textLocationField}
                      >
                        {salesStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    { (store && store.services) && 
                        <Grid item key="j22"  xs={6}>
                          <TextField                          
                            id="schedule"
                            label= "Scheduled"            
                            variant="outlined"
                            fullWidth
                            value = {schedule}                  
                            color="primary"
                            size="small"       
                            className ={classes.textLocationField}
                          >
                          
                          </TextField>
                        </Grid>
                    }
                    <Grid item key="j3"  xs={12}> 
                        {store.showPrice ?
                        <Typography variant="caption" color="primary"> </Typography>
                          : <Typography variant="caption" color="secondary"> "Prices are not shown, you can negotiate the prices on the comments." 
                          </Typography>}  
                    </Grid>
                    <Grid item key="j4"  xs={12}> 
                        {store.services ?
                        <Typography variant="caption" color="secondary"> "You can asked for available schedule on comments below. The seller will schedule for you." 
                        </Typography>:
                        <Typography variant="caption" color="primary"> </Typography>
                        }
                    </Grid>

                    <Grid item key="j41"  xs={12}>

                      <TableContainer component={Paper}>
                        <Table   aria-label="item table">
                          <TableHead>
                            <TableRow>
                            <TableCell></TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Qty.</TableCell>
                              <TableCell align="right">Price</TableCell>
                              <TableCell align="right">Total</TableCell>     
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {order.items.map((item) => (
                              <TableRow key={item.productId}>
                                <TableCell padding="checkbox">
                                  <IconButton>
                                    <DeleteForeverIcon onClick={(event)=>{  deleteMe(event, item)}} />                                    
                                   </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {item.productName}
                                </TableCell>
                                <TableCell align="right">{item.count}</TableCell>
                                <TableCell align="right">{store.showPrice ?    Number.parseFloat(item.productPrice).toFixed(2):"--"}</TableCell>
                                <TableCell align="right">{store.showPrice ? getTotal(item.count, item.productPrice,  store.showPrice  ):"--"}</TableCell>
                                
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                       
                    </Grid>

                    <Grid item key="j5"  xs={12}>
                           
                       <Typography variant="body2" color="primary">Total Amount:  &nbsp;
                         {  store.showPrice ?  (store.currency ==='PHP' ? <>&#8369;</>  : '$') :  "Checked your comments below. Prices probably negotiated." }
                         {  store.showPrice ?  formatter.format(totalAmount) : "" }    
                       </Typography>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >

                        
                          <Typography variant="body2" color="primary">   {order.negTotalAmount < totalAmount?"Discounted Total Amount" :"Total Amount"} :  &nbsp;
                          {  store.showPrice ?  (store.currency ==='PHP' ? <>&#8369;</>  : '$') :  "" }
                            {  store.showPrice ?  formatter.format(order.negTotalAmount?order.negTotalAmount:0.0) : 0.0 }   
                          </Typography>
                    
                    </Grid>
                
                  </Grid>
                </div> 
              }        
        </DialogContent>
        <DialogActions>
 
              <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                  
                      <Grid    xs={12} md={6} sm={6} item container direction="row"    >
                   {/**
                          <Grid item  key="j6" xs={3} sm={2} md={2}>
                          
                           
                              <Tooltip title="Upload images, limit 2mb size.">
                                <IconButton aria-label="upload video/image" component="span">
                                <PhotoLibraryIcon color='primary'/>
                                </IconButton> 
                                </Tooltip>
                             
                          </Grid>
                          */}  
                          <Grid item key="j7"  xs={3} sm={2} md={2}>
                            <Tooltip title="Enter notes upon confirmation of your orders.">
                                  <IconButton aria-label="message text" onClick={handleMessageBox} > 
                                    <MessageIcon  color='primary'/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>
                      
                      </Grid>  
                      <Grid item  key="j8" xs={12} md={6} sm={6} container direction="row" justify="center"  alignItems="center">
                             
                             <Button
                                 variant="outlined"
                                 color="primary"
                                 className={classes.button}
                                 onClick={()=>{handleConfirmOrderDialog(notes)}}
                                 fullWidth
                               >
                                   {isSending && <CircularProgress/>  }
                                   {!isSending && <><PublishIcon/> Confirm Order</>}
                               </Button>
                     </Grid>
                      
            
              </Grid>
        </DialogActions>
        {order &&
          <Button  fullWidth color="primary" 
              className={classes.button} 
              aria-label="show more"
              onClick={handleExpandClick}
              endIcon={ <Icon className={classes.iconUi}>
                <ExpandMoreIcon  color="primary" className={clsx(  {
                  [classes.expandOpen]: expanded,
                })}/>
              </Icon>}
          > 
              {order.countComment>0?order.countComment:""} Comments              
          </Button>
    
              }
        {order && 
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          
            <Grid container>   
                <CommentEntrySnO  storeId={order.storeId} orderId ={order._id}/> 
            </Grid>

              <Grid container>
                  {expanded &&
                  <CommentsSnO   store={store} orderId ={order._id} />    
                }  
              </Grid>

            {order.countComment > 3   ?
                <Button  fullWidth color="primary" 
                className={classes.button} 
                aria-label="show more"
                onClick={handleExpandClick}
                endIcon={ <Icon className={classes.iconUi}>
                  <ExpandMoreIcon  color="primary" className={clsx(  {
                    [classes.expandOpen]: expanded,
                  })}/>
                </Icon>}
            > 
                {order.countComment>0?order.countComment:""} Comments               
            </Button>
            :
            <></>
            }


          </Collapse>
        }
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} >
            
                <Alert  onClose={handleCloseSnack} severity="success">
                    <AlertTitle> Update send                               
                    </AlertTitle>
                    
                    {snackMessage}
                </Alert>
                
          
        </Snackbar>
      </Dialog>
     
     
      </>
  
  );
}

 
export default OrderConfirmation;

//The Bootstrap grid system has four classes: 
//xs (phones),
// sm (tablets), 
//md (desktops), and 
//lg (larger desktops). 
//The classes can be combined to create more dynamic and flexible layouts.