import React , {useState,useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Grid, Divider, AppBar, Toolbar, IconButton, Typography , Slide, Card, CardHeader, Avatar, CardContent, CardActions, Badge, Collapse, Container, Icon, TextareaAutosize, TextField, ListItemIcon, ListItemText, Snackbar, CardMedia, GridList, GridListTile, GridListTileBar, LinearProgress, Box} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import PostingImage from '../templates/PostingImage';
import ProductImage from '../templates/ProductImage';
import ProductImageS from '../templates/ProductImageS';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import MyMapBox from './myMapBox';
import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { Link } from "gatsby";
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime} from '../utils/MantawiTime'; 
import { Alert, AlertTitle } from '@material-ui/lab';
import PeopleIcon from '@material-ui/icons/People';
// SHARING -- PRIVATE, FRIEND, PUBLIC
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// UPLOAD VIDEO/IMAGE
// TAG A FRIEND ???
// WHERE ARE YOU LOCATION
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PublishIcon from '@material-ui/icons/Publish';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
 
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FiberNewRoundedIcon from '@material-ui/icons/FiberNewRounded';
import ConfirmDeleteImageDialog from '../templates/ConfirmDeleteImageDialog';
import ConfirmDeleteProductDialog from '../templates/ConfirmDeleteProductDialog';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDialog:{
        background: "rgba(0,0,0,0)",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    expand4: {
        transform: 'rotate(0deg)',
        marginLeft: '5%',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      border: 1,
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "none",
      minWidth: '200px',
    },
    input: {
      display: 'none',
    },
    textLocationField: {
      marginTop: '10px' 
    },
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleTextUi:{
      marginTop: 20,
    },
    borderUi: {
      border: "1px solid rgb(212, 212, 212)",
    },
    productImageSui:{
      marginTop: 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      maxHeight: 220,
      minHeight: 220,
    },
    gridListDiv:{
      zIndex: theme.zIndex.drawer + 1,
    },
    imageUi:{
      maxHeight: 220,
      minHeight: 220,
      maxWidth: 150,
      minWidth: 150,
    },
    imageUi2:{
      maxHeight: 220,
      minHeight: 220,
      width: '100%',
 
    },
  
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    
    },
    tileBar:{
      zIndex: theme.zIndex.drawer + 2,
    },
    prgBar: {
      width: '100%',
      height: '10px',
      backgroundColor: red[500],
      transform: 'translateZ(0)',
    },
  }),
);
 

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

let storage = undefined;


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  
  
};


const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
const postImageUrl = ( imageName: string, storeId: string, productId: string ) => {
   
  const lastDot = imageName.lastIndexOf('.');
  const fileName = imageName.substring(0, lastDot);
  const ext = imageName.substring(lastDot + 1);
  
  const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
  const completeName= `${imageUrl}${storeId}%2F${productId}%2F${newName}?alt=media`;
  console.log("product image imageURL: " + completeName);
  return completeName;

}
const dropImage = require(`../images/error_image.png`);
const onErrorImg = (event:  any) =>{
   
  event.target.src= require('../images/error_image.png');
}
 
const ProductEntry = ( { openProductEntryDialog, handleCloseProductEntryDialog ,  userProfile, store, prid, matchesXS}) =>{
  const classes = useStyles();
  const messageInput = useRef(null);
  
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [widthLabel, setWidthLabel] = React.useState("md"); 
  
  const [isSending,setIsSending] = useState(false);
  
  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setFullWidth(event.target.checked);
     
    if(fullWidth){
        setWidthLabel("md");
    }else{
        setWidthLabel("sm");
    }
    setMaxWidth('md' as DialogProps['maxWidth']);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openProductDeleteDialog, setOpenProductDeleteDialog] = useState(false);

   
  const [message, setMessage] = useState("");
  const [index, setIndex] = useState(0);

  const [expanded, setExpanded] = React.useState(false);
 
  const [today, setToday] = useState(new Date().toLocaleDateString());
  const [description, setDescription] = useState("");
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [pprice, setPPrice] = useState(0.0);
  const [quantity, setQuantity] = useState(0);
  const [status, setStatus] = useState('new');
  const [unit, setUnit] = useState('pc');
  const [productType, setProductType] = useState("item");
  
  const [efiles, setEFiles] = useState([]);
   
  const [urls, setUrls] = useState([]);
  var nfiles = []

  const [timerStarted,setTimerStartec] = useState(false);
  const [productId, setProductId] = useState(prid);
  const [product, setProduct] = useState(null);
  
  const [progress,setProgress] = useState([]);
  const [privacy, setPrivacy] = useState(null);
  const [openSnack,setOpenSnack] = useState(false);
  const [alertMessage,setAlertMessage] = useState("");
  const MAX_ITEM_COUNT = 6;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log("acceptedFiles: " + acceptedFiles);
   
    if((acceptedFiles.length + efiles.length) > MAX_ITEM_COUNT){
      setAlertMessage("count");
      setOpenSnack(true);
    }else{
      fileLoader( Array.from(acceptedFiles));
  
    }
 
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop,accept: 'image/*'})

  const checkClosingDialog =()=>{
    if(!isSending){ 
       handleCloseProductEntryDialog();
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
   //junald
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  
 const [overSize, setOverSize] = useState([]);
 const [overSizeMessage, setOverSizeMessage] = useState("");
 const [fileCountUpload,setFileCountUpload] = useState(0);

 const productStatus =[
   {value: 'new',label: 'New'},
   {value: 'cancelled',label: 'Cancelled'},
   {value: 'available',label: 'Available'},
   {value: 'preorder',label: 'Pre-Order'},
   {value: 'outofstock',label: 'Out of Stock'},
 ]

 const productUnit =[
  {value: 'pc',label: 'pc'},
  {value: 'kg',label: 'kg'},
  {value: 'gl',label: 'gl'},  
  {value: 'ads',label: 'ads'}, 
]

 const productTypes =[
  {value: 'item',label: 'Item'},
  {value: 'services',label: 'Services'},
  {value: 'consultation',label: 'Consultation'},
  {value: 'ads',label: 'Ads'},
  {value: 'others',label: 'Others'},
]
 
//item size upload
 const handleFileChange = (event)  => {
  if (event.target.files.length  ) {
    if((event.target.files.length + efiles.length)> MAX_ITEM_COUNT){
      setAlertMessage("count");
      setOpenSnack(true);
    }else{
      fileLoader( Array.from(event.target.files));
    }
   
  }
 }
 

  const fileLoader = (arrFiles)  => {
    
    setAlertMessage("size");
    console.log("files count fileLoader: "+ efiles.length);
    console.log("productentry: " +arrFiles);
    const newfiles = arrFiles.map((file: any, index) => {

      
      var urlFile = URL.createObjectURL(file)
      var _overSize = false;
    
      if (file.size > 300000) // 2 mb for bytes.
      {
        _overSize = true;
          overSize.push(file.name);
          setOverSize(overSize);
      } 
 
      var component = "img";
      var imageType = file.type.indexOf('image');
      if(imageType === -1){
        component = file.type;
      }else{
        component = 'img'
      }
      return {overSize: _overSize, index: index,fileType: file.type, size: file.size, img: urlFile, fileName: file.name, author:globalThis.PROFILE_UID, progress: 0, file: file,
        component: component,status: 'local'
      };

    });
 
   // setFiles([...files,newfiles]);
      var osMsg = "";
      var osFileMsg = "";
      var hasOverSize = false;
      var notImage = false;
      var _efiles = [];
      newfiles.map(f =>{
          if(f.overSize){ 
            hasOverSize = true;
            osMsg = osMsg +  ` ${f.fileName}=${f.size}, `;
          }else{
            if(f.component === 'img'){  
              
              _efiles.push(f);
            }else{
              notImage = true;
              osFileMsg =` ,Some files are not images. `;
            }
          }
      });
      console.log("files count : "+ efiles.length);
    
       setEFiles(nfiles.concat(  efiles.concat(_efiles)));
    // setFiles
    // setFiles(files);
      var themsg = `You have ${overSize.length} over size files selected (250kb limit).`;
      if(hasOverSize){
        themsg = themsg + osMsg + osFileMsg; 
      }else{
        themsg = osFileMsg;
      }
                     
      setOverSizeMessage(themsg);
      setOpenSnack((hasOverSize||notImage));
    
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    if(storage === undefined){ 
      storage = firebase.storage();
    }
    if(prid === null){
      let _product = 
        {
     
          userId: globalThis.PROFILE_UID,
          storeId: store._id,
          productName: "",
          descrption: "",
          price: 0.0,
          pprice: 0.0,
          quantity: 0,
          status: "new",
          unit: "pc",
          productType: "item",
          countView: 1,
          countLike: 0,
          countDisLike: 0,
          countTag: 0,
          countShare: 0,
          countComment: 0,
          urlFiles: [],
          follow: false,
          hasUpdates: false, 
          category: "assorted",
          showPrice: false,
          indexOrder:0
        }
       
        setProduct(_product);
        setIsLoading(false);
    }else{
      
      reloadProduct();
    }


  },[]);

  const reloadProduct =() =>{
    setIsLoading(true);
    var getProduct = [ firestore.collection("products").doc(prid).get().then(
      function(doc){
        if (doc.exists) {
          let _data = doc.data();
          setProduct(_data);
          setProductName(_data.productName);
          setDescription(_data.description|| "");
          setPrice(_data.price || 0.0);
          setPPrice(_data.pprice || 0.0);
          setQuantity(_data.quantity || 0);
          setProductType(_data.productType || 'item');
          setEFiles(_data.urlFiles);
          nfiles = _data.urlFiles;
          
          setStatus(_data.status||"new");
          setUnit(_data.unit || "pc");
          console.log("efiles urlFiles: " + _data.urlFiles.length);
          console.log("efiles1: " + efiles.length);
          console.log("nfiles1: " + nfiles.length);
        }
    }).then(()=>{
      setIsLoading(false);
      console.log("efiles2: " + efiles.length);
    })]
    var response = Promise.all(getProduct);
  }

  useEffect(()=>{
 
    if(isSending){
      if(efiles.length>0){ 
        if(fileCountUpload >= efiles.length){
          console.log(efiles.length +"   fileCountUpload " + fileCountUpload)
          setTimeout(() => {
              publishPostRecord();
          }, 2000);
        } 
      }else{
        publishPostRecord();
      }
    }
 
  },[fileCountUpload]);


  const handleName= (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setProductName(value);
  }
  const handleDescription= (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setDescription(value);
  }
  const handlePrice= (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setPrice(value);
  }
  const handlePPrice= (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setPPrice(value);
  }
  const handleQuantity= (event) =>{  
    const value = event.target.value;
    const name = event.target.name;     
    setQuantity(value);
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

   

  const handleProductTypeChange = (event) => {
    console.log("productType: " + event.target.value);
    setProductType(event.target.value);
  };

  let fileCounter = 0;
  //const uploadTask = theStorage.ref(`images/post/${postRecordId}/${postProfileId}/${theImage.name}`).put(theImage);
  const  uploadFunction = (theImage,index,postRecordId) => {
    
    const uploadTask =  firebase.storage().ref(`images/stores/${store._id}/${postRecordId}/${theImage.fileName}`).put(theImage.file);
         uploadTask.on(
          "state_changed",
          snapshot => {
            const progressCount = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //the theimage progress value is some how connected to state progress
            theImage.progress = progressCount;
            progress[index] = theImage.progress ;
            setProgress([...progress]);
      
          },
          error => {
            // Error function ...
            console.error(error);
          },
          () => {
            fileCounter++;
            setFileCountUpload(fileCounter);
          }
      );
    
  }


  var totalSizeUploaded = 0;
  const publishPost = async () =>{

    if(isSending){
      return;
    }

    setAlertMessage("title");
    if(productName.trim().length ===0  ){
      setOpenSnack(true);
      return;
    }

    setIsSending(true);
    
    fileCounter =0;
     
    //var uniqueKey = firebase().database().ref().child('posts').push().key;
    totalSizeUploaded = 0;
    const postedDate = utcTime();
      

    if(prid === null){
      let _product ={
        ...product,
        productType: productType,
        created: postedDate,
        updated: postedDate,
      }
       setProduct(_product);
        await firestore.collection("products").add(_product)
        .then( 
          (productRecord) =>{
            console.log('initial product save' + productRecord.id);   
            setProductId(productRecord.id);
            return productRecord.id;
          }).then( async (_productId)=>{
            if(efiles.length>0){
              efiles.forEach((file,index) =>{     
                
                if(!file.overSize){

                  totalSizeUploaded = totalSizeUploaded + file.size;
                  progress.push(0);
                  setProgress([...progress]);
                  if(file.status==='local'){ 
                    uploadFunction(file,index,_productId);
                  }else{
                    file.progress = 100;
                    progress[index] = file.progress ;
                    setProgress([...progress]);
                    fileCounter++;
                     setFileCountUpload(fileCounter);
                  }
                }
        
              });
        
            }else{
              //something bugy
              setFileCountUpload(1);
            } 
          })
        .catch(function(error) {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log("xxxxxxxxxxxxxxxxxxxcode: "+ message);
          console.error(error);
          // ...
        });
    } else{
      
      if(efiles.length>0){
        efiles.forEach((file,index) =>{     
          
          if(!file.overSize){

            totalSizeUploaded = totalSizeUploaded + file.size;
            progress.push(0);
            setProgress([...progress]);
            if(file.status==='local'){ 
              uploadFunction(file,index,prid);
            }else{
              file.progress = 100;
              progress[index] = file.progress ;
              setProgress([...progress]);
              fileCounter++;
              setFileCountUpload(fileCounter);
            }
          }
  
        });
      }else{
        setFileCountUpload(1);
      }
      console.log("xxxxxxxxxxxxxxxxxxxcode:setFileCountUpload");
  
    }

    console.log("upload started: "+ productId);
    
  }
 

  const publishPostRecord = async ()=>{
    
    const filesData = efiles.map((f) =>{return {fileName: f.fileName, author: f.author , size: f.size, fileType: f.fileType, status: 'uploaded'}});
    
    const postedDate = utcTime();

    const _product = {
      ...product,
      productName: productName.substring(0,1000),
      description: description? description.substring(0,5000) : "",
      price: price,
      pprice: pprice,
      quantity: quantity,
      productType: productType,
      status: status,
      unit: unit,
      updated: postedDate,
      urlFiles: filesData,
    }
 
    firestore.collection("products").doc(productId).update(_product).then(()=>{
    
      handleCloseProductEntryDialog();
    }) ;
  
  }
 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [messageBoxFocus, setMessageBoxFocus] = useState(false);
   
  const handleCloseFeelings = () => {
    setAnchorEl(null);
  };
 
  const handleMessageBox = () => {
    messageInput.current.focus();
  } 

  const deleteImage = (index) =>{
    var theImage = efiles[index];
    var deleteRef = firebase.storage().ref(`images/stores/${store._id}/${productId}/${theImage.fileName}`);

    const lastDot = theImage.fileName.lastIndexOf('.');
    const fileName = theImage.fileName.substring(0, lastDot);
    const ext = theImage.fileName.substring(lastDot + 1);
 //   const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
    const completeName = `images/stores/${store._id}/${productId}/thumbnails/${fileName}_400x400.${ext}`;
    var deleteThumbsRef =   firebase.storage().ref(completeName);
    deleteThumbsRef.delete().then(()=>{
      console.log("thumbnailsthumbnails: " + completeName);
    }).catch((error)=>{console.log('thumbnails: '+ error)})
    deleteRef.delete().then(function() {
        var _tileData = efiles.splice(index,1);
        setEFiles(efiles);
        firestore.collection("products").doc(productId).update({urlFiles: efiles})
      .then(()=>{ 
        reloadProduct();  
      }) ;
    }).catch(function(error) {
      console.log("handleDeleteImage: " + error);
    });
 
  }

  const deleteProduct = () =>{
    firestore.collection("products").doc(productId).update({status: 'delete'}).then(()=>{
      setIsSending(false);
      handleCloseProductEntryDialog();
    })
  }

  const deleteMe = (event, _tile ) =>{
    event.preventDefault();
    setOpenDeleteDialog(true);
    var indexOf = efiles.indexOf(_tile);
    setIndex(indexOf);

    setMessage(_tile.fileName);
    console.log("delete me: " + _tile.fileName + " "   );
      
  }
 
  const handleDeleteImage = () =>{
    
    deleteImage(index);
    setOpenDeleteDialog(false);
 }

 const handleCancelImage = () =>{
   setOpenDeleteDialog(false);
 }
  
 const handleCancelProduct = () =>{
  setOpenProductDeleteDialog(false);
}

const handleDeleteProduct = () =>{
    //junald
    console.log("handleDeleteProduct");
  setOpenProductDeleteDialog(false);
  deleteProduct();

}

const openDeleteProductConfirmDialog = (event) =>{
  event.preventDefault();
  setOpenProductDeleteDialog(true);
}

  return (
    
  <>
  
      <Dialog
        fullScreen={matchesXS}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openProductEntryDialog}
        onClose={checkClosingDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        className={classes.postDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
          
        <DialogTitle id="max-width-dialog-title"> 
          
              <Card >
                  <CardHeader
                      avatar={

                            <Avatar alt="username" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${globalThis.PROFILE_UID}?alt=media`}  className={classes.avatar} />    
                      }
                      action={
                        <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                            {  privacy&&privacy==='public'?  <PublicIcon color="primary" fontSize="small" /> 
                                    : privacy==='friend'?  <PersonIcon color="primary"  fontSize="small" />
                                    : privacy==='shared'?  <PeopleIcon  color="primary" fontSize="small" />
                                    : privacy==='private'?  <LockIcon  color="primary" fontSize="small" />:<></>   
                            }
                            <IconButton aria-label="close"   onClick={checkClosingDialog}>
                              <CloseIcon color="primary"/>
                            </IconButton>
                            </Grid>
                        }
                       
                      title={`${userProfile.name} - ${store.name}`}
                      subheader={today}
                  />
              </Card>
       
        </DialogTitle>
        <DialogContent>
 
        
          <Grid  container  alignItems="center" spacing={1}>
            <Grid item xs={12} className={classes.borderUi}>
              
               <input {...getInputProps()   } /> 
             
               {/**
                <ProductImage  tileData={efiles} storeId={store._id} productId={prid}/>     
                  */} 
            <div  className={classes.gridListDiv} > 
                <GridList cellHeight={250}  className={classes.gridList} cols={efiles.length === 0 ? 1: 2.5}> 
                  {efiles.length === 0 &&
                 
                   <Box   
                     display="flex"
                     alignItems="center"
                     justifyContent="center" {...getRootProps()} className={classes.imageUi2} >  
                     <CloudUploadOutlinedIcon  color='secondary'>Drop Images</CloudUploadOutlinedIcon>  
                   
                  </Box>
                  
               
                  }
        
                  {efiles.map((tile) => (
              
                    <GridListTile key={tile.fileName}   style={{ height: '220', width: '150px' }} >
                              <img  {...getRootProps()} className={classes.imageUi}
                                  src={(tile.img !== null && tile.img !== undefined)? tile.img :postImageUrl(tile.fileName,store._id, productId)} 
                                  alt={tile.fileName}   onError={onErrorImg}
                                  />    
                                
                            <GridListTileBar  
                                    className={classes.tileBar} 
                                    title={tile.fileName}
                                    actionIcon={
                                      tile.status === 'uploaded'?
                                      <IconButton aria-label={`info about ${tile.fileName}`} className={classes.icon} onClick={(event)=>{  deleteMe(event, tile)}}>
                                        <DeleteForeverIcon  fontSize="small"/>
                                      </IconButton>
                                      :
                                      <IconButton aria-label={`info about ${tile.fileName}`} className={classes.icon}  >
                                        <FiberNewRoundedIcon  fontSize="small"/>
                                      </IconButton>
        
                                    }
                                  />
                            { tile.progress > 0 && 
                            <LinearProgress className={classes.prgBar} variant="determinate" value={tile.progress}  />  
                            }
                    
                    </GridListTile>
                  
                  ))}
               </GridList>
           </div> 
                {/**
                  <div className={classes.productImageSui}>
                <ProductImageS  tileData={efiles} storeId={store._id} productId={prid} handleDeleteImage={handleDeleteImage}/>   
                </div> */}
                
            </Grid>
            <Grid item xs={12}>
            <Typography variant="caption">Maximum 6 images and 250kb file size</Typography>
              </Grid>
            <Grid item xs={8}>
                <TextField
                  id="name-textarea"
                  label= "Name"
                  placeholder="Enter item code or name."
                  variant="outlined"
                  fullWidth
                 
                  value={productName}
                  color="primary"
                  size="small"
                  onChange={handleName}
                  inputRef = {messageInput}
                  className ={classes.textLocationField}
                />
              </Grid>
            
                <Grid item xs={4}>
                  <TextField
                    id="status-selector"
                    select
                    label= "Unit"
                    placeholder="Set Item Unit"
                    variant="outlined"
                    fullWidth
                    value = {unit}                  
                    color="primary"
                    size="small"
                    onChange={handleUnitChange}
                    className ={classes.textLocationField}
                  >
                    {productUnit.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
            <Grid item xs={12}>
                <Typography variant="caption"> </Typography>
                     <Link to="https://www.w3schools.com/html/html_formatting.asp">How to format text in html?</Link>
                <TextField
                  id="description-textarea"
                  label= "Descriptiond"
                  placeholder="Item description."
                  multiline
                  variant="outlined"
                  fullWidth
                  value = {description}
                  color="primary"
                  rowsMax={5}
                  size="small"
                  onChange={handleDescription}
                  className ={classes.textLocationField}
                />
              </Grid>
            <Grid item xs={4}>
              <TextField
                  id="price-textarea"
                  label= "Inventory Price"
                  placeholder="Purchase price"
                  variant="outlined"
                  fullWidth
                  value = {pprice}
                  inputProps={{min: 0, style: { textAlign: 'right' }}}     
                  color="primary"
                  size="small"
                  onChange={handlePPrice}
                  className ={classes.textLocationField}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                /> 
                   </Grid>
               <Grid item xs={4}> 
                <CurrencyTextField
                  id="price-textarea"
                  label= "Sale Price"
                  placeholder="Sale price"
                  variant="outlined"
                  fullWidth
                  value = {price}
                  outputFormat="string"
                  color="primary"
                  size="small"
                  //onChange={handlePrice}
                  onChange={(event, value)=>  setPrice(value)}
                  className ={classes.textLocationField}
                  decimalCharacter="."
                  digitGroupSeparator=","
                  currencySymbol=""
                />
             

              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="quantity-textarea"
                  label= "Quantity"
                  placeholder="Enter item quantity"
                  variant="outlined"
                  fullWidth
                  value = {quantity}                  
                  color="primary"
                  size="small"
                  inputProps={{min: 0, style: { textAlign: 'right' }}}     
                  onChange={handleQuantity}
                  className ={classes.textLocationField}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
               </Grid>
               <Grid item xs={6}>
                <TextField
                  id="type-selector"
                  select
                  label= "Type"
                  placeholder="Set Item Type"
                  variant="outlined"
                  fullWidth
                  value = {productType}                  
                  color="primary"
                  size="small"
                  onChange={handleProductTypeChange}
                  className ={classes.textLocationField}
                >
                   {productTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
               <Grid item xs={6}>
                <TextField
                  id="status-selector"
                  select
                  label= "Status"
                  placeholder="Set Item Status"
                  variant="outlined"
                  fullWidth
                  value = {status}                  
                  color="primary"
                  size="small"
                  onChange={handleStatusChange}
                  className ={classes.textLocationField}
                >
                   {productStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
             </Grid>
           
           
        </DialogContent>
        <DialogActions>
 
              <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                  
                      <Grid item xs={12} md={6} sm={6} container direction="row"   justify="flex-start">
                   
                          <Grid item xs={3} sm={2} md={2}>
                          
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                onChange={handleFileChange}
                                multiple
                                type="file"
                              />
                              <label htmlFor="contained-button-file">
                              <Tooltip title="Upload images, limit 2mb size.">
                                <IconButton aria-label="upload video/image" component="span">
                                <PhotoLibraryIcon color={efiles.length>0?'primary':'secondary'}/>
                                </IconButton> 
                                </Tooltip>
                              </label>
                          </Grid>
                           
                          <Grid item xs={3} sm={2} md={2}>
                            <Tooltip title="You must enter the name and the description.">
                                  <IconButton aria-label="message text" onClick={handleMessageBox} > 
                                    <MessageIcon  color={productName.length>0?'primary':'secondary'}/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>

                          <Grid item xs={3} sm={2} md={2}>
                            <Tooltip title="Delete your product forever.">
                                  <IconButton disabled={prid===null?true:false} aria-label="message text" onClick={(event) =>openDeleteProductConfirmDialog(event)} > 
                                    <DeleteForeverIcon  color={prid === null?'disabled':'primary'}/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>
                      
                      </Grid>  
                      <Grid item xs={12} md={6} sm={6} container direction="row" justify="center"  alignItems="center">
                             
                             <Button
                                 variant="outlined"
                                 color="primary"
                                 className={classes.button}
                                 onClick={publishPost}
                                 fullWidth
                               >
                                   {isSending && <CircularProgress/>  }
                                   {!isSending && <><PublishIcon/> Publish </>}
                               </Button>
                     </Grid>
                      
            
              </Grid>
        </DialogActions>
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} >
            <>
            {alertMessage==='size' &&
                <Alert  onClose={handleCloseSnack} severity="warning">
                    <AlertTitle> Uploaded Files                                  
                    </AlertTitle>
                    
                    {overSizeMessage}
                </Alert>
            }
            {alertMessage==='title' &&
                
                <Alert  onClose={handleCloseSnack} severity="warning">
                    <AlertTitle> Item name is required.
                     </AlertTitle>
                    
                </Alert>
            }
            {alertMessage==='count' &&
             <Alert  onClose={handleCloseSnack} severity="warning">
              <AlertTitle> Only 3 images allowed.
                </AlertTitle>
             </Alert>
            }
            </>
        </Snackbar>


      </Dialog>
     
      {openDeleteDialog && 
        <ConfirmDeleteImageDialog
          open={openDeleteDialog}
          index={index}
          message={message}
          handleDeleteImage={handleDeleteImage}
          handleCancelImage={handleCancelImage} />
      }

      {openProductDeleteDialog && 
        <ConfirmDeleteProductDialog
          open={openProductDeleteDialog} 
          product={product}
          handleDeleteProduct={handleDeleteProduct}
          handleCancelProduct={handleCancelProduct} />
      }

 
      </>
  
  );
}

 
export default ProductEntry;

//The Bootstrap grid system has four classes: 
//xs (phones),
// sm (tablets), 
//md (desktops), and 
//lg (larger desktops). 
//The classes can be combined to create more dynamic and flexible layouts.