import React,{useState, useEffect, Fragment} from 'react';
import { withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PostImage from './PostImage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Badge from '@material-ui/core/Badge';

import FaceIcon from '@material-ui/icons/Face';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Button, Grid, ListItem, List, Container, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Paper, Link, TextField, MenuItem, Tooltip } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import LinkIcon from '@material-ui/icons/Link';
import { firebase, firestore, useFirestoreQuery } from "gatsby-theme-firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import {utcTime, utcTimePlus, sortByProperty, warpText, getLabel} from '../utils/MantawiTime'; 
import { navigate } from '@reach/router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { RelationZeroOrManyToMany } from 'mdi-material-ui';
import EventIcon from '@material-ui/icons/Event';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { isToday } from 'date-fns';
import { green } from '@material-ui/core/colors';
import ScheduleIcon from '@material-ui/icons/Schedule';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  
    avatar: {
      backgroundColor: red[500],
    },
 
    content:{
      marginTop: '-25px'
    },
    listUi:{
      maxHeight: 400,
      width: "auto",
      marginLeft: -25,
      marginRight: -25,
      overflow: 'auto',
    },
    listItemUi:{
     
      width: "auto",
      flex: 1,
    },   
    loadingUi:{
        width: "100%",        
    },
    typoUi:{
        maxHeight: 100,
        display:"block",
        overflow: 'hidden',
    },
    notificationUi:{
       flex: 1,
    },
    
    gridUi:{
      flex: 1,
      width: "100%"
    },
    inline: {
      display: 'inline',
    },
    avatarSmall: {
      backgroundColor: red[500],
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: 5
    },
    headerCenter:{
      alignItems: "center",
      display: "flex"
    },
    textLocationField: {
      marginTop: '10px',
    },
    buttonMenu: {        
      textTransform: "none",     
    },
    
  }),
);
   
TimeAgo.addLocale(en);
 
 

const YourSales = ({store,closedStoreDrawer}) => {

  let formatter = new Intl.NumberFormat('en-IN', {
   
    minimumFractionDigits: 2
  })

  const classes = useStyles();
  const timeAgo = new TimeAgo('en-US');

  const processing =['ordered','open','cart','processing','delivery','intransit','delivered']
  const completed =['completed','canceled'];
  const [statusIn, setStatusIn] = useState("processing");
 
  const [yourSales, setYourSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRecords, setHasRecords] = useState(false);
  const [scheduleList, setScheduleList] = useState(false);
  const [utcToday, setUtcToday] = useState(utcTime());
  const [filterStatus, setFilterStatus] = useState(processing);
  
  const statuses =[
    {value: "processing",label: 'Processing'},
    {value: "completed",label: 'Completed'},
  ];

  const useFirestoreQueryYourSales=  async <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
  //  console.log("useFirestoreQueryYourSales: " );
    const unsubscribe =  await query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        setYourSales(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })).filter( (d) =>{
            return scheduleList ? d.schedule != null : true;
          }),
        );
        setIsLoading(false);
        
      },
      (err: Error) => {
        console.log("err useFirestoreQueryYourOrders: " + err);
        console.error(err);
         
      } 
      
    );
    
    return () => unsubscribe();
  }

  const handleStatusChange = (event) => {
    if(event.target.value === 'completed'){
      
       refreshYourSales(completed);
    }else{
   
      refreshYourSales(processing);
    }
 
    setStatusIn(event.target.value);
    
  };

  const handleScheduleList = (event)=>{
    setScheduleList(!scheduleList)
   // refreshYourSales(filterStatus)
  };
   
  // const [yourSales, isLoading] = useFirestoreQuery(
  //     firestore.collection("sales").where("storeId", "==", store._id)
  //       .where("status","in",["open","delivered","processing","delivery","intransit","completed"])
  //       .where("totalCount",">",0)
  //       .orderBy("totalCount")
  // );

  const refreshYourSales = (filter:any) =>{
    setFilterStatus(filter);
    setIsLoading(true);
    // if(scheduleList){
    //   useFirestoreQueryYourSales(
    //     firestore.collection("sales").where("storeId", "==", store._id)
    //         .where("status","in",filter)            
    //         .where("totalCount",">",0)
    //         .where("schedule","!=",null)
    //         .orderBy("totalCount"))
    // }else{
    //   useFirestoreQueryYourSales(
    //     firestore.collection("sales").where("storeId", "==", store._id)
    //         .where("status","in",filter)
    //         .where("totalCount",">",0)
    //         .orderBy("totalCount"))
    //  }

     useFirestoreQueryYourSales(
      firestore.collection("sales").where("storeId", "==", store._id)
          .where("status","in",filter)
          .where("totalCount",">",0)
          .orderBy("totalCount"))
    
  } 

  useEffect( ()=>{ 
    
   // refreshYourSales(processing);
     refreshYourSales(filterStatus);
     console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxfilterstatus");
  },[])

  useEffect(()=>{
    refreshYourSales(filterStatus);
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxscheduleList");
  },[scheduleList])
 
 const handlePost = (sales)=>{
 // var to=`/?store=${sales.storeId}&kil=${sales.storeUserId}&oid=${sales._id}`;
  if(sales.hasUpdates){ 
    firestore.collection("sales").doc(sales._id).update( {hasUpdates:false} )
        .then(()=>{
          console.log("post updated........");
        }).catch((error)=>{
          console.log("post updated error: " + error);
          console.error(error);
    });
  }
 // navigate(to);
  closedStoreDrawer();
}

const computeTotalAmount = (sales) =>{
  let totalAmt = 0;
  try{ 
    sales.items.map( (item) =>{
      let _total = (item.count?item.count:0) * (item.productPrice ? item.productPrice : 0);
      totalAmt = totalAmt + _total;
    });
  }catch{ (err) =>{
    console.log('error computeTotalAmount');
   
    } 
  }
  return totalAmt;
}

  
const handleSalesOrder = (sales: any)=>{
  
  handlePost(sales);
 // var to=`/?store=${store._id}&mid=so&slid=${sales._id}`;
 var to=`/?store=${globalThis.STORE_ID}&mid=so&slid=${sales._id}`;
 
  navigate(to);
  closedStoreDrawer();
}

const [sortData,setSortData] = useState([]);

useEffect( ()=>{
     setSortData( yourSales.sort(sortByProperty("updated")));
     if(yourSales.length>0 && !hasRecords){
       setHasRecords(true);
     }
},[yourSales] );
 
  return (
    <div className={classes.root}>
        { hasRecords && 
            <Grid container  > 
                <Grid item xs={12}>
                  <Button fullWidth                 
                      variant="outlined"
                      onClick={ (e) => {handleScheduleList(e)} } 
                      startIcon={<ScheduleIcon/>}
                      className={classes.buttonMenu}                
                      color= { scheduleList ? "primary" : "secondary" }
                      >
                      Scheduled List
                                      
                  </Button>  
                </Grid>
                <Grid item xs={12}>
                     <TextField
                        id="filter-selector"
                        select
                        label= "Filter"
                        placeholder="Set Sales Order Status"
                        variant="outlined"
                        fullWidth
                        value = {statusIn}                  
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                        className ={classes.textLocationField}
                      >
                        {statuses.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                  </TextField>
                </Grid>
            </Grid>
                 
                  
          }
    {isLoading && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
    {!isLoading &&
     
    <List className={classes.listUi} >
        { sortData.length === 0 &&  
        <Container>
          <Grid  container direction="row" justify="flex-start"  >
              <Typography variant="caption">
                Start Marketing your products and services.
              </Typography>
              <Typography variant="caption" className={classes.headerCenter}>
                Click <LinkIcon fontSize="small"  color="primary" /> Copy and paste your item url link. 
              </Typography>
          </Grid>
          </Container>
        }
        { sortData.map((sales) =>(
          
          <ListItem className={classes.listItemUi} key={sales._id} onClick={ ()=>{handleSalesOrder(sales)} }button  >
            
                
            <Avatar  alt={`Avatar ${sales.orderUsername}` }
              className={classes.avatarSmall}
              src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${sales.orderUserId}?alt=media`}>
                {sales.orderUsername}
                
            </Avatar>
              

            <ListItemText className={classes.typoUi}
                primary={ 
                  <React.Fragment>
                    <Typography  component="span"
                          variant="subtitle2"
                          display="block"
                          color="textPrimary"
                          noWrap >
                      {sales.orderUsername}   
                    </Typography>
                    <Typography  component="span"
                        variant="subtitle2"
                        display="block"
                        color="primary"
                        noWrap >
                         SN: {sales.transNumber}   
                    </Typography>
                  </React.Fragment>
                }
                secondary={
         
                      <React.Fragment>
                        
                          <Typography   component="span"  variant="body2"  color="textPrimary"  >
                            { `${timeAgo.format(utcTimePlus(sales.created), 'twitter')} : `}
                          </Typography>

                          <Typography   component="span"  variant="body2"  color="secondary"   >
                            { getLabel( sales.status)  }
                          </Typography>
                         
                          <Typography variant="body2"  component="span" color="textPrimary" display="block">
                            Total : &nbsp;
                            {store.currency ==='PHP' ? <>&#8369;</>  : '$'} 
                            { formatter.format(sales.negTotalAmount? sales.negTotalAmount : computeTotalAmount(sales))}
                            &nbsp;
                            Qty: {sales.totalCount} 
                          </Typography>
                          <Typography variant="body2"   component="span" color="textPrimary" display="block">
                            {sales.schedule !== null && 
                            `Due On: ${ new Date(sales.schedule).toLocaleString().replace(":00","")}`
                            }
                          </Typography>
                         
                      </React.Fragment>
                }
            />
 
            <ListItemSecondaryAction>
                 {sales.hasUpdates?
                  <NotificationsActiveIcon   style={{ color: green[500] }} fontSize="small"  />:
                  <NotificationsIcon className={classes.notificationUi} color="primary" fontSize="small" />
                  }
                  {sales.schedule !== null &&  
                (  sales.schedule >= utcToday ?
                      <Tooltip title={`Dont forget this schedule for services: ${ new Date(sales.schedule).toLocaleString().replace(":00","")}`}>
                        <EventIcon className={classes.notificationUi} style={{ color: green[500] }} fontSize="small" />
                      </Tooltip>:
                      <Tooltip title={`Overdue, Please checked your services schedule :${ new Date(sales.schedule).toLocaleString().replace(":00","")}`}>
                        <EventBusyIcon color="secondary" fontSize="small"/>
                      </Tooltip>
                )}
                  
            </ListItemSecondaryAction>
                    
          </ListItem>
        
        ))}
      </List>
     
    }
   </div>
  );
} 
export default YourSales;
