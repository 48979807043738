import React,  {useState, useEffect, useContext, Fragment, useRef} from 'react';
import SEO from "./SEO";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from './ProTip';
 
import Product from '../templates/Product';
import Grid from "@material-ui/core/Grid";
import { Paper, IconButton, InputBase, Divider, createStyles, makeStyles, Theme, Button, CircularProgress, List, ListItem, Fab, Menu, MenuItem, TextField, Backdrop, AppBar } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import MyMapBox from './myMapBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import Switch from '@material-ui/core/Switch';
import {utcTime, sortByProperty, Copyright} from '../utils/MantawiTime'; 
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import ListIcon from '@material-ui/icons/List';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {Place, LocationState} from '../utils/mantawiObject';
import queryString from 'query-string';
import { DotsVertical } from 'mdi-material-ui';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import green from "@material-ui/core/colors/green";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';
import LinkIcon from '@material-ui/icons/Link';
import { navigate } from 'gatsby';

const token = process.env.GATSBY_MAPBOX_API_KEY;
const geocodingUrl = 'https://api.mapbox.com/geocoding/v5';
const geocodingOptions = '&limit=5'
const geocodingOptions3 = '&autocomplete=true&limit=10'
const geocodingOptions2 = '&autocomplete=true&types=country,region,district,locality&limit=5'
const geocodingOptions4 = '&autocomplete=true&types=poi&limit=10'
const geocodingtypes='&autocomplete=true&types=country,region,district&limit=1'

const mapboxGeocoding = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingOptions2}`;
const mapboxGeocodingR = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingtypes}`;
 
// const geocodingtypesAddress='&autocomplete=true&types=address'
// const mapboxGeocoding = (query: string) =>  
// `${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingtypesAddress}`;
// const mapboxGeocodingR = (query: string) =>  
// `${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingtypesAddress}`;
 

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const onErrorImg = (event:  any) =>{ 
  event.target.src= require(`../images/Miniens1.png`);
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      textTransform: 'none'
    },
    divider: {
      height: 28,
      margin: 4,
    },
    headerSpacer: {
      display: 'flex',
      paddingTop: 15,
     
    },
    loaderUi:{    
      
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',  
      marginLeft: '50%',
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',
      marginLeft: '50%',
    },
  
    listUi:{
     
      width: '33vw',
      overflow: "auto",
   
    },
    searchItemList: {     
      cursor: 'pointer',
      color: 'black'
    },
    fabUi:{
      width: "100%",
    },
    menuUi:{
        width: '33vw',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    containerUi: {
  
      marginLeft: -5,
      minHeight: 350,
    },
    container: {
     
      position: 'relative',
      top: 5,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      flex: 1, 
      border: "1px solid rgb(212, 212, 212)",
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarClipped: {
      zIndex: theme.zIndex.drawer + 1,
      height: '50px', 
      backgroundColor: 'white',
      background: 'white',
      minWidth: 345,
    },
    dotsVerticalIcon: {
      color: "#efefef"
    },
    fab: {
      margin: '0px',
      top: 'auto',
      right: 'auto',
      bottom: '20px',
      left:  'auto',
      position: 'fixed',
      backgroundColor: green[300],
      zIndex: theme.zIndex.drawer + 1 
    },
    containerBorder:{
      marginTop: 10,
      border: "1px solid rgb(212, 212, 212)",
    }, 
    storeDetailsUi:{
      paddingLeft: 15,
      paddingRight: 15,
    },
    headerCenter:{
      alignItems: "center",
      display: "flex"
    },
    headerIcon:{
      marginLeft: 5,
    },
    linkUrlUi:{
      textTransform: 'none',
    }
  }),
);
globalThis.COUNTER = 0;
const StorePosts = ({ userProfile,  store}) => {
 
  
  const classes = useStyles();
 
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
  
   
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [openMap, setOpenMap] = React.useState(false);
  const [searchValue , setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [displayStyle, setDisplayStyle] = useState("none");
  const [showList, setShowList] = useState(false);
   
  const [AnchorElMapMenu, setAnchorElMapMenu] = React.useState<null | HTMLElement>(null);
   
  const [isGeoLocating,setIsGeoLocating] = useState(false);
  
  const [showText,setShowText] = useState(true);
  const [isPostMapMenuOpen,setIsPostMapMenuOpen] = useState(false);
  
   
  var [error, setError] = React.useState<Error | null>(null);
   
  const [colCount, setColCount] = useState(12);
  const [switchSx, setSwitchSx] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
   
  const [stores, setStores] = useState<firebase.firestore.DocumentData[]>([]); 

  const [isLoading, setIsLoading] = useState(false);
   
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
 
  function copyToClipboard(theLink) {
    // checkView();
     const el = document.createElement('textarea');
     el.value = theLink;
     el.setAttribute('readonly', '');
     el.style.position = 'absolute';
     el.style.left = '-9999px';
     document.body.appendChild(el);
     el.select();
     document.execCommand('copy');
     document.body.removeChild(el);
   }

   function navigatLink(totheStore) {
    navigate(totheStore);
   }

  const handleCloseSnackBar = () =>{
  
     setOpenSnackBar(false);
  }
 
  const useFirestoreQueryStores =  <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryStores: " );
    const unsubscribe =    query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        setStores(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
         
        setIsLoading(false);
        
      },
      (err: Error) => {
        console.log("err useFirestoreQueryStores: " + err);
        console.error(err);
        setError(err);
      } 
      
    );
    
    return () => unsubscribe();
  }
    
  const handleCloseMapMenu = () => {
    setIsPostMapMenuOpen(false);
    setAnchorElMapMenu(null);
  };

  const handleClickMapMenu = (event) => {
 
    setIsPostMapMenuOpen(true);
    setAnchorElMapMenu(event.currentTarget);
     
  };

  const onSearch = (event) => {
  
       if(event.target.value.length > 2){
         fetchQuery(event.target.value);
         setSearchValue(event.target.value);
       }
 
   };

   const onSelectItem = (event,index) => {
     console.log("onselecteditem: " + index);
     setShowText(true);
     setShowList(false);
     const selectedCenter = options[index];
     setSelectedLocation(selectedCenter.name);
     setSearchValue(selectedCenter.name);
     setIsPostMapMenuOpen(false);
     handleSearchLocation(selectedCenter.name);
  
   };

   const req = (url: string, body?: any, method = 'GET') =>
   new Request(url, {
     method,
     headers: new Headers({
       Accept: 'application/json',
       'Content-Type': 'application/json',
       'Accept-Charset': 'utf-8'
     }),
     body
   });
   
   const fetchQuery = (query: string) => {
     setShowList(true);
     fetch(req(mapboxGeocoding(query)))
       .then((res: any) => res.json())
       .then((data: any) => {
         var datamap =  data.features.map((poi: any) => ({
           id: poi.id,
           center: poi.center,
           name: poi.place_name,
           place_name: poi.place_name
         }));
         setOptions( datamap );
       }).catch( (error)=>{
         console.error(error);
       });
   };

   const fetchQueryR = (query: string) => {
   // setShowList(true);
    fetch(req(mapboxGeocodingR(query)))
      .then((res: any) => res.json())
      .then((data: any) => {
        console.log(data);
        var datamap =  data.features.map((poi: any) => ({
          id: poi.id,
          center: poi.center,
          name: poi.place_name,
          place_name: poi.place_name
        }));
       // setOptions( datamap );
        let _selected = datamap[0] ;
        return _selected;
      }).then( (_selected) =>{
        console.log(_selected.center);
         setShowText(true);
         setSelectedLocation(_selected.name);
         setSearchValue(_selected.name);
         setIsGeoLocating(false);
      }).catch( (error)=>{
        console.error(error);
      });;
  };

  const handleUserLocation = () => {
   
    if(isGeoLocating){
      return;
    }
    setShowText(false);
    setIsGeoLocating(true);
    handleCloseMobile();
    navigator.geolocation.getCurrentPosition(position => {
        let newViewport = {
            height: "100vh",
            width: "100vw",
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 12
        }
        console.log(newViewport);
   
        fetchQueryR(`${position.coords.longitude},${position.coords.latitude}`);
    })
  }

  const handleMapDialogIndex = (dialogBox: string,sdLocation: any) =>{
    if(sdLocation === 'close without selecting'){
      setSelectedLocation(undefined);
      setSearchValue('');
    }else{ 
      setSelectedLocation(sdLocation.name);
      setSearchValue(sdLocation.name);
      handleSearchLocation(sdLocation.name);
    }
    setOpenMap(false);
  }

  const handleMapOpenDialog = () =>{
    setShowText(true);
    setSelectedLocation(undefined);
    setSearchValue('');
    setOpenMap(true);
    handleCloseMobile();
  }
   
  const  handleChangeSwitch = () =>{
 
    if(switchSx){
      setColCount(12);
      setSwitchSx(false);
    }else{
      setColCount(6);
      setSwitchSx(true);
    }
  }

  const handleSearchLocation2 = () =>{
    handleSearchLocation(searchValue);
  }
 
  const handleSearchLocation =(_selectedLocation)=>{
    
    if(_selectedLocation===undefined || _selectedLocation.length === 0){

    }else{ 
       
      var _location = _selectedLocation.split(',');
      var pCountry = undefined;
      var pDistrict =undefined;
      var pCity = undefined;
      var pOther =undefined;
  
      if(_location.length>0)
        pCountry = _location.pop();
      if(_location.length>0)
        pDistrict = _location.pop();
      if(_location.length>0){ 
        pCity = _location.pop();
      }
      if(_location.length>0){ 
        pOther = _location.pop();
      }
   
      filterStores(pCountry,pDistrict, pCity);
    
    }  
  }
 

  useEffect( () =>{
  
      // userProfile.locationCountry
      // userProfile.locationCity
      // userProfile.locationDistrict

      // if(userProfile === null || userProfile === undefined){
      //   filterStores("Philippines",null, null);
      //   console.log("store_posts country:");
      // }else{ 
      //   console.log("store_posts all:");
      //   filterStores(userProfile.locationCountry,userProfile.locationDistrict, userProfile.locationDistrict);
      // }

      filterStores("Philippines",null, null);
  },[])

  useEffect( ()=>{
    console.log("stores: " + stores.length);
  },[stores])

  const filterStores = ( country, district, city) =>{
    setIsLoading(true);
 

    if((district === undefined  && city === undefined) || (district === null  && city ===  null) ){
      console.log(`search ${country}`);
      useFirestoreQueryStores(
        firestore.collection('stores')
        //.where("country","==", country.trim())
      );
    }else if( city === undefined || city === null){
      console.log(`search ${country}-${district}`);
      useFirestoreQueryStores(
        firestore.collection('stores')
        .where("country","==", country.trim())
        .where("district","==", district.trim())
      );
    }else{
      console.log(`search ${country}-${district}-${city}`);
      useFirestoreQueryStores(
        firestore.collection('stores')
        .where("country","==", country.trim())
        .where("district","==", district.trim())
        .where("city","==", city.trim())
      );
    }
   
  }

  const handleCloseMobile = () => {
    setAnchorEl(null);
  };

  const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
   
   
  return (
  
   <Container className={classes.containerUi}>
     
          
      {( isLoading ) && 
          <Typography variant="body2" color="textSecondary" align="center">
            <img  src={globalThis.LOADING_IMAGE} alt='loading'/>
          </Typography>   
        } 
        {( !isLoading ) &&
        
          <div>
            {/**
             <Hidden mdUp>
              <AppBar
              position="relative"
              className={classes.appBarClipped} 
              >
             <Grid item container xs={12} key="toolbar" >
       
                    <Grid container  direction="row"  justify="flex-start"  alignItems="center" >
                    <IconButton className={classes.iconButton} aria-label="menu" >
                      <LocationSearchingIcon />
                    </IconButton>
                              
                    {showText?
                        <InputBase
                          className={classes.input}
                          placeholder="Filter your posts by location. (city,region,country)"
                          value={selectedLocation}
                          onClick={()=>{setShowText(false)}}
                        /> 
                        :
                        <InputBase
                        className={classes.input}
                        placeholder="Filter your posts by location. (city,region,country)"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        defaultValue={searchValue}
                        onChange={onSearch}
                        onClick={()=>{setShowText(false)}}
                        /> 
                        }
                        <IconButton onClick={handleClickMobile}>
                          <ListIcon/>
                        </IconButton>
                  
                      </Grid>          
                      
                    <Menu
                      id="simple-menu-mobile"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMobile}
                    >
        
                        <MenuItem  color="primary"  onClick={handleSearchLocation}>
                        
                          { isRefreshing ? <CircularProgress  color="primary"  size={30}/> : < RefreshIcon  color="primary"  />  } <Typography   color="primary">Refresh</Typography>
                       
                        </MenuItem>
                          
                         
                        <MenuItem  onClick={handleUserLocation}>
                           
                           {  isGeoLocating ? <CircularProgress color="primary" size={30}/> : <MyLocationIcon  color="primary" />} <Typography   color="primary">My Location</Typography>
                       
                        </MenuItem>

                        <MenuItem onClick={handleMapOpenDialog}> 
                          
                           <SearchIcon  color="primary"  />  <Typography   color="primary"> Maps </Typography>
                          
                        </MenuItem>
                    
                        <MenuItem >  
                          <Switch
                              checked={switchSx}
                              onChange={handleChangeSwitch}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </MenuItem> 
                          
                    </Menu>
                     { 
                      ( searchValue.length > 0 && showList)&&
                      <Container className ={classes.container}  >
                      
                            <List>
                              {
                                options.map((el, index) => (
                                  <ListItem
                                    key={index}
                                    onClick={(event) => onSelectItem(event, index)}
                                    className={classes.searchItemList}
                                    button
                                  >
                                    {el.place_name.substr(0,120)}
                                  </ListItem>
                                ))
                              }
                            </List>
                            
                      </Container> 
                    }
           

                </Grid>
            </AppBar>
             </Hidden>
              */}
           
              <AppBar
                position="relative"
                className={classes.appBarClipped} 
              >
              <Grid item xs={12} key="toolbar">
                    <Grid container direction="row" justify="center" alignItems="center">
                      <IconButton className={classes.iconButton} aria-label="menu" >
                        <LocationSearchingIcon  />
                      </IconButton>
                         
                      {showText?
                              <InputBase
                                className={classes.input}
                                placeholder="Filter your store's by location. (city,region,country)"
                                value={selectedLocation}
                                onClick={()=>{setShowText(false)}}
                              /> 
                              :
                              <InputBase
                            className={classes.input}
                            placeholder="Filter your store's by location. (city,region,country)"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            defaultValue={searchValue}
                            onChange={onSearch}
                            onClick={()=>{setShowText(false)}}
                            /> 
                      }   
               
               <Tooltip title="Refresh the posts base on your selected location.">
               <IconButton color="primary" className={classes.iconButton} aria-label="search" onClick={handleSearchLocation2}>
                 {  isRefreshing ? <CircularProgress size={30}/> : <RefreshIcon />}
               </IconButton>
               </Tooltip>
  
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Auto Geo locate.">
               <IconButton color="primary" className={classes.iconButton} aria-label="gel locate" onClick={handleUserLocation}>
                 {  isGeoLocating ? <CircularProgress size={30}/> : <MyLocationIcon />}
               </IconButton>
               </Tooltip>
               
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Open Map screen.">
               <IconButton  color="primary" className={classes.iconButton} aria-label="search" onClick={handleMapOpenDialog}>
                 <SearchIcon />
               </IconButton>
               </Tooltip>
               {/**
               <Divider className={classes.divider} orientation="vertical" />
               <Tooltip title="Switch display to two column"> 
               <Switch
                   checked={switchSx}
                   onChange={handleChangeSwitch}
                   color="primary"
                   name="checkedB"
                  
                   inputProps={{ 'aria-label': 'primary checkbox' }}
                 />
                </Tooltip>
            */}
             </Grid>

             { 
               ( searchValue.length > 0 && showList)&&
               <Container className ={classes.container}  >
              
                    <List>
                      {
                        options.map((el, index) => (
                          <ListItem
                            key={index}
                            onClick={(event) => onSelectItem(event, index)}
                            className={classes.searchItemList}
                            button
                          >
                            {el.place_name.substr(0,120)}
                          </ListItem>
                        ))
                      }
                    </List>
                    
              </Container> 
             }
           

         </Grid>
         </AppBar>  

 
       
          <div className={classes.headerSpacer} />
            <Grid container  direction="row" alignItems="center" justify="center" spacing={3} > 
              {  stores.map((_store, index) =>(   
              
                  <Grid container className={classes.containerBorder} >
                    <Grid item xs={12}>
                      <img
                          src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${_store._id}%2F${_store._id}?alt=media`}
                          alt="Uploaded Images"
                          height="200"
                          width="100%"
                          onError={onErrorImg}
                          onClick={()=>navigatLink(`?store=${_store._id}`)}
                      />
                    </Grid>
                    
                      <Grid item   xs={11} className={classes.storeDetailsUi}>
                    
                          <Typography variant="body1" noWrap display="inline">
                              <Button  className={classes.linkUrlUi} onClick={()=>navigatLink(`?store=${_store._id}`)} >
                                  <Typography color="primary" variant="body2">   {_store.name} </Typography>
                              </Button>  
                          </Typography>
                          <Typography  className={classes.headerCenter} noWrap display="inline">
                          </Typography>
                          <Grid container > 
                            <Grid item>   
                            <Button  className={classes.linkUrlUi}> <DateRangeIcon  className={classes.headerIcon} /> <Typography  variant="body2">{` : ${new Date(_store.created).toDateString()} `}</Typography></Button>
                            </Grid>
                            {_store &&  <Grid item> <Button  className={classes.linkUrlUi}><RoomIcon  className={classes.headerIcon}/> <Typography   variant="body2">{` : ${_store.country},${_store.district}${_store.city?(','+_store.city):''}`} </Typography></Button></Grid> }
                            { _store.email.length>0 &&  <Grid item> <Button  className={classes.linkUrlUi}> <EmailIcon  className={classes.headerIcon} /> <Typography   variant="body2"> :{_store.email} </Typography> </Button></Grid>}
                            { _store.contactNo.length>0 && <Grid item>  <Button  className={classes.linkUrlUi}> <ContactPhoneIcon  className={classes.headerIcon}/> <Typography  variant="body2"> :  {_store.contactNo}</Typography> </Button></Grid> }
                            <Grid item>
                              <Button  className={classes.linkUrlUi} onClick={()=>navigatLink(`?store=${_store._id}`)} >
                                <LinkIcon  className={classes.headerIcon}  color="primary"/><Typography color="primary" variant="body2"> Visit {_store.name} </Typography>
                              </Button>  
                            </Grid> 
                          </Grid>
                         
                          <Typography variant="body1" noWrap display="inline">
                            {_store.description}
                          </Typography>
 
                      </Grid>
                     
                  </Grid>
                  
                ))
              } 
            </Grid>
         </div>
      }
    
      <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapDialogIndex}  source="posts"  />
  

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className={classes.headerSpacer} />
      <Copyright/>
 

      </Container>
 
  );
}
 
export default StorePosts



