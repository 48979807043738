import 'date-fns';
import React, { useState, useEffect, useContext } from 'react';


import {auth, useAuth,  firestore , firebase}  from 'gatsby-theme-firebase';
 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {UserProfile} from '../utils/mantawiObject';
 
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
import {Snackbar,  IconButton, Grid, Paper, LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography} from '@material-ui/core';
import { Link  } from 'gatsby-theme-material-ui';

import SearchIcon from '@material-ui/icons/Search';
import MyMapBox from './myMapBox';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//npm i @date-io/date-fns use 1.3 something 
//npm i date-fns
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootEntry: {
      display: 'flex',
      flexGrow: 1,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    prgBar: {
      width: '250px',
      height: '10px'
    },
    paper: {
      backgroundColor: 'black',
       
    },
    expandButton: {
      
      minWidth: '100px'
    },
    snackTop:{
      marginTop: 50,
    },
    contactUi:{
      width: '90%',
      height: '100vh'
    },
    cardUi:{
      marginTop: 10,
    },
    donateUi:{
      marginTop: 10,
    },
    donateItemUi:{
      flex: 1,
    },
    tableCellUi:{
      flex: 1,
    },
    tableCellUiT:{
      flex: 1,
      textDecorationLine: 'line-through',
      color: "red",
    }

   
  }),
);

let storage = undefined;
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())

const Account = ({setShowPage}) => {
  
    let formatterMembership = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })

    const classes = useStyles();
    //this is working but we need the subscription into dollar, 
    const [formatter, setFormatter] = useState(new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }));
    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);
    const [checkedB, setCheckedB] = useState(false);
    // const [userProfile, setUserProfile] = useState<UserProfile>( {
 
    //     name: "",
    //     email: "",
    //     dob: new Date( "1901-01-26").getTime(),
    //     profilePicture: "",
    //     location: "",
    //     locationCenter: [0,0],
    //     created: null,
    //     updated: null,
    //     useLocation: true,
    //     locationCity: "",
    //     locationCountry: "",
    //     locationDistrict: "",
    //     locationOther: "",
    //     historyCounter: 0,
    //     historyStores: [{}],
    // });
    const [userProfile, setUserProfile] = useState(null);
  
    
    const [loading, setLoading] = useState( false );
    const [errorMsg, setError] = useState(null);
    const [image, setImage] = useState(null);
    const [displayName,setDisplayName] = useState("");
    const { isLoggedIn, profile }  = useAuth();
    const [membership, setMembership] = useState([]);
    const [center, setCenter] = useState([0,0]);
  
    const [openMap, setOpenMap]= useState(false);
    const [open, setOpen] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [severity, setSeverity] = React.useState("warning");
    const [message, setMessage] = React.useState("");


    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);   
    };

    const handleMapDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(true);
     
    }

    const handleMapCloseDialog = (dialogBox: string,sdLocation: any) =>{
      setOpenMap(false);
     
      handleLocation(sdLocation);
    }

    const handleLocation =(location)=>{
    
      if(  location !== undefined  && location !== null && location.name && location.name !== "" && location.name !== userProfile.location && location.name.length > 0 ){
       
       
        var _location = location.name.split(',');
        var pCountry = "";
        var pDistrict ="";
        var pCity = "";
        var pOther ="";

        if(_location.length>0)
          pCountry = _location.pop();
        if(_location.length>0){ 
          pDistrict = _location.pop();
          if(pDistrict.indexOf('City') !== -1){
            pCity = pDistrict;
            pDistrict = "";
          }
        }
        if(_location.length>0 && pCity.length===0){ 
          pCity = _location.pop();
        }
        if(_location.length>0)
          pOther = _location.pop();
       
         setUserProfile(
          {...userProfile,
          ['location']: location.name,
          ['locationCenter']: location.center,
          ['locationCountry']: pCountry.trim(),
          ['locationDistrict']: pDistrict.trim(),
          ['locationCity']: pCity.trim(),
          ['locationOther']: pOther.trim(),
          }
        );
      }
    }

    const handleCheckedB = () =>{
      var _useLocation = !checkedB;
      setCheckedB(_useLocation);
      setUserProfile(
        {...userProfile,
        ['useLocation']: _useLocation
       
        }
      );
    }
  
    useEffect(()=>{
 
      if(storage === undefined){ 
        storage = firebase.storage();
      }
 
      if(profile){
        
        console.log("mount useefffect profile: " +profile.uid );
        
        firestore.collection("users").doc(profile.uid).get().then(
          function(doc){

            if (doc.exists) {
              var data = doc.data();
              var _useLocation = (data.useLocation!==null && data.useLocation!==undefined)?data.useLocation:false ;
              setDisplayName(data.name);
              // setUserProfile({
              //     ...userProfile, 
              //     email: data.email,
              //     name: data.name,
              //     dob: data.dob ,
              //     profilePicture: data.profilePicture,    
              //     location: data.location,
              //     locationCenter: data.center || [0,0],
              //     created: data.created,
              //     updated: data.updated,
              //     useLocation: _useLocation,
              //     locationCity: data.locationCity || "",
              //     locationCountry: data.locationCountry || "",
              //     locationDistrict: data.locationDistrict || "",
              //     locationOther: data.locationOther || "",
                   
                  
              // });
              setUserProfile(doc.data());

              setUrl(data.profilePicture);

              setCheckedB( _useLocation);
              //fetch membership
              firestore.collection("membership").where( "userId","==",profile.uid).get().then( function(querySnapshot) {
          
                if(!querySnapshot.empty){
                    let _stList = [];
                    querySnapshot.forEach(function(doc) {
                        
                        _stList.push({ _id: doc.id, ...doc.data()});
                    }); 
                   
                    setMembership(_stList);
                } else{
                   
                }
              })

              firestore.collection("stores").where("userId", "==" ,profile.uid).get()
              .then(function(querySnapshot) {
                let _store = null;
                
                if(!querySnapshot.empty){
                  querySnapshot.forEach(function(doc) {
                      console.log("store: + " + doc.id, " => ", doc.data());
                      _store = { _id: doc.id, ...doc.data()};
                  }); 
                }
            
                return _store;
               }).then( (_store) =>{
                 if(_store!==null){
                  setFormatter(new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: _store.currency,
                      minimumFractionDigits: 2
                    }));
                  }
               })

            } else {

              console.log("this should not happened.....")
              throw "account does not exist";
   
            }
          }

        ).catch( (error)=> {
          console.error(error);
        }); 
   
      }else{
        setDisplayName('fetching...');
      }
      console.log("mount useefffect account");
   

    },[profile]);



    const saveUserProfile =  () => {  
    
      firestore.collection("users").doc(globalThis.PROFILE_UID)
        .update( userProfile ).then(()=>{
          console.log("updated........");
          setSeverity("success");
          setMessage("Account detail updated.")
          setOpen(true);
        });
          
    }  

    const handleInputChange = (event) =>{  
        //cutoff string
        const value = event.target.value;
        const name = event.target.name;     
    
        setUserProfile(
          {...userProfile,
          [name]: value.substring(0, 500)}
        );
          
    }

    const handleDateInputChange = (date: Date | null) =>{  
     
      setUserProfile(
        {...userProfile,
        dob:  date.getTime()}
      );
        
  }
 
    const spacing = 2;

    const handleUpload = (theImage) => {
     // const newName = `${profile.uid}.${theImage.name.split('.').pop()}` 
     const newName = `${profile.uid}` 
     const uploadTask = storage.ref(`images/profiles/${newName}`).put(theImage);
      uploadTask.on(
        "state_changed",
        snapshot => {

          const progressCount = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
       
          setProgress(progressCount);
        },
        error => {
          // Error function ...
          console.error(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images/profiles")
            .child(newName)
            .getDownloadURL()
            .then(url => {
              console.log("account profile: " + url);
              setUrl(url);
              userProfile['profilePicture']= url
              setUserProfile({...userProfile} );
            }).then( ()=>{
              saveUserProfile();
            });
        }
      );
    };

    const handleChange = (event)  => {
      if (event.target.files[0]) {
        const imageFiles = event.target.files[0];
        if (imageFiles.size > 150000) // 100,000 for bytes.
        {
          setSeverity("warning");
          setMessage("Profile image size exceed 150 kilobytes.")
          setOpen(true);
        
        } else{

          setImage(imageFiles);
          handleUpload(imageFiles);
        }
 
      }
    };
  
  //  const myExtScript = require('../utils/paypal');
    return (   
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid  container  justify="center"  alignItems="center">

      <Container >
        {userProfile &&
           <Card>
             
              <CardContent>
                <Grid container  spacing={2}>
                    <Grid item xs={12}>
                      <h2>
                         Account Information - {isLoggedIn && displayName}
                      </h2> 
                    </Grid>
                    <Grid key={'profilePicture'} item >   
                      <Grid key={'profilePicture-image'} item >
                        <>
                          <label htmlFor="icon-button-file">
                            <img
                              src={url || "https://via.placeholder.com/250x250"}
                              alt="Uploaded Images"
                              height="250"
                              width="250"
                            />
                          </label>
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            id="icon-button-file"
                            style={{ display: 'none', }}
                          />   
                        </>
                      </Grid>
                      <Grid key={'profilePicture-progress'} item>
                           <LinearProgress className={classes.prgBar}  variant="determinate" value={progress}  />
                      </Grid>
                    </Grid>


                    <Grid key={'textfields'} item xs={12} sm container>
                      <Grid item xs container  spacing={2}>
                         <Grid container>
                              <Grid item key={'textfields1'} xs={12}>
                                 <TextField   className={classes.rootEntry} id="name" name="name" label="Name" value={userProfile.name}  onChange={handleInputChange}/>
                              </Grid>
                   
                                {/**
                                 <TextField className={classes.rootEntry} id="dob" name="dob" label="Date of Birth" type="date" value={userProfile.dob}  onChange={handleInputChange}/>
                                  */}

                              <Grid item key={'textfields3d1'} xs={12}>
                                  <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="email"  name="email" label="Email" value={userProfile.email}  onChange={handleInputChange}/>

                              </Grid>
                                 
                                 <Grid item key={'textfields2'} xs={12}>
                                  <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        name="dob" 
                                        id="dob"
                                        label="Date of Birth"
                                        value={new Date(userProfile.dob)}
                                        onChange={handleDateInputChange}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />
                                       </Grid>
                              
                                
                              
                              <Grid item key={'textfields3'} xs={11}>
                                  <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="location"  name="location" label="Global Map GeoLocation" value={userProfile.location}  onChange={handleInputChange}/>

                              </Grid>
        
                              <Grid item key={'textfields4'} xs={1}>
                                  <IconButton  color="primary" aria-label="search location" onClick={() => handleMapDialog("posting","") } >
                                    <SearchIcon />
                                  </IconButton>
                                 
                              </Grid>
                              <Grid item key={'textfields3a4'} xs={12}>
                                 <TextField   className={classes.rootEntry} id="streetAddress" name="streetAddress" label="Street Address" value={userProfile.streetAddress}  onChange={handleInputChange}/>
                              </Grid>
                   
                              <Grid item key={'textfields5'} xs={11}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                  disabled
                                    checked={checkedB}
                                    onChange={handleCheckedB}
                                    name="checkedB"
                                    color="primary"
                                    value={userProfile.useLocation}
                                  />
                                }
                                label={<Typography variant="caption">Use your location as a default location for delivery or services. Available on Future Updates </Typography>}
                              />
                              </Grid>
          
               {/**
                              <Grid item  key={'textfield5'} xs={12}>
                                  <TextField InputProps={{ readOnly: true,}} className={classes.rootEntry} id="profile"  name="profile"  label="Profile" value={userProfile.profilePicture}  onChange={handleInputChange}/>
                              </Grid> */}
                            </Grid>
                      </Grid>
                    </Grid>
                 </Grid>  
              </CardContent>
              <CardActions disableSpacing>
                  
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                  <Button className={classes.expandButton} variant="outlined" color="primary" onClick={saveUserProfile }>
                       Submit
                  </Button>
                  </Grid>
                  <Grid item>
                  <Button className={classes.expandButton} variant="outlined" color="primary" onClick={()=>{setShowPage(2)} }>
                       Cancel
                  </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
        }
        {

      
            // <Grid container className={classes.donateUi} spacing={1} justify="flex-start" >
                    
            //         <Grid item >
            //           <Button className={classes.expandButton} variant="outlined" color="primary" >
            //             <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            //               <input type="hidden" name="cmd" value="_s-xclick" />
            //               <input type="hidden" name="hosted_button_id" value="YEP7LSCFTCJ3W" />
            //               <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            //               <img alt="" border="0" src="https://www.paypal.com/en_PH/i/scr/pixel.gif" width="1" height="1" />
            //             </form>
            //           </Button>
            //         </Grid>
            //         <Grid item xs >
            //               <Typography variant="body1">
            //                   You can help us developed, this mini online shop. Your support will greatly, appreciated to further develop new features.

            //               </Typography>
            //         </Grid>
                    
            //  </Grid>

}
                { //profile &&    
                      // <Card className={classes.cardUi}>
                      //   <CardHeader title="Membership" /> 
                      //   <CardContent> 
                      //     <TableContainer component={Paper}>
                      //       <Table   aria-label="item table">
                      //         <TableHead>
                      //           <TableRow>
                      //             <TableCell>Code</TableCell>
                      //             <TableCell align="center">Description</TableCell>
                      //             <TableCell align="center">Start</TableCell>  
                      //             <TableCell align="center">End</TableCell>   
                      //             <TableCell align="center">Price</TableCell>  
                      //             <TableCell align="center">Status</TableCell>    
                      //           </TableRow>
                      //         </TableHead>
                      //         <TableBody>
                      //         {membership.map((item) => (
                      //           <TableRow key="p1">
                      //           <TableCell className={classes.tableCellUi}  component="th" scope="row">{item.code} </TableCell>
                      //           <TableCell className={classes.tableCellUi}  align="left">{item.description}</TableCell>
                      //           <TableCell className={classes.tableCellUi}  align="right">{new Date(item.started).toLocaleDateString()}</TableCell>
                      //           <TableCell className={classes.tableCellUi}  align="right">{new Date(item.ended).toLocaleDateString()}</TableCell>
                      //           <TableCell className={classes.tableCellUiT}  align="right">{formatterMembership.format(item.price)}</TableCell>
                      //           <TableCell className={classes.tableCellUi}  align="right">{item.status}</TableCell>   
                      //           </TableRow>
                      //         ))}             
                      //         </TableBody>
                      //       </Table>
                      //     </TableContainer>
                      //   </CardContent>
                      // </Card>
                }
            <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapCloseDialog}  source="account"  />
            <Snackbar className={classes.snackTop} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}   onClose={handleClose} >  
              <Alert  onClose={handleClose}  severity={severity}>
              <AlertTitle>{severity}</AlertTitle>
              <strong>{message}</strong>
            </Alert>
         </Snackbar>
       </Container>

       </Grid>
       </MuiPickersUtilsProvider>
    )
}

export default Account;
