import React,  {useState, useEffect, useContext, Fragment, useRef} from 'react';
import SEO from "./SEO";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import { useStaticQuery, graphql, navigate } from "gatsby";
import ProTip from './ProTip';
import Button from '@material-ui/core/Button';
import Product from '../templates/Product';
import Grid from "@material-ui/core/Grid";
import { Paper, IconButton, InputBase, Divider, createStyles, makeStyles, Theme, CircularProgress, List, ListItem, Fab, Menu, MenuItem, TextField, Backdrop, AppBar, Badge } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import MyMapBox from './myMapBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import Switch from '@material-ui/core/Switch';
import {utcTime, sortByProperty, Copyright} from '../utils/MantawiTime'; 
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
import ListIcon from '@material-ui/icons/List';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {Place, LocationState} from '../utils/mantawiObject';
import queryString from 'query-string';
import { DotsVertical } from 'mdi-material-ui';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import green from "@material-ui/core/colors/green";
import ConfirmOrderDialog from '../templates/ConfirmOrderDialog';
import OrderConfirmation from './OrderConfirmation';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DataGrid } from '@material-ui/data-grid';

const token = process.env.GATSBY_MAPBOX_API_KEY;
const geocodingUrl = 'https://api.mapbox.com/geocoding/v5';
const geocodingOptions = '&limit=5'
const geocodingOptions3 = '&autocomplete=true&limit=10'
const geocodingOptions2 = '&autocomplete=true&types=country,region,district,locality&limit=5'
const geocodingOptions4 = '&autocomplete=true&types=poi&limit=10'
const geocodingtypes='&autocomplete=true&types=country,region,district&limit=1'
const mapboxGeocoding = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingOptions2}`;
const mapboxGeocodingR = (query: string) =>  
`${geocodingUrl}/mapbox.places/${query}.json?access_token=${token}${geocodingtypes}`;
 
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
      textTransform: 'none'
    },
    divider: {
      height: 28,
      margin: 4,
    },
    headerSpacer: {
      display: 'flex',
      paddingTop: 15,
     
    },
    loaderUi:{    
      
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',  
      marginLeft: '50%',
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      alignItems: 'center',
      justifyItems: 'center',
      marginTop: '20%',
      marginLeft: '50%',
    },
  
    listUi:{
     
      width: '33vw',
      overflow: "auto",
   
    },
    searchItemList: {     
      cursor: 'pointer',
      color: 'black'
    },
    fabUi:{
      width: "100%",
    },
    menuUi:{
        width: '33vw',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    containerUi: {
  
      marginLeft: -5,
     
    },
    container: {
     
      position: 'relative',
      top: 5,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      flex: 1, 
      border: "1px solid rgb(212, 212, 212)",
    },
    appBarClipped: {
      zIndex: theme.zIndex.drawer + 1,
      height: '50px', 
      backgroundColor: 'white',
      background: 'white',
      minWidth: 345,
    },
    dotsVerticalIcon: {
      color: "#efefef"
    },
    fab: {
      margin: '0px',
      top: 'auto',
      right: 'auto',
      bottom: '20px',
      left:  'auto',
      position: 'fixed',
      backgroundColor: green[300],
      zIndex: theme.zIndex.drawer + 1 
    },
    viewAllUi: {
      fontWeight: "bold",
      textTransform: "none"

    },
  }),
);
globalThis.COUNTER = 0;
const WorkSchedule = ({location,  isLoggedIn, store , colCount}) => {


  let formatter = new Intl.NumberFormat('en-IN', {
   
    minimumFractionDigits: 2
  })
  
  const NO_SCHEDULE_SET = "No Schedule Set";
  
  const classes = useStyles();
 
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
  
   
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [openMap, setOpenMap] = React.useState(false);
  const [searchValue , setSearchValue] = useState('');
  const [options, setOptions] = useState([]);
  const [displayStyle, setDisplayStyle] = useState("none");
  const [showList, setShowList] = useState(false);
   
  const [AnchorElMapMenu, setAnchorElMapMenu] = React.useState<null | HTMLElement>(null);
   
  const [isGeoLocating,setIsGeoLocating] = useState(false);
  
  const [showText,setShowText] = useState(true);
  const [isPostMapMenuOpen,setIsPostMapMenuOpen] = useState(false);
  
   
  var [error, setError] = React.useState<Error | null>(null);
   
  //const [colCount, setColCount] = useState(12);
  const [switchSx, setSwitchSx] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [tagPosts, setTagPosts] =  useState<firebase.firestore.DocumentData[]>([]);  
  
  const [products, setProducts] = useState<firebase.firestore.DocumentData[]>([]); 

  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [confirmOrderDialog, setConfirmOrderDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openConfirmOrderDialog = () =>{
    if(store.userId === globalThis.PROFILE_UID || currentOrder === null){

    }else{
      setConfirmOrderDialog(true);
    }
  
  }

  const handleCloseConfirmOrderDialog = () =>{
    setConfirmOrderDialog(false);
  }

  //junald
  //we need to check the rules governing this databse security
  const handleConfirmOrderDialog = (_notes) =>{
     setConfirmOrderDialog(false);
     let today = utcTime();
     if(currentOrder && currentOrder.status === 'cart'){
       if(currentOrder.totalCount > 0){
          currentOrder.status="ordered";
          currentOrder.hasUpdates = true;
          currentOrder.orderedDate = today;
          currentOrder.updated = today;   
          currentOrder.notes = _notes;
          setCurrentOrder(currentOrder);
          let salesOrder = {
            storeId: store._id,
            storeName: store.name,
            storeUserId: store.userId,
            orderId: currentOrderId,
            orderUserId: currentOrder.userId,
            orderUsername: currentOrder.userName,
            totalCount: currentOrder.totalCount,
            items: currentOrder.items,
            schedule: currentOrder.soSchedule ? currentOrder.soSchedule : NO_SCHEDULE_SET,
            negTotalAmount: currentOrder.negTotalAmount,
            hasUpdates: true,
            notes: _notes ? _notes.substring(0,1000 ): "",
            status: 'open',
            countComment: 0,
            created: today,
            updated: today
          }

          firestore.collection("sales").add(salesOrder)
          .then( (_newSalesOrder) =>{
            firestore.collection("orders").doc(currentOrderId)
              .update({...currentOrder, salesOrderId:  _newSalesOrder.id})
              .then( (doc) =>{
                setCurrentOrderId(null);
                setCurrentOrder(null);
                console.log("order send");
                setSnackBarMessage("Order send successfully.");
                setOpenSnackBar(true);
                createdHistory("order",_newSalesOrder.id,currentOrderId,_notes,currentOrder.status);
                createdHistory("sales",_newSalesOrder.id,currentOrderId,_notes,'open');
             });

          });

       }
     }

  }

  const handleCloseSnackBar = () =>{
  
     setOpenSnackBar(false);
  }

  const handleProductOrderCount =   (product, operation) =>{

    if( globalThis.PROFILE_UID === undefined ){
      return;
    }
    console.log("handleProductOrderCount");
    const today =  utcTime();
    let _currentOrder = currentOrder;
    if(_currentOrder === null){
      
      _currentOrder = {
        storeId : store._id,
        storeUserId: store.userId,
        storeEmail: store.email,
        storeName: store.name,
        userId: globalThis.PROFILE_UID,
        userName: globalThis.PROFILE_USERNAME,
        showPrice: store.showPrice,   
        totalCount: 0,
        countComment: 0,
        created: today,
        updated: today,
        status: 'cart',
        hasUpdates: true,
        items: []
      }
    }
    
    let _itemOrder = _currentOrder.items.find( e => e.productId === product._id);
 
    if(_itemOrder === null || _itemOrder ===  undefined){
      _itemOrder = {
        productId: product._id,
        productName: product.productName,
        productPrice: product.price,
        count: operation==='addition'?1:0,
        productImage: product.urlFiles.length > 0 ? product.urlFiles[0].fileName : "", 
        showPrice: store.showPrice,      
      }
      _currentOrder.totalCount =  _currentOrder.totalCount+1;
      _currentOrder.items.push(_itemOrder);
      //save
    }else{
      if(operation==='addition'){
        _itemOrder.count = _itemOrder.count +1;
        _currentOrder.totalCount =  _currentOrder.totalCount+1;
      }else if(operation === 'remove' && _itemOrder.count !== 0){
        let _count =_itemOrder.count - 1;
        if( _count <= 0){
          _itemOrder.count = 0
        }else{
          _itemOrder.count = _itemOrder.count - 1
          
        }
        if(_currentOrder.totalCount - 1 <= 0){
          _currentOrder.totalCount = 0 ;
        }else{
          _currentOrder.totalCount =  _currentOrder.totalCount - 1;
        }
      }
    }
    createUpdateOrder(_currentOrder);
  
    return _itemOrder.count ;
  }

  const createUpdateOrder = async (_currentOrder) =>{
    if(currentOrderId === null){ 
      firestore.collection("orders").add(_currentOrder)
          .then( (doc) =>{
            setCurrentOrderId(doc.id);
            setCurrentOrder(_currentOrder);
          });
    }else{
      firestore.collection("orders").doc(currentOrderId)
        .update(_currentOrder)
        .then( (doc) =>{
        console.log("current order");
      });
    }
  }

  const useFirestoreQueryTagPosts=  async <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryTagPosts: " );
    const unsubscribe =  await query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        setTagPosts(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
        console.log("1useFirestoreQueryTagPosts: " + tagPosts.length);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryTagPosts: " + err);
        console.error(err);
        setError(err);
      } 
      
    );
    
    return () => unsubscribe();
  }


  const useFirestoreQueryCurrentOrders=  async <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    console.log("useFirestoreQueryCurrentOrders: " );
    const unsubscribe =  await query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        if(querySnapshot.empty){
          setCurrentOrderId(null);
          setCurrentOrder(null);
        }else{
          let _co = querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          }));
          setCurrentOrderId(_co[0]._id);
          setCurrentOrder(_co[0]);
        }
        
        console.log("useFirestoreQueryCurrentOrders: " + tagPosts.length);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryCurrentOrders: " + err);
        console.error(err);
        setError(err);
      } 
      
    );
    
    return () => unsubscribe();
  }

  const useFirestoreQueryProducts =  <T extends firebase.firestore.DocumentData>(
    query: firebase.firestore.Query,
  )  => {
    //console.log("useFirestoreQueryProducts: " );
    const unsubscribe =    query.onSnapshot(
      (querySnapshot: firebase.firestore.QuerySnapshot) => {
   
        setProducts(
          querySnapshot.docs.map(doc => ({
            _id: doc.id,
            ...(doc.data() as T),
          })),
        );
         
        setIsLoading(false);
        //console.log("done useFirestoreQueryProducts: " + products.length);
      },
      (err: Error) => {
        console.log("err useFirestoreQueryProducts: " + err);
        console.error(err);
        setError(err);
      } 
      
    );
    
    return () => unsubscribe();
  }
   

  const handleCloseMapMenu = () => {
    setIsPostMapMenuOpen(false);
    setAnchorElMapMenu(null);
  };

  const handleClickMapMenu = (event) => {
 
    setIsPostMapMenuOpen(true);
    setAnchorElMapMenu(event.currentTarget);
     
  };

  const onSearch = (event) => {
  
       if(event.target.value.length > 2){
         fetchQuery(event.target.value);
         setSearchValue(event.target.value);
       }
 
   };

   const onSelectItem = (event,index) => {
     console.log("onselecteditem: " + index);
     setShowText(true);
     setShowList(false);
     const selectedCenter = options[index];
     setSelectedLocation(selectedCenter.name);
     setSearchValue(selectedCenter.name);
     setIsPostMapMenuOpen(false);
  
   };

   const req = (url: string, body?: any, method = 'GET') =>
   new Request(url, {
     method,
     headers: new Headers({
       Accept: 'application/json',
       'Content-Type': 'application/json',
       'Accept-Charset': 'utf-8'
     }),
     body
   });
   
   const fetchQuery = (query: string) => {
     setShowList(true);
     fetch(req(mapboxGeocoding(query)))
       .then((res: any) => res.json())
       .then((data: any) => {
         var datamap =  data.features.map((poi: any) => ({
           id: poi.id,
           center: poi.center,
           name: poi.place_name,
           place_name: poi.place_name
         }));
         setOptions( datamap );
       }).catch( (error)=>{
         console.error(error);
       });
   };

   const fetchQueryR = (query: string) => {
   // setShowList(true);
    fetch(req(mapboxGeocodingR(query)))
      .then((res: any) => res.json())
      .then((data: any) => {
        console.log(data);
        var datamap =  data.features.map((poi: any) => ({
          id: poi.id,
          center: poi.center,
          name: poi.place_name,
          place_name: poi.place_name
        }));
       // setOptions( datamap );
        let _selected = datamap[0] ;
        return _selected;
      }).then( (_selected) =>{
        console.log(_selected.center);
         setShowText(true);
         setSelectedLocation(_selected.name);
         setSearchValue(_selected.name);
         setIsGeoLocating(false);
      }).catch( (error)=>{
        console.error(error);
      });;
  };

  const handleUserLocation = () => {
   
    if(isGeoLocating){
      return;
    }
    setShowText(false);
    setIsGeoLocating(true);
    handleCloseMobile();
    navigator.geolocation.getCurrentPosition(position => {
        let newViewport = {
            height: "100vh",
            width: "100vw",
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 12
        }
        console.log(newViewport);
   
        fetchQueryR(`${position.coords.longitude},${position.coords.latitude}`);
    })
  }

  const handleMapDialogIndex = (dialogBox: string,sdLocation: any) =>{
    if(sdLocation === 'close without selecting'){
      setSelectedLocation(undefined);
      setSearchValue('');
    }else{ 
      setSelectedLocation(sdLocation.name);
      setSearchValue(sdLocation.name);
    }
    setOpenMap(false);
  }

  const handleMapOpenDialog = () =>{
    setShowText(true);
    setSelectedLocation(undefined);
    setSearchValue('');
    setOpenMap(true);
    handleCloseMobile();
  }
   
  // const  handleChangeSwitch = () =>{
 
  //   if(switchSx){
  //     setColCount(12);
  //     setSwitchSx(false);
  //   }else{
  //     setColCount(6);
  //     setSwitchSx(true);
  //   }
  // }
 
  const handleSearchLocation =()=>{

  }
 // variable for showing only single products
  const [sgle, setSgle] = useState(false);

  useEffect( ()=>{ 

    if(isLoggedIn){
      setIsLoading(true);
      setCurrentOrderId(null);
      setCurrentOrder(null);
      useFirestoreQueryTagPosts( firestore.collection("tags")
        .where("userId", "==", globalThis.PROFILE_UID).orderBy("updated","desc")); 
        // tag == true was remove because if tag is set to false,  the like and disklike will be set to false or disabled
        // even you like it or dislike it because tag is not included in list.
       // .where("userId", "==", globalThis.PROFILE_UID).where("tag","==",true).orderBy("updated","desc"));      
        // always limit to 1.
      useFirestoreQueryCurrentOrders(firestore.collection('orders')
        .where("storeId","==", store._id)
        .where("userId", "==", globalThis.PROFILE_UID)
        .where("status","==",'cart').limit(1));
    }

    if( location &&  location.search ){
      const params = queryString.parse(  location.search);
      if(params.fid){
        firestore.collection('products').doc(params.fid+"").get()
          .then((doc)=>{
              if(doc.exists){
                setProducts([{_id: doc.id, ...doc.data()}]);
              }
              setIsLoading(false);
          });
   
        setSgle(true);
      }else{
       
        if(  store._id === "str-063T67UHIWW0iw9JXFyBbFkDaP92"){
          useFirestoreQueryProducts(firestore.collection('products')
          .where("storeId","==", store._id)
          .where("status","in",["new","cancelled","available","preorder","outofstock"])
          .orderBy("indexOrder"));
          
        }else{
          useFirestoreQueryProducts(firestore.collection('products')
          .where("storeId","==", store._id)
          .where("status","in",["new","cancelled","available","preorder","outofstock"]));
          
        }
        setSgle(false);
      }
    }
      
  },[location] )

  const handleCloseMobile = () => {
    setAnchorEl(null);
  };

  const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  
  const createdHistory = (_source: string, _storeId: string, _orderId: string, _notes: string, _status: string) =>{
    try{
      const key = `${_orderId}${_storeId}`;
      var today = utcTime();
      firestore.collection("history").doc(key).get().then( (doc) =>{
        if(doc.exists){
            var history = doc.data();
            var details = [ ...history.details, {
              created: today,
              source: _source,
              status: _status,
              notes: _notes,
            }]
            history.updated = today;
            history.details = details;
            firestore.collection("history").doc(key).update(history).then(()=>{
              console.log("history updated");
            })
        }else{
         
          var _history = {
            historyId: key,
            created: today,
            updated: today,
            details: [{  created: today,  source: _source,
              status: _status,  notes: _notes,  }]
          }
          firestore.collection("history").doc(key).set(_history).then(()=>{
            console.log("history created");
          })
        }
      })
    }catch(error){
      console.log("error: " + error);
    }
  }
 
  return (
  
   <Container className={classes.containerUi}>
      
      {( isLoading ) && 
          <Typography variant="body2" color="textSecondary" align="center">
            <img  src={globalThis.LOADING_IMAGE} alt='loading'/>
          </Typography>   
        } 
        {( !isLoading ) &&
        
          <div>   
            <div className={classes.headerSpacer} />
            
              <Grid container  direction="row" alignItems="center" justify="center"  > 
              {sgle && 
                  <Grid  item xs={12}  md={12} key="viewAll" justify="flex-start" style={{ paddingLeft: 5 }} >
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.viewAllUi}
                        onClick={ ()=>{navigate(`/?store=${store._id}`)}} 
                         >
                        View All Products
                    </Button>
                  </Grid>
              }
                {  products.map((product, index) =>(     
                  /**junald original md={12}**/         
                  <Grid  item xs={12}  md={colCount} key={product._id}  style={{ padding: 5 }} > 
                  {/**`${product.productName}-${product.description}-${product.price}` */}
                  {<Product product={product} 
                        tagPost={tagPosts.find(e => e._id === product._id+globalThis.PROFILE_UID)} 
                        store={store}  
                        handleProductOrderCount={handleProductOrderCount} 
                        currentOrders={currentOrder}                       
                        colCount={colCount} index={index}
                        isLoggedIn={isLoggedIn} />  } 
                  </Grid>
                ))
              } 
              </Grid>
         </div>
      }
      { ( store.userId !== globalThis.PROFILE_UID && globalThis.PROFILE_UID !== undefined && store._id !== "str-063T67UHIWW0iw9JXFyBbFkDaP92") &&
        <Tooltip title={store.userId !== globalThis.PROFILE_UID ? "Send Your Orders.":"You own the shop."}>
          <Fab size="large" aria-label="checkout" className={classes.fab} onClick={openConfirmOrderDialog}>
              <Badge badgeContent={currentOrder && currentOrder.totalCount}  max={999} color="primary">
              <AddShoppingCartIcon />
              </Badge>
          </Fab> 
        </Tooltip>
      }
      <MyMapBox openMapDialog={openMap} handleDialogMap={handleMapDialogIndex}  source="posts"  />
 
     {confirmOrderDialog && 
      < OrderConfirmation 
          open={confirmOrderDialog} 
          store={store}
          order={currentOrder}
          products={products}
          handleCloseConfirmOrderDialog={handleCloseConfirmOrderDialog}
          handleConfirmOrderDialog={handleConfirmOrderDialog} />
     }

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className={classes.headerSpacer} />
      <Copyright/>
 

      </Container>
 
  );
}
 
export default WorkSchedule



