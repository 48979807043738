import React , {useState,useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Grid, Divider, AppBar, Toolbar, IconButton, Typography , Slide, Card, CardHeader, Avatar, CardContent, CardActions, Badge, Collapse, Container, Icon, TextareaAutosize, TextField, ListItemIcon, ListItemText, Snackbar, CardMedia, GridList, GridListTile, GridListTileBar, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import PostingImage from '../templates/PostingImage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
//import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import MyMapBox from './myMapBox';
import {auth, useAuth,  firestore , firebase, useFirestoreDoc, useFirestoreQuery}  from 'gatsby-theme-firebase';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import {utcTime} from '../utils/MantawiTime'; 
import { Alert, AlertTitle } from '@material-ui/lab';
import PeopleIcon from '@material-ui/icons/People';
// SHARING -- PRIVATE, FRIEND, PUBLIC
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// UPLOAD VIDEO/IMAGE
// TAG A FRIEND ???
// WHERE ARE YOU LOCATION
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PublishIcon from '@material-ui/icons/Publish';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
 
import PersonIcon from '@material-ui/icons/Person';
 
import MessageIcon from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import CommentsSnO from '../templates/CommentsSnO';
import CommentEntrySnO from '../templates/CommentEntrySnO';
import DateFnsUtils from '@date-io/date-fns';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'


import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ScheduleIcon from '@material-ui/icons/Schedule';
import { isNonNullExpression } from 'typescript';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDialog:{
        background: "rgba(0,0,0,0)",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    expand4: {
        transform: 'rotate(0deg)',
        marginLeft: '5%',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      border: 1,
    },
    buttonSchedule: {             
      textTransform: "none",   
      
      marginBottom: 10
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "none",
      minWidth: '200px',
    },
    input: {
      display: 'none',
    },
    textLocationField: {
      marginTop: '10px',
    },
    menuItemSelected:{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    titleTextUi:{
      marginTop: 20,
    },
    loadingUi:{
      minHeight: 50,
      width: "100%",        
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    imageUi:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 200,
      minWidth: 200
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    detailsUi:{
      border: '1px solid rgba(0, 0, 0, .125)',
    },
    iconUi:{
      width:'100%',
      marginTop: -6
    },
    table: {
      minWidth: 650,
    },
    buttonMenu: {
       
      marginTop: 10,
      textTransform: "none",
     
    },
    negTA1:{
      marginRight: 5,
    }
  
  }),
);
 

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
 
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

let storage = undefined;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
 

 //store purpose was just to defualt to store, same with userprofile
const DraftOrder = ( { openOrderDialog, handleCloseOrderDialog,  userProfile,   orderId, matchesXS, storeCurrency}) =>{
  const classes = useStyles();
  const messageInput = useRef(null);
 
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [widthLabel, setWidthLabel] = React.useState("md"); 
  
  const [isSending,setIsSending] = useState(false);
   
  const handleMaxWidthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMaxWidth(event.target.value as DialogProps['maxWidth']);
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     setFullWidth(event.target.checked);
     
    if(fullWidth){
        setWidthLabel("md");
    }else{
        setWidthLabel("sm");
    }
    setMaxWidth('md' as DialogProps['maxWidth']);
  };
 
  const [expanded, setExpanded] = React.useState(false);
 
  const [today, setToday] = useState(new Date().toLocaleDateString());
   
  const [progress,setProgress] = useState([]);
 
  const [openSnack,setOpenSnack] = useState(false);
  const [alertMessage,setAlertMessage] = useState("");
  const [status, setStatus ]=  useState("open");
  const [notes, setNotes ]=  useState(null);
//  const [schedule, setSchedule] = useState("No Schedule Set");
  const [schedule,setSchedule] = useState(null);
  const [schedule2,setSchedule2] = useState("Schedule");
  const [details, setDetails ]=  useState(null);
  const [totalAmount, setTotalAmount ]=  useState(0);
  const [negTotalAmount, setNegTotalAmount ]=  useState(0);
  const [utcToday, setUtcToday] = useState(utcTime());
  const [anchorElSchedule, setAnchorElSchedule] = React.useState<null | HTMLElement>(null);
  
  const handleSchedulePage = (event) =>{
    if(schedule === null){
      var _today = new Date();
      setSchedule(_today);
      setScheduleTemp(isNonNullExpression);
      setSchedule2(_today.toLocaleString().replace(":00",""));     
    }

    setAnchorElSchedule(event.currentTarget);
  }
  const handleScheduleClosePage = (event) =>{
    setAnchorElSchedule(null);
  }

  const handleDateChange = (date: Date | null) => { 
    setSchedule(date);
    setSchedule2( date.toLocaleString().replace(":00",""));
  };
  
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleNotes= (event) =>{  
    
    setNotes(event.target.value);
  }

  const handleDetails = (event) =>{  
    
    setNotes(event.target.value);
  }
  
  const checkClosingDialog =()=>{
    if(!isSending){ 
      handleCloseOrderDialog();
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
   //junald
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const updateOrder = () =>{
    const postedDate = utcTime();
    if(isSending){
      return;
    }
    setSnackMessage("Draft Order Update sucessfull.");
    let theSchedule = schedule ? schedule.getTime():null;
    //notes: before schedule is check for being null if null cannot save draft order
    setIsSending(true);
    firestore.collection("orders").doc(orderId).update({soSchedule: theSchedule, 
        hasUpdates: true, updated: postedDate, negTotalAmount: negTotalAmount})
    .then( () =>{
    
        createdHistory("order",order.salesOrderId,order._id,notes,status, negTotalAmount);
    }).then(()=>{
      setOpenSnack(true);
      setIsSending(false);
    }).catch((err)=>{
      setSnackMessage("Something is wrong with your update. : " + err);
      setOpenSnack(true);
      setIsSending(false);
    });
  }

  const createdHistory = (_source: string, _storeId: string, _orderId: string, _notes: string, _status: string, totalAmount: number) =>{
    try{
      const key = `${_orderId}${_storeId}`;
      var today = utcTime();
     console.log("history: " + key);
      firestore.collection("history").doc(key).get().then( (doc) =>{
        if(doc.exists){
          console.log("doc exits history: " );
            var history = doc.data();
            var details = [...history.details, {
              created: utcTime(),
              source: _source,
              status: _status,
              notes: _notes,
              negTotalAmount: totalAmount,
            }];
            history.updated = today;
            history.details = details;
            firestore.collection("history").doc(key).update(history).then(()=>{
              console.log("history updated");
            })
        }else{
       
     
          var _history = {
            historyId: key,
            created: today,
            updated: today,
            orderUserId: order.userId,
            storeUserId: order.storeUserId,
            details: [{  created: today,  source: _source,
              status: _status,  notes: _notes,  }]
          }
          firestore.collection("history").doc(key).set(_history).then(()=>{
            console.log("history created");
          })
        }
      })
    }catch(error){
      console.log("error: " + error);
    }
  }
  
 const [overSize, setOverSize] = useState([]);
 const [snackMessage, setSnackMessage] = useState("");
 const [fileCountUpload,setFileCountUpload] = useState(0);
 const [scheduleTemp, setScheduleTemp] = useState(null);

 const salesStatus =[
   {value: 'open',label: 'Open'},
   {value: 'cart',label: 'Cart'},
   {value: 'ordered',label: 'Ordered'},
   {value: 'processing',label: 'Processing'},
   {value: 'delivery',label: 'For Pickup'},
   {value: 'intransit',label: 'In Transit'},
   {value: 'delivered',label: 'Delivered'},
   {value: 'completed',label: 'Completed'},
   {value: 'canceled',label: 'Canceled'},
 ]

 const orderStatus =[
 
  {value: 'ordered',label: 'Ordered'},
  {value: 'processing',label: 'Processing'},
  {value: 'delivery',label: 'For Pickup'},
  {value: 'intransit',label: 'In Transit'},
  {value: 'delivered',label: 'Delivered'},
  {value: 'completed',label: 'Completed'},
  {value: 'canceled',label: 'Canceled'},
]

 let formatter = new Intl.NumberFormat('en-IN', {
   
  minimumFractionDigits: 2 
})

 
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [messageBoxFocus, setMessageBoxFocus] = useState(false);
    
  const handleMessageBox = () => {
    messageInput.current.focus();
  }

  const [order, isLoading] = useFirestoreDoc(
    firestore.collection("orders").doc(orderId)
  );

  const [isLoadingProducts, setIsLoadingProducts]= useState(true);

  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  // const [products, isLoadingProducts] = useFirestoreQuery (
  //   firestore.collection("products").where("storeId", "==", store._id)
  // );
  
 const onErrorImg = (event:  any ) =>{ 
    event.target.src= require('../images/error_image.png');
 }

 const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
 
 const postImageUrl = (mainStoreId: string, imageName: string, productId: string) => {

  let completeName =require('../images/error_image.png');
  try{ 
      const lastDot = imageName.lastIndexOf('.');
      const fileName = imageName.substring(0, lastDot);
      const ext = imageName.substring(lastDot + 1);  
      const newName = `thumbnails%2F${fileName}_400x400.${ext}`;
      completeName= `${imageUrl}${mainStoreId}%2F${productId}%2F${newName}?alt=media`;
     // const completeName = `images/stores/${store._id}/${productId}/thumbnails/${fileName}_400x400.${ext}`;
      console.log("imageURL: " + completeName);
  }catch{
      console.log("no image: " + productId);
  }
  return completeName;

} 

 

const useProductImageUrl = (mainStoreId: string, imageName: string, productId: string) =>{
  var product = products.find( e => e._id === productId);
  var pName = imageName;
  if(product){
    if(product.urlFiles && product.urlFiles.length > 0 ){
      pName = product.urlFiles[0].fileName;
    }
  }
  return postImageUrl(mainStoreId, pName, productId);
}

  const computeTotalAmount = () =>{
    var _totalAmount = 0;
    order.items.forEach( (item) =>{ 
      _totalAmount = _totalAmount + (item.productPrice* item.count)

    } );
    setTotalAmount(_totalAmount);
 
  }

  const handleNegTotalAmount= (event) =>{     
    setNegTotalAmount(event.target.value);
  }

  const handleScheduleCancel = (event) =>{
    setSchedule(scheduleTemp);
    setSchedule2(scheduleTemp.toLocaleString().replace(":00",""));    
    setAnchorElSchedule(null);
  }

  const handleScheduleOk = (event) =>{
    setScheduleTemp(schedule);
    setAnchorElSchedule(null);
  }

  const deleteMe = (event, pid) =>{
    console.log("deleteme: " + pid);
    console.log("jjjjjjjjjjjjjjjjjjjj1: " + JSON.stringify(order.items));
    var itemToRemove =  order.items.filter( (e) => e.productId !== pid) ;
    console.log("jjjjjjjjjjjjjjjjjjjj2: " + JSON.stringify(itemToRemove));
  }
  
    
  useEffect(()=>{

    console.log('order doc exists0');
    if(order){
      setIsLoadingProducts(true);
      setNotes(order.notes? order.notes.substring(0,1000) : "");
      setStatus(order.status||"");
      setNegTotalAmount(order.negTotalAmount||0.0);
      if(order.soSchedule){
        let draftScheduleDate = new Date(order.soSchedule);
        setSchedule( draftScheduleDate);
       // setSchedule( new Date(order.soSchedule).toLocaleString().replace(":00",""));
        setScheduleTemp(draftScheduleDate);
        setSchedule2( draftScheduleDate.toLocaleString().replace(":00",""));
      }else{
        setSchedule2("No Schedule Set");
        setScheduleTemp(null);
        setSchedule(null);
      }
      
      computeTotalAmount();
  
      firestore.collection("stores").doc( order.storeId).get()
      .then(
        function(doc){
         
          if (doc.exists) {
         
            setStore({ _id: doc.id, ...doc.data()});
            return doc.data().currency;
          }  
          
        }
      )
      .then((currency)=>{

            formatter = new Intl.NumberFormat('en-IN', {
            
              minimumFractionDigits: 2
            });
            firestore.collection("products").where( "storeId","==",order.storeId).get().then( function(querySnapshot) {
 
              if(!querySnapshot.empty){
                  let _stList = [];
                  querySnapshot.forEach(function(doc) {
                      
                      _stList.push({ _id: doc.id, ...doc.data()});
                  }); 
                 
                  setProducts(_stList);
              } else{
                 
              }
            })
            .then(()=>{
              setIsLoadingProducts(false);
            })
            .catch( (error)=> {
              console.log("Error getting other params store: ", error);
        
           })
      })
      .catch( (error)=> {
        console.log("Error getting other params store: ", error);
       
      })
    }

  },[order])

  const getTotal =(qty, price, isPm) =>{
    if(isPm){
      return "--";
    }else{ 
      var amount = 0;
      try{ 
        const _qty = qty || 0;
        const _price = price || 0;
        amount = (qty*price);
     
      }catch(err) {
          console.log("err:" + err);
       };

      return amount.toFixed(2);
 
    }
  }


  return (
    
  <>
  
      <Dialog
        fullScreen={matchesXS}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openOrderDialog}
        onClose={checkClosingDialog}
        aria-labelledby="max-width-dialog-title"
        TransitionComponent={Transition}
        className={classes.postDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
          
        <DialogTitle id="max-width-dialog-title"> 
          Draft Orders
          {order && 
                <Card >
                    <CardHeader
                        avatar={
                              <Avatar alt="username" src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fprofiles%2F${order.userId}?alt=media`}  className={classes.avatar} />    
                        }
                        action={
                          <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                          
                              <IconButton aria-label="close"   onClick={checkClosingDialog}>
                                <CloseIcon color="primary"/>
                              </IconButton>
                              </Grid>
                          }
                        
                        title={`${order.userName} `}
                        subheader={ new Date(order.created).toLocaleDateString()}
                    />
                </Card>
            }
        </DialogTitle>
        <DialogContent>
             { ( isLoading ) && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid> }
              
              { (order && store && !isLoading)&& 
                <div>
                  <GridList cellHeight={300} className={classes.gridList} cols={2}>
                  { order.items.map( (item) =>( 
          
                        <GridListTile key={item.productId} cols={1}  style={{ width: '200px' }}>
                          
                          <img  className={classes.imageUi} src={useProductImageUrl(order.storeId,item.productImage,item.productId)} alt={item.productName} 
                            onError={onErrorImg}/>

                          <GridListTileBar
                            title={item.productName}
                            subtitle={<span>  
                              {store.currency ==='PHP' ? <>&#8369;</>  : '$'}
                              {  formatter.format(item.productPrice) } Qty: {item.count}
                              </span>}
                            actionIcon={
                              <IconButton aria-label={`info about ${item.productName}`} className={classes.icon}>
                                <InfoIcon />
                              </IconButton>
                            }
                          />

                        </GridListTile>
                      
                    ))}
                  </GridList> 

                  <Grid  container  alignItems="center" spacing={1}>
                    <Grid item key="j1" xs={12}>
                      <TextField 
                        disabled
                        id="description-textarea"
                        label= "Notes"
                        placeholder="Notes"
                        multiline
                        variant="outlined"
                        fullWidth
                        value = {notes}
                        color="primary"
                        rowsMax={2}
                        size="small"
                        inputRef = {messageInput}
                        onChange={handleNotes}
                        className ={classes.textLocationField}
                      />
                    </Grid>
              
                    <Grid item key="j2"  xs={6}>
                      <TextField
                        disabled
                        id="status-selector"
                        select
                        label= "Status"
                        placeholder="Set Order Status"
                        variant="outlined"
                        fullWidth
                        value = {status}                  
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                        className ={classes.textLocationField}
                      >
                        {salesStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    { (store && store.services) && 

                     
                         
                         <Grid item key="j21"  xs={6}>
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                           <Button fullWidth 
                               
                               variant="contained"
                               onClick={ (e) => {handleSchedulePage(e)} } 
                               startIcon={<ScheduleIcon />}
                               className={classes.buttonMenu}
                               style={{ backgroundColor: schedule < new Date().getTime() ? '#FF0000' : '#33A3FF', 
                                         color: schedule < new Date().getTime() ? '#FFFFFF' : '#000000' }}
                               
                               >
                                 {schedule2}
                                 
                               </Button>  
                             { schedule &&
                             <Menu
                                 id="simple-menu-schedule"
                                 anchorEl={anchorElSchedule}
                                 keepMounted
                                 open={Boolean(anchorElSchedule)}
                                 onClose={handleScheduleClosePage}
           
                                 getContentAnchorEl={null}
                                 anchorOrigin={{
                                   vertical: 'bottom',
                                   horizontal: 'center',
                                 }}
                                 transformOrigin={{
                                   vertical: 'top',
                                   horizontal: 'center',
                                 }}
                             >
                               <MenuItem key="datetime"  >
                                 <Grid container direction="column" spacing={1}>
                                   <Grid item key="j211"  >
                                         <KeyboardDatePicker
                                             
                                               variant="inline"
                                               format="MM/dd/yyyy"
                                               margin="normal"
                                               name="scheduleDate" 
                                               id="scheduleDate"
                                               label="Schedule Date"
                                               value={schedule}
                                               onChange={handleDateChange}
                                               KeyboardButtonProps={{
                                                 'aria-label': 'change date',
                                               }}
                                               inputProps={{
                                                min: 0,
                                                max: 100,
                                                step: 2,
                                                onKeyDown: (event) => {
                                                   event.preventDefault();
                                                },
                                              }}
                                             />
                                     </Grid>
                                     <Grid item key="j22"  >
   
                                         <KeyboardTimePicker
                                                 
                                                 margin="normal"
                                                 id="scheduleTime"
                                                 label="Schedule Time"
                                                 value={schedule}
                                                 onChange={handleDateChange}
                                                 KeyboardButtonProps={{
                                                   'aria-label': 'change time',
                                                 }}
                                                 inputProps={{
                                                  min: 0,
                                                  max: 100,
                                                  step: 2,
                                                  onKeyDown: (event) => {
                                                     event.preventDefault();
                                                  },
                                                }}
                                             />
                                   
                                     </Grid>
                                     <Grid item key="j23"  >
                                    <Grid container direction="row"  justify="flex-end" spacing={2}>
                                        <Grid  item xs={6} >
                                          <Button  
                                              fullWidth                                                              
                                              variant="contained"                                      
                                              startIcon={<CancelIcon />}
                                              className={classes.buttonSchedule}
                                              onClick={ (e) => {handleScheduleCancel(e)} } 
                                              style={{ backgroundColor: '#33A3FF', 
                                                        color:  '#FFFFFF' }}                                      
                                              >
                                              Cancel
                                          </Button>  
                                        </Grid>
                                        <Grid item xs={6} >
                                          <Button      
                                          fullWidth                                              
                                              variant="contained"                                      
                                              startIcon={<CheckCircleIcon />}
                                              onClick={ (e) => {handleScheduleOk(e)} } 
                                              className={classes.buttonSchedule}
                                              style={{ backgroundColor: '#33A3FF', 
                                                color:  '#FFFFFF'}}                                      
                                              >
                                              Ok
                                          </Button>  
                                        </Grid>
                                      </Grid>
                                  </Grid>
                                   </Grid>
                                 </MenuItem>
                               </Menu>
                             }
                                 </MuiPickersUtilsProvider>
                         </Grid>
                        
                    }
                    <Grid item key="j3"  xs={12}> 
                        {store.showPrice ?
                        <Typography variant="caption" color="primary"> </Typography>
                          : <Typography variant="caption" color="secondary"> "Prices are not shown, you can negotiate the prices on the comments." 
                          </Typography>}  
                    </Grid>
                    <Grid item key="j4"  xs={12}> 
                        {store.services ?
                        <Typography variant="caption" color="secondary"> "Checked your comments, Your customer probably asking for a schedule." 
                        </Typography>:
                        <Typography variant="caption" color="primary"> </Typography>
                        }
                    </Grid>
                    <Grid item key="j41"  xs={12}>

                      <TableContainer component={Paper}>
                        <Table   aria-label="item table">
                          <TableHead>
                            <TableRow>
                            <TableCell> </TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Qty.</TableCell>
                              <TableCell align="right">Price</TableCell>
                              <TableCell align="right">Total</TableCell>     
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {order.items.map((item) => (
                              <TableRow key={item.productId}>
                                <TableCell padding="checkbox">
                                  <IconButton>
                                    <DeleteForeverIcon onClick={(event)=>{  deleteMe(event, item.productId)}} />                                    
                                   </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {item.productName}
                                </TableCell>
                                <TableCell align="right">{item.count}</TableCell>
                                <TableCell align="right">{store.showPrice ? Number.parseFloat(item.productPrice).toFixed(2):"--"}</TableCell>
                               {/** <TableCell align="right">{store.showPrice ? getTotal(item.count, item.productPrice, store.showPrice  ):"--"}</TableCell> */} 
                               <TableCell align="right">{ getTotal(item.count, item.productPrice, false )}</TableCell>
                                
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                       
                    </Grid>

                    <Grid item key="j5"  xs={12}>
                      
                       <Typography variant="body2" color="primary">
                           Total Amount: { store.showPrice && (store.currency ==='PHP' ? <>&#8369;</>  : '$') }{`${store.showPrice ?  formatter.format(totalAmount)  : "Checked your comments below. Prices probably negotiated."} `} 
                        </Typography>
                    </Grid>

                    <Grid   container direction="row" justify="flex-end" alignItems="center" className={classes.negTA1} >
                        <Grid xs  className={classes.negTA1}  >
                         <Typography variant="body2" color="primary" align="right"> 
                           Total Amount: {store.currency ==='PHP' ? <>&#8369;</>  : '$'}    </Typography>
                        </Grid>
                        <Grid xs={3}  >
                          {/**
                           * 
                           * <TextField
                              id="negTotalAmount-textarea"
                             inputProps={{min: 0, style: { textAlign: 'right' }}}     
                              variant="outlined"                              
                              value = {negTotalAmount}
                              color="primary"
                              size="small"
                              name="numberformat"
                              onChange={handleNegTotalAmount}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                           */}
                          <CurrencyTextField
                              id="negTotalAmount-textarea"                             
                              variant="outlined"                              
                              value = {negTotalAmount}
                              color="primary"
                              size="small"                             
                           //   onChange={handleNegTotalAmount}
                              onChange={(event, value)=> setNegTotalAmount(value)}
                              outputFormat="number"
                              decimalCharacter="."
		                          digitGroupSeparator=","
                              currencySymbol=""
                            />
                        </Grid>
                      
                    </Grid>
                  </Grid>
                </div> 
              }        
        </DialogContent>
        <DialogActions>
 
              <Grid  container   direction="row"    justify="flex-end" alignItems="center" >
                  {/**
                      <Grid    xs={12} md={6} sm={6} item container direction="row"    >
                  
                          <Grid item key="j7"  xs={3} sm={2} md={2}>
                            <Tooltip title="You must enter the name and the description.">
                                  <IconButton aria-label="message text" onClick={handleMessageBox} > 
                                    <MessageIcon  color='primary'/>                       
                                  </IconButton>  
                            </Tooltip>
          
                          </Grid>
                      
                      </Grid> 
                       */} 
                      <Grid item  key="j8" xs={12} md={6} sm={6} container direction="row" justify="center"  alignItems="center">
                             
                             <Button
                                 variant="outlined"
                                 color="primary"
                                 className={classes.button}
                                 onClick={updateOrder}
                                 fullWidth
                               >
                                   {isSending && <> <CircularProgress/> Submit Changes </>}
                                   {!isSending && <><PublishIcon/> Submit Changes</>}
                               </Button>
                     </Grid>
                      
            
              </Grid>
             
        </DialogActions>
        {order &&
          <Button  fullWidth color="primary" 
              className={classes.button} 
              aria-label="show more"
              onClick={handleExpandClick}
              endIcon={ <Icon className={classes.iconUi}>
                <ExpandMoreIcon  color="primary" className={clsx(  {
                  [classes.expandOpen]: expanded,
                })}/>
              </Icon>}
          > 
              {order.countComment>0?order.countComment:""} Comments              
          </Button>
    
              }
        {order && 
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          
            <Grid container>   
                <CommentEntrySnO  storeId={order.storeId} orderId ={order._id}/> 
            </Grid>

              <Grid container>
                  {expanded &&
                  <CommentsSnO    store={store}   orderId ={order._id} />    
                }  
              </Grid>

            {order.countComment > 3   ?
                <Button  fullWidth color="primary" 
                className={classes.button} 
                aria-label="show more"
                onClick={handleExpandClick}
                endIcon={ <Icon className={classes.iconUi}>
                  <ExpandMoreIcon  color="primary" className={clsx(  {
                    [classes.expandOpen]: expanded,
                  })}/>
                </Icon>}
            > 
                {order.countComment>0?order.countComment:""} Comments               
            </Button>
            :
            <></>
            }


          </Collapse>
        }
        <Snackbar open={openSnack} autoHideDuration={5000} onClose={handleCloseSnack} >
            
                <Alert  onClose={handleCloseSnack} severity="success">
                    <AlertTitle> Update send                               
                    </AlertTitle>
                    
                    {snackMessage}
                </Alert>
                
          
        </Snackbar>
      </Dialog>
     
     
      </>
  
  );
}

 
export default DraftOrder;

//The Bootstrap grid system has four classes: 
//xs (phones),
// sm (tablets), 
//md (desktops), and 
//lg (larger desktops). 
//The classes can be combined to create more dynamic and flexible layouts.