import React ,{useEffect ,useState} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withPrefix , Link} from "gatsby";
import { CardMedia, Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import orange from "@material-ui/core/colors/orange";
//import captureWebsite  from 'capture-website';
//import { capture, OutputType } from 'html-screen-capture-js';
//const useStyles = (psHeight) => makeStyles( (theme: Theme) =>
//https://stackoverflow.com/questions/15273042/catch-error-if-iframe-src-fails-to-load-error-refused-to-display-http-ww#:~:text=your%20web%20app%20would%20make,know%20the%20IFrame%20will%20error.
const useStyles = makeStyles( (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: "rgba(0,0,0,.03)", 
 
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      minHeight: 300
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    backgroundUrl:{
      
      width: '100%'
   
    },
    backgroundUrl2:{
      height: 200
    },
    adds1:{
      minWidth: 250,
      maxHeight: 300,
      minHeight: 300,
      
      maxWidth: 500,
      paddingTop: 5,
      display: 'flex',
    } ,
    adds2:{
      width: '95%',
      maxHeight: 300,
      minHeight: 300,
      minWidth: 300,
      paddingTop: 5,
    } ,
    adds3:{
      width: 470,
      height:   80
    } ,
    imageUi36:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 'auto',
      minWidth: 250,
    },
    imageUi3:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 250,
      minWidth: 250,
    },
    imageUi2:{
      maxHeight: 300,
      minHeight: 300,
      maxWidth: 'auto',
      minWidth: 250,
    },
    imageUi1:{
      maxHeight: 300,
      minHeight: 300,
      width: '100%'
    },
    imageLinks:{
      maxHeight: 300,
      minHeight: 300,
      width: '90%',
      maxWidth: '100% -20px',
      minWidth: 250,
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    advertisementUi:{
      background: orange[500]
    }
   
  }),
);
  
const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
const postImageUrl = (mainStoreId: string, imageName: string, productId: string, productType: string) => {
   
  const lastDot = imageName.lastIndexOf('.');
  const fileName = imageName.substring(0, lastDot);
  const ext = imageName.substring(lastDot + 1);
   
  const newName = productType !== "ads" ? `thumbnails%2F${fileName}_400x400.${ext}` : `${fileName}.${ext}`;
  const completeName= `${imageUrl}${mainStoreId}%2F${productId}%2F${newName}?alt=media`;
  //console.log(`imageURL: ${completeName}`);
  //console.log(`mainStoreId: ${mainStoreId} imageName: ${imageName} productId: ${productId}`);
  return completeName;

}

const screeshotUrl = (mainPostId) => { 
 // console.log("screenshot url: " + mainPostId); 
  return `${imageUrl}${mainPostId}%2F${mainPostId}.png?alt=media`;
}


const addsUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/adds%2Ftest1%2F`
const addsImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 19) + 1;
 
  const newName = `thumbnails%2F${fileName}_200x200.jpg`;
 //const newName = `thumbnails%2F${fileName}_200x200.jpg`;
   return `${addsUrl}${newName}?alt=media`;

}
 
const sponsorImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 20) + 1;
  const newName = require(`../images/sponsor/${fileName}.jpg`);
  return newName;
}


const backgroundImageUrl =  () => {
  const  fileName= Math.floor(Math.random() * 40) + 1;
  const newName = require(`../images/background/${fileName}.jpg`);
  return newName;
}



const PostImage = ({tileData, storeId, productId,  psHeight, source, productType, colCount}) =>  {
  //const classes = useStyles(psHeight)();
  const classes = useStyles();
  var useDefaultImage = false;
   
  
  const [ colsCount, setColsCount] = useState(1);
 
 
  if(tileData && tileData.length >0){
    useDefaultImage=true;
  }
 
  const onErrorImg = (event:  any) =>{
   
     event.target.src= require('../images/error_image.png');
  }
 
  
   
  useEffect(()=>{

    if( tileData.length >= 3){
      setColsCount(tileData.length)
    }else if(tileData.length > 1){
      setColsCount(2)
    }else{
      setColsCount(1)
    }
    if(colCount=== 6){
      setColsCount(1);
    }
   
  },[tileData.length,colCount]);
 
  
  return (
 
      <div className={classes.root}>
    

        { (tileData.length > 2) &&
            <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
              {tileData.map((tile) => (
                <GridListTile key={tile.fileName} cols={1}>
                    
                      {colCount === 6?
                        <img  className={classes.imageUi36} src={postImageUrl(storeId,tile.fileName,productId,productType)} alt={tile.fileName} 
                        onError={onErrorImg}/>
                        :
                        <img  className={classes.imageUi3} src={postImageUrl(storeId,tile.fileName,productId,productType)} alt={tile.fileName} 
                        onError={onErrorImg}/>
                          
                      }
              
                </GridListTile>
              ))}  
              
            </GridList>
        } 

        {(tileData.length === 2 ) &&
            <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
              {tileData.map((tile) => (
                <GridListTile key={tile.fileName} cols={1}>
                <img  className={classes.imageUi2} src={postImageUrl(storeId,tile.fileName,productId,productType)} alt={tile.fileName} 
                    onError={onErrorImg}
                />
                </GridListTile>
              ))}    
            </GridList>
        } 

        {(tileData.length === 1 ) &&
          <GridList cellHeight={300} className={classes.gridList} cols={colsCount}>
            {tileData.map((tile) => (
              <GridListTile key={tile.fileName} cols={1}>
                
              <img  className={classes.imageUi1} src={postImageUrl(storeId,tile.fileName,productId,'ads')} alt={tile.fileName} 
                  onError={onErrorImg}
              /> 
              </GridListTile>
            ))}  
          
          </GridList> 
        } 
 
 
    </div>
            
  );
}

export default PostImage;
 