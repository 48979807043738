import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
 
import YourTagProducts from '../templates/YourTagProducts';
import YourOrders from '../templates/YourOrders';

import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .05)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const AccountDrawer = ({profileId,store, closedAccountDrawer}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion  key="1" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Tag Products</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
        <YourTagProducts  userId={profileId} closedAccountDrawer={closedAccountDrawer} />
        </AccordionDetails>
      </Accordion>
 
      
      <Accordion   key="3" expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
        <AccordionSummary
          expandIcon={
          <ExpandMoreIcon />
        }
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
         
            <Typography className={classes.heading}>My Orders</Typography>
            

        </AccordionSummary>
        <AccordionDetails>
          <YourOrders userId={profileId}  closedAccountDrawer={closedAccountDrawer} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AccountDrawer;