import React, { useState, useEffect, useContext, Fragment } from 'react';
 
import {auth, useAuth,  firestore , firebase, useFirestoreQuery}  from 'gatsby-theme-firebase';
 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {ContactUs} from '../utils/mantawiObject';
 
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CancelIcon from '@material-ui/icons/Cancel';
 
import { Snackbar, IconButton, Grid, Paper, LinearProgress, MenuItem, InputLabel, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import { Link  } from 'gatsby-theme-material-ui';
import { navigate } from "gatsby"
import { utcTime } from '../utils/MantawiTime';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    rootEntry: {
      display: 'flex',
      flexGrow: 1,
      marginTop: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    expandButton: {    
      minWidth: '100px'
    },
    contactUi:{
      width: '100%',
      height: '100vh'
    },
    cardUi:{
      width: '100%',
      height: '100%-10',
    },
    snackTop:{
      marginTop: 50,
    },
    loadingUi:{
      width: "100%",        
    },
   
  }),
);

const errorBannerUrl = require(`../images/Miniens1.png`);
const onErrorImg = (event:  any) =>{ 
  event.target.src= require(`../images/Miniens1.png`);
}


const ContactUsForm = ({setShowPage,userProfile, store}) => {
    const classes = useStyles();
    const bannerUrl = store !== null? `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F${store._id}%2F${store._id}?alt=media`:errorBannerUrl;
    const [contactUs, setContactUs] = useState<ContactUs>( {
        name: "",
        email: "",
        message: "",
        topic: "",
        notes: "",
        storeId: "",
        storeUserId: "",
        userName: "",
        userId: "",
        userEmail: "",
        created: null,
        updated: null,
    });
  
    
    const [loading, setLoading] = useState( false );
    const [errorMsg, setError] = useState(null);
    const [image, setImage] = useState(null);
    const [displayName,setDisplayName] = useState("");
    const { isLoggedIn, profile }  = useAuth();
    const [open, setOpen] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [severity, setSeverity] = React.useState("warning");
    const [message, setMessage] = React.useState("");
    const [contacts, setContacts] = useState([]);
    // const [contacts, setContacts] = useFirestoreQuery (
    //   firestore.collection("contactus").where("storeId", "==", store._id)
    // );

    const [isContactLoading, setisContactLoading] = useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        setOpen(false);
        if(severity==="success"){
          handleCancel();
        }
        return;
      }
  
   
    };

    // useEffect(()=>{
    //   if(contacts && contacts.length> 0){
    //     setisContactLoading(true);
    //   }else{
    //     setisContactLoading(false);
    //   }
     
    // }, [contacts])
 
    useEffect(()=>{
 
 
      if(profile){
        console.log("mount useefffect account");
              
        firestore.collection("users").doc(profile.uid).get().then(
          function(doc){

            if (doc.exists && store) {
              var data = doc.data();
              setDisplayName(store.name);
              setContactUs({
                  ...contactUs, 
                  email: store.email,
                  name: store.name,
                  topic: "Others",
                  message: "",
                  storeId: store._id,
                  storeUserId: store.userId,
                  userName: data.name ,
                  userEmail: data.email,
                  userId: profile.uid,
              });
              console.log("mount useefffect profile data: " + data.name  );
            } else {

              console.log("this happened.....")
              setContactUs({
                name:  store.name,
                email: store.email,
                topic: "Others",
                message: "",
                storeId: store._id,
                storeUserId: store.userId,
                userEmail: userProfile.email,
                userName: userProfile.name ,
                userId: profile.uid,
              })
   
            }
          }

        ).catch( (error)=> {
          console.error(error);
        }); 

         
        if(profile.uid === store.userId){
          setisContactLoading(true);
            
          firestore.collection("contactus").where("storeUserId", "==", profile.uid).get()
          .then((querySnapshot)=>{
            
              if(!querySnapshot.empty){
                  let _stList = [];
                  querySnapshot.forEach(function(doc) {
                      
                      _stList.push({ _id: doc.id, ...doc.data()});
                  }); 
                
                  setContacts(_stList);
                  setisContactLoading(false);
              } else{
                setisContactLoading(false);
                console.log("query contact us empty4"  )
              }
          }).catch( (error)=> {
            setisContactLoading(false);
            console.error(error);
          })
   
        }
       
      }else{
        setDisplayName('fetching...');
      }
    
   

    },[profile]);



    const saveContactUs =  () => {  
      console.log("saveUserProfile");
      console.log(JSON.stringify(contactUs));
      console.log(globalThis.PROFILE_UID);
      var today = utcTime();
      setIsSending(true);
      if(contactUs.name.trim().length === 0 || contactUs.email.trim().length === 0  || contactUs.message.trim().length === 0 ){
        setSeverity("warning");
        setMessage("All fields are required.")
        setOpen(true);
        setIsSending(false);
      }else if(contactUs.name.trim().length !== 0 && contactUs.email.trim().length !== 0  && contactUs.message.trim().length !== 0 ){
            var _contactUs = 
              {
                name: userProfile.name.substring(0, 500),
                email: userProfile.email.substring(0, 500),
                message: contactUs.message.substring(0, 500),
                topic: contactUs.topic.substring(0, 500),
                userId: profile.uid,
                storeId: store._id,
                storeUserId: store.userId,
                status: 'open',
                sendEmail: false,
                created: today,
                updated: today, 
              }

          
            firestore.collection("contactus").add(_contactUs).then((doc)=>{
                console.log("updated........");
              }).then(()=>{
                setSeverity("success");
                setMessage("Message send.")
                setOpen(true);
              }).finally(()=>{
                setIsSending(false)
              });
      }
          
    }  

    const handleInputChange = (event) =>{  
        const value = event.target.value;
        const name = event.target.name;     
    
        setContactUs(
          {...contactUs,
          [name]: value.substring(0, 500)}
        );
          
    }
 
    const handleChangeTopic = (event: React.ChangeEvent<{ value: unknown }>) => {
     
      setContactUs(
        {...contactUs,
        topic: event.target.value as string}
      );
    };

    const handleCancel=()=>{
       navigate(`/?store=${store._id}`);
      // setShowPage(0) 
    }
    const spacing = 2;
 
  
    return (   
      
      <Container className={classes.contactUi} >
        
        <Card className={classes.cardUi} raised>     
              <CardContent>
              <form noValidate autoComplete="off">
                <Grid container  direction="column" >
                
                    <Grid item xs={12}>
                      <img
                          src={bannerUrl}
                          alt="Store Banner"
                          height="200"
                          width="100%"
                          onError={onErrorImg}
                      />
                    </Grid>
                     <Grid item key={'textfields22'} xs={12}>
                        <h2>
                          Contact Us   {isLoggedIn ? `-${displayName}` : 'You need to register or login.'}
                        </h2> 
                     </Grid>
                     
                   
                     { (profile && profile.uid !== store.userId) && <Container>
                          <Grid item key={'textfields21'} xs={12}>
                              <Typography variant="h6">{`${contactUs.userName}-${contactUs.userEmail}`}</Typography>
                          </Grid>
                        
                          <Grid item key={'textfields1'} xs={12}>
                              <TextField   className={classes.rootEntry} id="name" name="name" label="Store" value={contactUs.name}  onChange={handleInputChange}/>
                          </Grid>

                          <Grid item key={'textfields2'} xs={12}>
                              <TextField className={classes.rootEntry} type="email" id="email" name="email" label="Email"   value={contactUs.email}  onChange={handleInputChange}/>
                          </Grid>
                      

                          <Grid item key={'textfields3'} xs={12}>
                          <TextField select className={classes.rootEntry}   id="topic" name="topic" label="Topic"   value={contactUs.topic}  onChange={handleChangeTopic}>
                                <MenuItem value={'Others'}>Others</MenuItem>
                                {/**
                                <MenuItem value={'Ads'}>Ads</MenuItem>
                                 */}
                                <MenuItem value={'Help'}>Help</MenuItem>
                          </TextField>
                          {/**
                            <Select  className={classes.rootEntry}
                                labelId="topic-select-label"
                                id="topic-select"
                                value={contactUs.topic}
                                onChange={handleChangeTopic}
                                label="Topic"
                              >
                                <MenuItem value={'Others'}>Others</MenuItem>
                                <MenuItem value={'Ads'}>Ads</MenuItem>
                                <MenuItem value={'Help'}>Help</MenuItem>
                              </Select>
                              */}
                          </Grid>


                          <Grid item key={'textfields4'} xs={12}>
                              <TextField  multiline  rows={4} className={classes.rootEntry} id="message" name="message" label="Message"   value={contactUs.message}  onChange={handleInputChange}/>
                          </Grid>

                          
                    </Container>}
                  {(profile && (profile.uid  === store.userId &&  
                      isContactLoading)) && <Grid container  justify="center" alignItems="center" className={classes.loadingUi}><CircularProgress/> </Grid>
                   }
                  { (profile && profile.uid  === store.userId && !isContactLoading ) && 
                    <TableContainer component={Paper}>
                      <Typography variant="caption"> 
                        <Table   aria-label="item table">
                          
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell align="center">Name</TableCell>
                              <TableCell align="center">Email</TableCell>
                              <TableCell align="center">Topic</TableCell>     
                              <TableCell align="center">Message</TableCell>    
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contacts.map((contact) => (
                              <TableRow key={contact._id}>
                                   <TableCell component="th" scope="row"> {new Date(contact.created).toLocaleString().replace(":00","")}  </TableCell>
                                <TableCell component="th" scope="row"> {contact.name}  </TableCell>
                                <TableCell align="right">{contact.email}</TableCell>
                                <TableCell align="right">{contact.topic}</TableCell>
                                <TableCell align="right">{contact.message}</TableCell>
                                
                              </TableRow>
                            ))}
                          </TableBody>
                     
                        </Table>
                         </Typography>
                      </TableContainer>
                    }
                 </Grid>  
                 </form>
              </CardContent>
              { (profile && profile.uid  !== store.userId) && <Container>
                <CardActions disableSpacing >
                  <Grid container className={classes.root}  justify="flex-end">
                      <Grid item>
                      {isSending && <CircularProgress/>  }
                      {!isSending &&   
                      <Button className={classes.expandButton} variant="outlined" color="primary" onClick={saveContactUs }>
                        Submit
                      </Button>}
                  
                    </Grid>
                    <Grid item>
                    <Button className={classes.expandButton} variant="outlined" color="primary" onClick={handleCancel}>
                        Go Back
                    </Button>
                    </Grid>
                  </Grid>
                </CardActions>

               </Container>
              }
            </Card>

            
            <Snackbar className={classes.snackTop} anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open}   onClose={handleClose} >  
              <Alert  onClose={handleClose}  severity={severity}>
               <AlertTitle>{severity}</AlertTitle>
                 <strong>{message}</strong>
               </Alert>
            </Snackbar>
      
      </Container>
   
    )
}

export default ContactUsForm;
